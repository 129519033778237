import {
  GET_ORGANIZATION_REQUEST,
  GET_ORGANIZATION_SUCCESS,
  GET_ORGANIZATION_ERROR,
} from "configurations/Types";

const INITIAL_STATE = {
  organization: null,
  loading: false,
};

export default function OrganizationReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_ORGANIZATION_REQUEST: {
      return Object.assign({}, state, { loading: true });
    }
    case GET_ORGANIZATION_SUCCESS: {
      console.log("ACTION", action.payload);
      return Object.assign({}, state, {
        organization: action.payload,
        loading: false,
      });
    }
    case GET_ORGANIZATION_ERROR: {
      return Object.assign({}, state, { loading: false });
    }
    default: {
      return state;
    }
  }
}
