import {
  GET_CHARTS_ERROR,
  GET_CHARTS_REQUEST,
  GET_CHARTS_SUCCESS,
  GET_CHARTS_DROPDOWNS_REQUEST,
  GET_CHARTS_DROPDOWNS_SUCCESS,
  GET_CHARTS_DROPDOWNS_ERROR,
  CHART_REPORT_REQUEST,
  CHART_REPORT_SUCCESS,
  CHART_REPORT_ERROR,
  CLEAR_CHARTS
} from "../configurations/Types";

export function getChartsRequest(month, year, day, dateType, lawyerId, accountId, customerId, jobId, cb) {
  let _body = {};
  if (dateType === 'all') {
    let start_day = "01"
    let start_month = "01"
    let start_year = new Date(new Date().setFullYear(new Date().getFullYear() - 50)).getFullYear()
    let end_day = new Date(new Date().setMonth(new Date().getMonth(11) + 1, 0)).getDate()
    let end_month = "12"
    let end_year = new Date(new Date().setFullYear(new Date().getFullYear() + 50)).getFullYear()

    _body.start_date = `${start_year}-${start_month}-${start_day}`
    _body.end_date = `${end_year}-${end_month}-${end_day}`
    _body.date_type = "all"
  }
  return {
    type: GET_CHARTS_REQUEST,
    body: {
      ..._body,
      date_type: "monthly",
      month,
      year,
      day,
      date_type: dateType,
      lawyer_id: lawyerId && parseInt(lawyerId),
      account_id: accountId && parseInt(accountId),
      customer_id: customerId && parseInt(customerId),
      job_id: jobId && parseInt(jobId)
    },
    cb
  };
}

export function getChartsSuccess(payload, date_type, cb) {
  return {
    type: GET_CHARTS_SUCCESS,
    payload,
    date_type,
    cb
  };
}

export function getChartsError(cb) {
  return {
    type: GET_CHARTS_ERROR,
    cb
  };
}

export function chartDropdownsRequest(cb) {
  return {
    type: GET_CHARTS_DROPDOWNS_REQUEST,
    cb
  };
}

export function chartDropdownsSuccess(payload) {
  return {
    type: GET_CHARTS_DROPDOWNS_SUCCESS,
    payload
  };
}

export function chartDropdownsError(error) {
  return {
    type: GET_CHARTS_DROPDOWNS_ERROR,
    error
  };
}

export function clearCharts(cb) {
  return {
    type: CLEAR_CHARTS,
    cb
  };
}

//Get chart report
export const chartReportRequest = (params, cb) => {
  // let _body = {};
  // if (dateType === 'all') {
  //   let start_day = "01"
  //   let start_month = "01"
  //   let start_year = new Date(new Date().setFullYear(new Date().getFullYear() - 50)).getFullYear()
  //   let end_day = new Date(new Date().setMonth(new Date().getMonth(11) + 1, 0)).getDate()
  //   let end_month = "12"
  //   let end_year = new Date(new Date().setFullYear(new Date().getFullYear() + 50)).getFullYear()

  //   _body.start_date = `${start_year}-${start_month}-${start_day}`
  //   _body.end_date = `${end_year}-${end_month}-${end_day}`
  //   _body.date_type = "all"
  // }
  return {
    type: CHART_REPORT_REQUEST,
    params,
    cb
  };
};

export const chartReportSuccess = payload => {
  return {
    type: CHART_REPORT_SUCCESS,
    payload
  };
};

export const chartReportError = error => {
  return {
    type: CHART_REPORT_ERROR,
    error
  };
};
