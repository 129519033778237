import {
    GET_TRANSACTION_INVOICES,
    GET_TRANSACTION_INVOICES_SUCCESS,
    GET_TRANSACTION_INVOICES_ERROR,
    CREATE_INVOICE,
    CREATE_INVOICE_SUCCESS,
    CREATE_INVOICE_ERROR,
    GET_OPEN_INVOICES_REQUEST,
    GET_OPEN_INVOICES_SUCCESS,
    GET_OPEN_TRANSACTIONS_ERROR,
    PRINT_INVOICE_REQUEST,
    PRINT_INVOICE_SUCCESS,
    PRINT_INVOICE_ERROR,
    DELETE_INVOICE_REQUEST,
    DELETE_INVOICE_SUCCESS,
    DELETE_INVOICE_ERROR,
    RECORD_INVOICE_REQUEST,
    RECORD_INVOICE_SUCCESS,
    RECORD_INVOICE_ERROR,
     MULTIPLE_EXPORT_REQUEST,
     MULTIPLE_EXPORT_SUCCESS,
     MULTIPLE_EXPORT_ERROR,
     MULTIPLE_STATEMENT_EXPORT_ERROR,
     MULTIPLE_STATEMENT_EXPORT_REQUEST,
     MULTIPLE_STATEMENT_EXPORT_SUCCESS,
    GET_CLIENT_STATEMENT_REQUEST,
    GET_CLIENT_STATEMENT_SUCCESS,
  GET_CLIENT_STATEMENT_ERROR,
   RECORD_PAYMENT_REQUEST,
   RECORD_PAYMENT_SUCCESS,
   RECORD_PAYMENT_ERROR,
  } from "configurations/Types";
  import moment from "moment";

  const INITIAL_STATE = {
    loading: false,
    invoicesData: {},
    year: moment().format("YYYY"),
    month: '',
    cardLoading: false,
    multipleExportLoading:false,
    invoices: [],
    invoicesLoading: false,
    printInvoiceLoading: false,
    printInvoicesData: [],
    deleteInvoiceLoading: false,
    recordInvoiceLoading: false,
    clientStatementLoading: false,
    multipleStatementExportLoading: false
  };
  
  function InvoiceReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
      case GET_TRANSACTION_INVOICES: {
        console.log('Reducerrr: ', action)
        let dateSelected = action?.params?.dateSelected
        return Object.assign({}, state, {
          loading: dateSelected ? false : true,
          cardLoading: dateSelected ? true : false
        });
      }
      case GET_TRANSACTION_INVOICES_SUCCESS: {
        console.log("action trans:", action);
        return Object.assign({}, state, {
          loading: false,
          cardLoading: false,
          invoicesData: action.payload.data,
        });
      }
      case GET_TRANSACTION_INVOICES_ERROR: {
        return Object.assign({}, state, {
          loading: false,
          cardLoading: false,
        });
      }
      case CREATE_INVOICE: {
        return Object.assign({}, state, {
          loading: true,
        });
      }
      case CREATE_INVOICE_SUCCESS: {
        return Object.assign({}, state, {
          loading: false,
        });
      }
      case CREATE_INVOICE_ERROR: {
        return Object.assign({}, state, {
          loading: false,
        });
      }
      
      case GET_OPEN_INVOICES_REQUEST: {
        // console.log('Reducerrr: ', action.payload)
        return Object.assign({}, state, {
          invoicesLoading: true,
        });
      }
  
      case GET_OPEN_INVOICES_SUCCESS: {
        console.log("GET_OPEN_INVOICES_SUCCESS REducer", action.payload);
        return Object.assign({}, state, {
          invoicesLoading: false,
          invoices: action.payload?.all_invoices,
        });
      }
      case GET_OPEN_TRANSACTIONS_ERROR: {
        return Object.assign({}, state, {
          invoicesLoading: false,
        });
      }
      case PRINT_INVOICE_REQUEST: {
        // console.log('Reducerrr: ', action.payload)
        return Object.assign({}, state, {
          printInvoiceLoading: true,
        });
      }
  
      case PRINT_INVOICE_SUCCESS: {
        console.log("GET_OPEN_INVOICES_SUCCESS REducer", action.payload);
        return Object.assign({}, state, {
          printInvoiceLoading: false,
          printInvoicesData: action.payload,
        });
      }
      case PRINT_INVOICE_ERROR: {
        return Object.assign({}, state, {
          printInvoiceLoading: false,
          printInvoicesData: action.payload
        });
      }
      case DELETE_INVOICE_REQUEST: {
        // console.log('Reducerrr: ', action.payload)
        return Object.assign({}, state, {
          deleteInvoiceLoading: true,
        });
      }
  
      case DELETE_INVOICE_SUCCESS: {
        console.log("GET_OPEN_INVOICES_SUCCESS REducer", action.payload);
        return Object.assign({}, state, {
          deleteInvoiceLoading: false,
        });
      }
      case DELETE_INVOICE_ERROR: {
        return Object.assign({}, state, {
          deleteInvoiceLoading: false,
        });
      }
      case RECORD_PAYMENT_REQUEST: {
        // console.log('Reducerrr: ', action.payload)
        return Object.assign({}, state, {
          recordPaymentLoading: true,
        });
      }
  
      case RECORD_PAYMENT_SUCCESS: {
        console.log("GET_OPEN_PaymentS_SUCCESS REducer", action.payload);
        return Object.assign({}, state, {
          recordPaymentLoading: false,
        });
      }
      case RECORD_PAYMENT_ERROR: {
        return Object.assign({}, state, {
          recordPaymentLoading: false,
        });
      }
      case RECORD_INVOICE_REQUEST: {
        // console.log('Reducerrr: ', action.payload)
        return Object.assign({}, state, {
          recordInvoiceLoading: true,
        });
      }
  
      case RECORD_INVOICE_SUCCESS: {
        console.log("GET_OPEN_INVOICES_SUCCESS REducer", action.payload);
        return Object.assign({}, state, {
          recordInvoiceLoading: false,
        });
      }
      case RECORD_INVOICE_ERROR: {
        return Object.assign({}, state, {
          recordInvoiceLoading: false,
        });
      }
        //BATCH EMAILING
      case  MULTIPLE_EXPORT_REQUEST: {
        // console.log('Reducerrr: ', action.payload)
        return Object.assign({}, state, {
          multipleExportLoading: true,
        });
      }
  
      case  MULTIPLE_EXPORT_SUCCESS: {
        console.log("GET_OPEN_INVOICES_SUCCESS REducer", action.payload);
        return Object.assign({}, state, {
          multipleExportLoading: false,
        });
      }
      case  MULTIPLE_EXPORT_ERROR: {
        return Object.assign({}, state, {
          multipleExportLoading: false,
        });
      }

      //BATCH EMAILING STATEMENT
      case MULTIPLE_STATEMENT_EXPORT_REQUEST: {
        // console.log('Reducerrr: ', action.payload)
        return Object.assign({}, state, {
          multipleStatementExportLoading: true,
        });
      }
  
      case MULTIPLE_STATEMENT_EXPORT_SUCCESS: {
        console.log("GET_OPEN_INVOICES_SUCCESS REducer", action.payload);
        return Object.assign({}, state, {
          multipleStatementExportLoading: false,
        });
      }
      case MULTIPLE_STATEMENT_EXPORT_ERROR: {
        return Object.assign({}, state, {
          multipleStatementExportLoading: false,
        });
      }
      //GET CLIENT STATEMENT
    case GET_CLIENT_STATEMENT_REQUEST: {
      // console.log('Reducerrr: ', action.payload)
      return Object.assign({}, state, {
        clientStatementLoading: true,
      });
    }

    case GET_CLIENT_STATEMENT_SUCCESS: {
      console.log("GET_OPEN_INVOICES_SUCCESS REducer", action.payload);
      return Object.assign({}, state, {
        clientStatementLoading: false,
      });
    }
    case GET_CLIENT_STATEMENT_ERROR: {
      return Object.assign({}, state, {
        clientStatementLoading: false,
      });
    }
      
      default: {
        return state;
      }
    }
}

export default InvoiceReducer;
