import { put, takeLatest } from "redux-saga/effects";
import {
  contactSuccess, endTourError, endTourSuccess,
} from "../actions/App";
import {
  CONTACT_REQUEST, DELETE_DEMO_REQUEST,
} from "../configurations/Types";
import {

  CONTACT_ENDPOINT,
  DELETE_DEMO_DATA,
  USERS_URL,
} from "../configurations/Constants";
import info from "../components/message/index";
import {
  publicAgent,
  CancelToken,
  privateAgent,
} from "../configurations/AxiosAgent";
import moment from "moment";

const contactUsApi = (body, token) => {
  //console.log("contactUsApi", body, token);
  return publicAgent.post(`${USERS_URL}${CONTACT_ENDPOINT}`, body, {
    cancelToken: token.token,
  });
};

const deleteDemoDataApi = (body) => {
  return privateAgent.delete(`${USERS_URL}${DELETE_DEMO_DATA}/${body}`)
}

let token;

export function* contactEmail(action) {
  try {
    if (token) {
      token.cancel();
    }
    token = CancelToken.source();
    let res = yield contactUsApi({ ...action.body }, token);
    yield put(contactSuccess());
    // console.log("res: ", res);
    info("success", `${res.data.data.message}`);
    if (action.cb) {
      action.cb();
    }
  } catch (err) {
    if (err.response) {
      //console.log("ERROR", err);
      info("error", `${err.response.data.error.message}`);
    }
  }
}
export function* deleteDemoData(action) {
  try {
    let res = yield deleteDemoDataApi(action.lawyer_id)
    localStorage.removeItem("guide")
    localStorage.removeItem("step-no")
    localStorage.removeItem("demo_user")
    localStorage.removeItem("valid")
    localStorage.removeItem("assigned")
    localStorage.removeItem("rates")
    localStorage.removeItem("rate")
    localStorage.removeItem("timeLawyer")
    localStorage.removeItem("time_lawyer_id")
    localStorage.removeItem("termonreciept")
    localStorage.removeItem("ExportReportformats")
    localStorage.removeItem("quantity")
    localStorage.removeItem("lawyer")
    localStorage.removeItem("quantities")
    localStorage.removeItem("lawyer_id")
    if (action.cb) {
      action.cb();
    }
    yield put(endTourSuccess());
    info("success", res?.data?.data?.message)
  } catch (error) {
    info("error", `${error?.response?.data?.error?.message}`)
    yield put(endTourError("error occured"))
  }
}

// Our watcher Saga: spawn a new incrementAsync task on each INCREMENT_ASYNC
export function* watchAppActions() {
  // yield takeLatest(CHANGE_PASSWORD_REQUEST, changePassword);
  yield takeLatest(CONTACT_REQUEST, contactEmail);
  yield takeLatest(DELETE_DEMO_REQUEST, deleteDemoData);
  // yield takeLatest(setPushToken.toString(), setToken);
}
