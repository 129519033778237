import {
  GET_ORGANIZATION_REQUEST,
  GET_ORGANIZATION_SUCCESS,
  GET_ORGANIZATION_ERROR,
} from "configurations/Types";

export function getOrganizationRequest() {
  return {
    type: GET_ORGANIZATION_REQUEST,
  };
}

export function getOrganizationSuccess(payload) {
  console.log("ISDAIADSISDI:", payload);
  return {
    type: GET_ORGANIZATION_SUCCESS,
    payload,
  };
}

export function getOrganizationError(error) {
  return {
    type: GET_ORGANIZATION_ERROR,
    error,
  };
}
