import {
  ADD_LAWYER_REQUEST,
  ADD_LAWYER_SUCCESS,
  ADD_LAWYER_ERROR,
  ADD_CUSTOMER_REQUEST,
  ADD_CUSTOMER_SUCCESS,
  ADD_CUSTOMER_ERROR,
  ADD_JOB_REQUEST,
  ADD_JOB_SUCCESS,
  ADD_JOB_ERROR,
  ADD_TIME_ACCOUNT_SUCCESS,
  ADD_TIME_ACCOUNT_ERROR,
  ADD_TIME_ACCOUNT_REQUEST,
  ADD_FIXED_COST_ACCOUNT_SUCCESS,
  ADD_FIXED_COST_ACCOUNT_ERROR,
  ADD_FIXED_COST_ACCOUNT_REQUEST,
  ADD_EXPENSE_ACCOUNT_ERROR,
  ADD_EXPENSE_ACCOUNT_REQUEST,
  ADD_EXPENSE_ACCOUNT_SUCCESS,
  ADD_VENDOR_REQUEST,
  ADD_VENDOR_SUCCESS,
  ADD_VENDOR_ERROR,
  ADD_CREDIT_CARD_ERROR,
  ADD_CREDIT_CARD_SUCCESS,
  ADD_CREDIT_CARD_REQUEST,

  //GET types
  FETCH_LAWYERS_REQUEST,
  FETCH_LAWYERS_SUCCESS,
  FETCH_LAWYERS_ERROR,
  FETCH_CUSTOMERS_REQUEST,
  FETCH_CUSTOMERS_SUCCESS,
  FETCH_CUSTOMERS_ERROR,
  FETCH_JOBS_REQUEST,
  FETCH_JOBS_SUCCESS,
  FETCH_JOBS_ERROR,
  FETCH_ALL_ACCOUNTS_REQUEST,
  FETCH_ALL_ACCOUNTS_SUCCESS,
  FETCH_ALL_ACCOUNTS_ERROR,
  FETCH_TIME_ACCOUNTS_REQUEST,
  FETCH_TIME_ACCOUNTS_SUCCESS,
  FETCH_TIME_ACCOUNTS_ERROR,
  FETCH_FIXED_COST_ACCOUNTS_REQUEST,
  FETCH_FIXED_COST_ACCOUNTS_SUCCESS,
  FETCH_FIXED_COST_ACCOUNTS_ERROR,
  FETCH_EXPENSE_ACCOUNTS_REQUEST,
  FETCH_EXPENSE_ACCOUNTS_SUCCESS,
  FETCH_EXPENSE_ACCOUNTS_ERROR,
  FETCH_VENDORS_REQUEST,
  FETCH_VENDORS_SUCCESS,
  FETCH_VENDORS_ERROR,
  FETCH_CREDIT_CARDS_REQUEST,
  FETCH_CREDIT_CARDS_SUCCESS,
  FETCH_CREDIT_CARDS_ERROR,
  GET_DASHBOARD_REQUEST,
  GET_DASHBOARD_SUCCESS,
  GET_DASHBOARD_ERROR,

  // UPDATE types
  UPDATE_LAWYER_REQUEST,
  UPDATE_LAWYER_SUCCESS,
  UPDATE_LAWYER_ERROR,
  UPDATE_CUSTOMER_REQUEST,
  UPDATE_CUSTOMER_SUCCESS,
  UPDATE_CUSTOMER_ERROR,
  UPDATE_JOB_REQUEST,
  UPDATE_JOB_SUCCESS,
  UPDATE_JOB_ERROR,
  UPDATE_TIME_ACCOUNT_REQUEST,
  UPDATE_TIME_ACCOUNT_SUCCESS,
  UPDATE_TIME_ACCOUNT_ERROR,
  UPDATE_EXPENSE_ACCOUNT_REQUEST,
  UPDATE_EXPENSE_ACCOUNT_SUCCESS,
  UPDATE_EXPENSE_ACCOUNT_ERROR,
  UPDATE_VENDOR_REQUEST,
  UPDATE_VENDOR_SUCCESS,
  UPDATE_VENDOR_ERROR,
  UPDATE_CREDIT_CARD_REQUEST,
  UPDATE_CREDIT_CARD_SUCCESS,
  UPDATE_CREDIT_CARD_ERROR,

  // DELETE type
  DEL_LAWYER_REQUEST,
  DEL_LAWYER_SUCCESS,
  DEL_LAWYER_ERROR,
  DEL_CUSTOMER_REQUEST,
  DEL_CUSTOMER_SUCCESS,
  DEL_CUSTOMER_ERROR,
  DEL_JOB_REQUEST,
  DEL_JOB_SUCCESS,
  DEL_JOB_ERROR,
  DEL_TIME_ACCOUNT_REQUEST,
  DEL_TIME_ACCOUNT_SUCCESS,
  DEL_TIME_ACCOUNT_ERROR,
  DEL_EXPENSE_ACCOUNT_REQUEST,
  DEL_EXPENSE_ACCOUNT_SUCCESS,
  DEL_EXPENSE_ACCOUNT_ERROR,
  DEL_VENDOR_REQUEST,
  DEL_VENDOR_SUCCESS,
  DEL_VENDOR_ERROR,
  DEL_CREDIT_CARD_REQUEST,
  DEL_CREDIT_CARD_SUCCESS,
  DEL_CREDIT_CARD_ERROR,

  // Search types
  SEARCH_LAWYER_REQUEST,
  SEARCH_LAWYER_SUCCESS,
  SEARCH_CUSTOMER_REQUEST,
  SEARCH_CUSTOMER_SUCCESS,
  SEARCH_JOB_REQUEST,
  SEARCH_JOB_SUCCESS,
  SEARCH_TIME_ACCOUNT_REQUEST,
  SEARCH_TIME_ACCOUNT_SUCCESS,
  SEARCH_FIXED_COST_ACCOUNT_REQUEST,
  SEARCH_FIXED_COST_ACCOUNT_SUCCESS,
  SEARCH_EXPENSE_ACCOUNT_REQUEST,
  SEARCH_EXPENSE_ACCOUNT_SUCCESS,
  SEARCH_VENDOR_REQUEST,
  SEARCH_VENDOR_SUCCESS,
  SEARCH_CREDIT_CARD_REQUEST,
  SEARCH_CREDIT_CARD_SUCCESS,
  CLEAR_TYPE_SEARCH,

  // Details
  LAWYER_DETAILS_REQUEST,
  LAWYER_DETAILS_SUCCESS,
  LAWYER_DETAILS_ERROR,
  CUSTOMER_DETAILS_REQUEST,
  CUSTOMER_DETAILS_SUCCESS,
  CUSTOMER_DETAILS_ERROR,

  // Details PATCH
  LAWYER_ASSIGN_REQUEST,
  LAWYER_ASSIGN_SUCCESS,
  LAWYER_ASSIGN_ERROR,
  CUSTOMER_ASSIGN_REQUEST,
  CUSTOMER_ASSIGN_SUCCESS,
  CUSTOMER_ASSIGN_ERROR,
  LAWYER_DETAILS_EMPTY,
} from "configurations/Types";

const INITIAL_STATE = {
  dashboard: null,
  lawyer: [],
  lawyerDetails: [],
  customer: [],
  customerDetails: [],
  job: [],
  all_accounts: [],
  time_account: [],
  expense_account: [],
  fixed_cost_account: [],
  vendor: [],
  credit_card: [],
  // search state
  searched_lawyer: [],
  searched_customer: [],
  searched_job: [],
  searched_time_account: [],
  searched_fixed_cost_account: [],
  searched_expense_account: [],
  searched_vendor: [],
  searched_credit_card: [],
  search: "",
  // ---
  loading: false,
  dashboard_card_loading: false,
  details_loading: false,
};
const DashboardReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // get dashboard
    case GET_DASHBOARD_REQUEST: {
      return Object.assign({}, state, { dashboard_card_loading: true });
    }
    case GET_DASHBOARD_SUCCESS: {
      return Object.assign({}, state, {
        dashboard: { ...action.payload },
        dashboard_card_loading: false,
      });
    }
    case GET_DASHBOARD_ERROR: {
      return Object.assign({}, state, { dashboard_card_loading: false });
    }

    //ADD LAWYER
    case ADD_LAWYER_REQUEST: {
      return Object.assign({}, state, { loading: true });
    }

    case ADD_LAWYER_SUCCESS: {
      return Object.assign({}, state, { loading: false });
    }

    case ADD_LAWYER_ERROR: {
      return Object.assign({}, state, { loading: false });
    }

    //ADD CUSTOMER
    case ADD_CUSTOMER_REQUEST: {
      return Object.assign({}, state, { loading: true });
    }
    case ADD_CUSTOMER_SUCCESS: {
      return Object.assign({}, state, {
        loading: false,
      });
    }
    case ADD_CUSTOMER_ERROR: {
      return Object.assign({}, state, { loading: false });
    }

    //ADD JOB

    case ADD_JOB_REQUEST: {
      return Object.assign({}, state, { loading: true });
    }

    case ADD_JOB_SUCCESS: {
      return Object.assign({}, state, { loading: false });
    }

    case ADD_JOB_ERROR: {
      return Object.assign({}, state, { loading: false });
    }

    //ADD TIME ACCOUNT

    case ADD_TIME_ACCOUNT_REQUEST: {
      return Object.assign({}, state, { loading: true });
    }

    case ADD_TIME_ACCOUNT_SUCCESS: {
      return Object.assign({}, state, {
        loading: false,
      });
    }

    case ADD_TIME_ACCOUNT_ERROR: {
      return Object.assign({}, state, { loading: false });
    }
    //ADD FIXED COST ACCOUNT

    case ADD_FIXED_COST_ACCOUNT_REQUEST: {
      return Object.assign({}, state, { loading: true });
    }

    case ADD_FIXED_COST_ACCOUNT_SUCCESS: {
      return Object.assign({}, state, {
        loading: false,
      });
    }

    case ADD_FIXED_COST_ACCOUNT_ERROR: {
      return Object.assign({}, state, { loading: false });
    }
    //ADD EXPENSE ACCOUNT

    case ADD_EXPENSE_ACCOUNT_REQUEST: {
      return Object.assign({}, state, { loading: true });
    }

    case ADD_EXPENSE_ACCOUNT_SUCCESS: {
      return Object.assign({}, state, {
        loading: false,
      });
    }

    case ADD_EXPENSE_ACCOUNT_ERROR: {
      return Object.assign({}, state, { loading: false });
    }

    //ADD VENDOR

    case ADD_VENDOR_REQUEST: {
      return Object.assign({}, state, { loading: true });
    }

    case ADD_VENDOR_SUCCESS: {
      return Object.assign({}, state, {
        loading: false,
      });
    }

    case ADD_VENDOR_ERROR: {
      return Object.assign({}, state, { loading: false });
    }

    //ADD CREDIT CARD

    case ADD_CREDIT_CARD_REQUEST: {
      return Object.assign({}, state, { loading: true });
    }

    case ADD_CREDIT_CARD_SUCCESS: {
      return Object.assign({}, state, {
        loading: false,
      });
    }

    case ADD_CREDIT_CARD_ERROR: {
      return Object.assign({}, state, { loading: false });
    }

    /*-----------------------------------------------FETCH REQUESTS--------------------------------------------*/
    //FETCH LAWYERS
    case FETCH_LAWYERS_REQUEST: {
      return Object.assign({}, state, {
        loading: true,
      });
    }

    case FETCH_LAWYERS_SUCCESS: {
      return Object.assign({}, state, { loading: false, lawyer: action.body });
    }

    case FETCH_LAWYERS_ERROR: {
      return Object.assign({}, state, { loading: false });
    }

    //FETCH CUSTOMERS
    case FETCH_CUSTOMERS_REQUEST: {
      return Object.assign({}, state, {
        loading: true,
      });
    }

    case FETCH_CUSTOMERS_SUCCESS: {
      return Object.assign({}, state, {
        loading: false,
        customer: action.body,
      });
    }

    case FETCH_CUSTOMERS_ERROR: {
      return Object.assign({}, state, { loading: false });
    }

    //FETCH JOBS
    case FETCH_JOBS_REQUEST: {
      return Object.assign({}, state, {
        loading: true,
      });
    }

    case FETCH_JOBS_SUCCESS: {
      return Object.assign({}, state, { loading: false, job: action.body });
    }

    case FETCH_JOBS_ERROR: {
      return Object.assign({}, state, { loading: false });
    }

    //FETCH ALL ACCOUNTS
    case FETCH_ALL_ACCOUNTS_REQUEST: {
      return Object.assign({}, state, {
        loading: true,
      });
    }

    case FETCH_ALL_ACCOUNTS_SUCCESS: {
      console.log('FETCHH ALL ACCOUNTSS REDUCERSS', action.body)
      return Object.assign({}, state, {
        loading: false,
        all_accounts: action.body,
      });
    }

    case FETCH_ALL_ACCOUNTS_ERROR: {
      return Object.assign({}, state, { loading: false });
    }

    //FETCH TIME ACCOUNTS
    case FETCH_TIME_ACCOUNTS_REQUEST: {
      return Object.assign({}, state, {
        loading: true,
      });
    }

    case FETCH_TIME_ACCOUNTS_SUCCESS: {
      return Object.assign({}, state, {
        loading: false,
        time_account: action.body,
      });
    }

    case FETCH_TIME_ACCOUNTS_ERROR: {
      return Object.assign({}, state, { loading: false });
    }
    //FETCH FIXED COST ACCOUNTS
    case FETCH_FIXED_COST_ACCOUNTS_REQUEST: {
      return Object.assign({}, state, {
        loading: true,
      });
    }

    case FETCH_FIXED_COST_ACCOUNTS_SUCCESS: {
      return Object.assign({}, state, {
        loading: false,
        fixed_cost_account: action.body,
      });
    }

    case FETCH_FIXED_COST_ACCOUNTS_ERROR: {
      return Object.assign({}, state, { loading: false });
    }

    //FETCH EXPENSE ACCOUNTS
    case FETCH_EXPENSE_ACCOUNTS_REQUEST: {
      return Object.assign({}, state, {
        loading: true,
      });
    }

    case FETCH_EXPENSE_ACCOUNTS_SUCCESS: {
      return Object.assign({}, state, {
        loading: false,
        expense_account: action.body,
      });
    }

    case FETCH_EXPENSE_ACCOUNTS_ERROR: {
      return Object.assign({}, state, { loading: false });
    }

    //FETCH VENDORS
    case FETCH_VENDORS_REQUEST: {
      return Object.assign({}, state, {
        loading: true,
      });
    }

    case FETCH_VENDORS_SUCCESS: {
      return Object.assign({}, state, { loading: false, vendor: action.body });
    }

    case FETCH_VENDORS_ERROR: {
      return Object.assign({}, state, { loading: false });
    }

    //FETCH CREDIT CARDS
    case FETCH_CREDIT_CARDS_REQUEST: {
      return Object.assign({}, state, {
        loading: true,
      });
    }

    case FETCH_CREDIT_CARDS_SUCCESS: {
      return Object.assign({}, state, {
        loading: false,
        credit_card: action.body,
      });
    }

    case FETCH_CREDIT_CARDS_ERROR: {
      return Object.assign({}, state, { loading: false });
    }

    // -------------------------- Update reducers =--------------------------

    case UPDATE_LAWYER_REQUEST: {
      return Object.assign({}, state, { loading: true, details_loading: true });
    }
    case UPDATE_LAWYER_SUCCESS: {
      return Object.assign({}, state, { loading: false, details_loading: false });
    }
    case UPDATE_LAWYER_ERROR: {
      return Object.assign({}, state, { loading: false, details_loading: false });
    }

    case UPDATE_CUSTOMER_REQUEST: {
      return Object.assign({}, state, { loading: true, details_loading: true });
    }
    case UPDATE_CUSTOMER_SUCCESS: {
      return Object.assign({}, state, { loading: false, details_loading: false });
    }
    case UPDATE_CUSTOMER_ERROR: {
      return Object.assign({}, state, { loading: false, details_loading: false });
    }

    case UPDATE_JOB_REQUEST: {
      return Object.assign({}, state, { loading: true });
    }
    case UPDATE_JOB_SUCCESS: {
      return Object.assign({}, state, { loading: false });
    }
    case UPDATE_JOB_ERROR: {
      return Object.assign({}, state, { loading: false });
    }

    case UPDATE_TIME_ACCOUNT_REQUEST: {
      return Object.assign({}, state, { loading: true });
    }
    case UPDATE_TIME_ACCOUNT_SUCCESS: {
      return Object.assign({}, state, { loading: false });
    }
    case UPDATE_TIME_ACCOUNT_ERROR: {
      return Object.assign({}, state, { loading: false });
    }

    case UPDATE_EXPENSE_ACCOUNT_REQUEST: {
      return Object.assign({}, state, { loading: true });
    }
    case UPDATE_EXPENSE_ACCOUNT_SUCCESS: {
      return Object.assign({}, state, { loading: false });
    }
    case UPDATE_EXPENSE_ACCOUNT_ERROR: {
      return Object.assign({}, state, { loading: false });
    }

    case UPDATE_VENDOR_REQUEST: {
      return Object.assign({}, state, { loading: true });
    }
    case UPDATE_VENDOR_SUCCESS: {
      return Object.assign({}, state, { loading: false });
    }
    case UPDATE_VENDOR_ERROR: {
      return Object.assign({}, state, { loading: false });
    }

    case UPDATE_CREDIT_CARD_REQUEST: {
      return Object.assign({}, state, { loading: true });
    }
    case UPDATE_CREDIT_CARD_SUCCESS: {
      return Object.assign({}, state, { loading: false });
    }
    case UPDATE_CREDIT_CARD_ERROR: {
      return Object.assign({}, state, { loading: false });
    }

    // -------------------------- Get Details reducers ---------------------------
    case LAWYER_DETAILS_REQUEST: {
      return Object.assign({}, state, {
        loading: true,
      });
    }
    case LAWYER_DETAILS_SUCCESS: {
      return Object.assign({}, state, {
        loading: false,
        lawyerDetails: action.payload,
      });
    }
    case LAWYER_DETAILS_ERROR: {
      return Object.assign({}, state, { loading: false });
    }
    case LAWYER_DETAILS_EMPTY: {
      return Object.assign({}, state, { lawyerDetails: [] });
    }

    case CUSTOMER_DETAILS_REQUEST: {
      return Object.assign({}, state, {
        loading: true,
      });
    }
    case CUSTOMER_DETAILS_SUCCESS: {
      return Object.assign({}, state, {
        loading: false,
        customerDetails: action.payload,
      });
    }
    case CUSTOMER_DETAILS_ERROR: {
      return Object.assign({}, state, { loading: false });
    }

    // -------------------------- Assign Details reducers ---------------------------
    case LAWYER_ASSIGN_REQUEST: {
      return Object.assign({}, state, { loading: true });
    }
    case LAWYER_ASSIGN_SUCCESS: {
      return Object.assign({}, state, { loading: false });
    }
    case LAWYER_ASSIGN_ERROR: {
      return Object.assign({}, state, { loading: false });
    }

    case CUSTOMER_ASSIGN_REQUEST: {
      return Object.assign({}, state, { loading: true });
    }
    case CUSTOMER_ASSIGN_SUCCESS: {
      return Object.assign({}, state, { loading: false });
    }
    case CUSTOMER_ASSIGN_ERROR: {
      return Object.assign({}, state, { loading: false });
    }
    // -------------------------- Delete reducers =--------------------------
    case DEL_LAWYER_REQUEST: {
      return Object.assign({}, state, { loading: true });
    }
    case DEL_LAWYER_SUCCESS: {
      return Object.assign({}, state, { loading: false });
    }
    case DEL_LAWYER_ERROR: {
      return Object.assign({}, state, { loading: false });
    }

    case DEL_CUSTOMER_REQUEST: {
      return Object.assign({}, state, { loading: true });
    }
    case DEL_CUSTOMER_SUCCESS: {
      return Object.assign({}, state, { loading: false });
    }
    case DEL_CUSTOMER_ERROR: {
      return Object.assign({}, state, { loading: false });
    }

    case DEL_JOB_REQUEST: {
      return Object.assign({}, state, { loading: true });
    }
    case DEL_JOB_SUCCESS: {
      return Object.assign({}, state, { loading: false });
    }
    case DEL_JOB_ERROR: {
      return Object.assign({}, state, { loading: false });
    }

    case DEL_TIME_ACCOUNT_REQUEST: {
      return Object.assign({}, state, { loading: true });
    }
    case DEL_TIME_ACCOUNT_SUCCESS: {
      return Object.assign({}, state, { loading: false });
    }
    case DEL_TIME_ACCOUNT_ERROR: {
      return Object.assign({}, state, { loading: false });
    }

    case DEL_EXPENSE_ACCOUNT_REQUEST: {
      return Object.assign({}, state, { loading: true });
    }
    case DEL_EXPENSE_ACCOUNT_SUCCESS: {
      return Object.assign({}, state, { loading: false });
    }
    case DEL_EXPENSE_ACCOUNT_ERROR: {
      return Object.assign({}, state, { loading: false });
    }

    case DEL_VENDOR_REQUEST: {
      return Object.assign({}, state, { loading: true });
    }
    case DEL_VENDOR_SUCCESS: {
      return Object.assign({}, state, { loading: false });
    }
    case DEL_VENDOR_ERROR: {
      return Object.assign({}, state, { loading: false });
    }

    case DEL_CREDIT_CARD_REQUEST: {
      return Object.assign({}, state, { loading: true });
    }
    case DEL_CREDIT_CARD_SUCCESS: {
      return Object.assign({}, state, { loading: false });
    }
    case DEL_CREDIT_CARD_ERROR: {
      return Object.assign({}, state, { loading: false });
    }

    // -------------------------- search reducers =--------------------------
    case SEARCH_LAWYER_REQUEST: {
      return Object.assign({}, state, { loading: true, search: action.search });
    }
    case SEARCH_LAWYER_SUCCESS: {
      let search = state.search.toLocaleLowerCase();
      let searchArr = state.lawyer.filter(
        (obj) =>
          obj.lawyer_title.toLocaleLowerCase().includes(search) ||
          obj.lawyer_name.toLocaleLowerCase().includes(search)
      );

      return Object.assign({}, state, {
        loading: false,
        searched_lawyer: searchArr,
      });
    }

    case SEARCH_CUSTOMER_REQUEST: {
      return Object.assign({}, state, { loading: true, search: action.search });
    }
    case SEARCH_CUSTOMER_SUCCESS: {
      let search = state.search.toLocaleLowerCase();
      let searchArr = state.customer.filter(
        (obj) => obj.customer_name.toLocaleLowerCase().includes(search)
        // obj.lawyer_name.toLocaleLowerCase().includes(search)
      );
      return Object.assign({}, state, {
        loading: false,
        searched_customer: searchArr,
      });
    }

    case SEARCH_JOB_REQUEST: {
      return Object.assign({}, state, { loading: true, search: action.search });
    }
    case SEARCH_JOB_SUCCESS: {
      let search = state.search.toLocaleLowerCase();
      let searchArr = state.job.filter(
        (obj) =>
          obj.job_name.toLocaleLowerCase().includes(search) ||
          obj.job_number.toLocaleLowerCase().includes(search)
      );
      return Object.assign({}, state, {
        loading: false,
        searched_job: searchArr,
      });
    }

    case SEARCH_TIME_ACCOUNT_REQUEST: {
      return Object.assign({}, state, { loading: true, search: action.search });
    }
    case SEARCH_TIME_ACCOUNT_SUCCESS: {
      let search = state.search.toLocaleLowerCase();
      let searchArr = state.time_account.filter((obj) =>
        obj.account_name.toLocaleLowerCase().includes(search)
      );
      return Object.assign({}, state, {
        loading: false,
        searched_time_account: searchArr,
      });
    }

    case SEARCH_FIXED_COST_ACCOUNT_REQUEST: {
      return Object.assign({}, state, { loading: true, search: action.search });
    }
    case SEARCH_FIXED_COST_ACCOUNT_SUCCESS: {
      let search = state.search.toLocaleLowerCase();
      let searchArr = state.fixed_cost_account.filter((obj) =>
        obj.account_name.toLocaleLowerCase().includes(search)
      );
      return Object.assign({}, state, {
        loading: false,
        searched_fixed_cost_account: searchArr,
      });
    }


    case SEARCH_EXPENSE_ACCOUNT_REQUEST: {
      return Object.assign({}, state, { loading: true, search: action.search });
    }
    case SEARCH_EXPENSE_ACCOUNT_SUCCESS: {
      let search = state.search.toLocaleLowerCase();
      let searchArr = state.expense_account.filter((obj) =>
        obj.account_name.toLocaleLowerCase().includes(search)
      );
      return Object.assign({}, state, {
        loading: false,
        searched_expense_account: searchArr,
      });
    }

    case SEARCH_VENDOR_REQUEST: {
      return Object.assign({}, state, { loading: true, search: action.search });
    }
    case SEARCH_VENDOR_SUCCESS: {
      let search = state.search.toLocaleLowerCase();
      let searchArr = state.vendor.filter(
        (obj) =>
          obj.vendor_name.toLocaleLowerCase().includes(search)
        // ||
        // obj.lawyer_name.toLocaleLowerCase().includes(search)
      );
      return Object.assign({}, state, {
        loading: false,
        searched_vendor: searchArr,
      });
    }

    case SEARCH_CREDIT_CARD_REQUEST: {
      return Object.assign({}, state, { loading: true, search: action.search });
    }
    case SEARCH_CREDIT_CARD_SUCCESS: {
      let search = state.search.toLocaleLowerCase();
      let searchArr = state.credit_card.filter(
        (obj) =>
          obj.credit_card_name.toLocaleLowerCase().includes(search)
        //  ||
        // obj.lawyer_name.toLocaleLowerCase().includes(search)
      );
      return Object.assign({}, state, {
        loading: false,
        searched_credit_card: searchArr,
      });
    }

    case CLEAR_TYPE_SEARCH: {
      return Object.assign({}, state, {
        searched_lawyer: [],
        searched_customer: [],
        searched_job: [],
        searched_time_account: [],
        searched_expense_account: [],
        searched_vendor: [],
        searched_credit_card: [],
        search: "",
      });
    }
    default: {
      return state;
    }
  }
};

export default DashboardReducer;
