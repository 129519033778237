import {
  GET_SUBSCRIPTION_REQUEST,
  GET_SUBSCRIPTION_SUCCESS,
  GET_SUBSCRIPTION_ERROR,
  BUY_SUBSCRIPTION_REQUEST,
  BUY_SUBSCRIPTION_SUCCESS,
  BUY_SUBSCRIPTION_ERROR,
  ALL_SUBSCRIPTION_ERROR,
  ALL_SUBSCRIPTION_SUCCESS,
  ALL_SUBSCRIPTION_REQUEST,
  CANCEL_SUBSCRIPTION_REQUEST,
  CANCEL_SUBSCRIPTION_SUCCESS,
  CANCEL_SUBSCRIPTION_ERROR,
  CHECK_SUBSCRIPTION_REQUEST,
  CHECK_SUBSCRIPTION_SUCCESS,
  CHECK_SUBSCRIPTION_ERROR
} from "../configurations/Types";

const INITIAL_STATE = {
  getSubscriptionLoading: false,
  buySubscriptionLoading: false,
  cancelSubcriptionLoading: false,
  subscriptions: [],
  invoiceSubscriptions: []
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_SUBSCRIPTION_REQUEST:
      return Object.assign({}, state, { getSubscriptionLoading: true });

    case GET_SUBSCRIPTION_SUCCESS:
      return Object.assign({}, state, { getSubscriptionLoading: false });

    case GET_SUBSCRIPTION_ERROR:
      return Object.assign({}, state, { getSubscriptionLoading: false });

      case ALL_SUBSCRIPTION_REQUEST:
        return Object.assign({}, state);
  
      case ALL_SUBSCRIPTION_SUCCESS:
        return Object.assign({}, state, { subscriptions: action.payload});
  
      case ALL_SUBSCRIPTION_ERROR:
        return Object.assign({}, state);

    case BUY_SUBSCRIPTION_REQUEST:
      return Object.assign({}, state, { buySubscriptionLoading: true });

    case BUY_SUBSCRIPTION_SUCCESS:
      return Object.assign({}, state, { buySubscriptionLoading: false });

    case BUY_SUBSCRIPTION_ERROR:
      return Object.assign({}, state, { buySubscriptionLoading: false });

    case CANCEL_SUBSCRIPTION_REQUEST:
      return Object.assign({}, state, { cancelSubcriptionLoading: true });

    case CANCEL_SUBSCRIPTION_SUCCESS:
      return Object.assign({}, state, { cancelSubcriptionLoading: false });

    case CANCEL_SUBSCRIPTION_ERROR:
      return Object.assign({}, state, { cancelSubcriptionLoading: false });

      case CHECK_SUBSCRIPTION_REQUEST:
        return Object.assign({}, state, { invoiceSubscriptions: []});
  
      case CHECK_SUBSCRIPTION_SUCCESS:{
        console.log("ACTION PAYLOAD: ", action.payload);
        return Object.assign({}, state, { invoiceSubscriptions: action.payload });
      }
  
      case CHECK_SUBSCRIPTION_ERROR:
        return Object.assign({}, state, { invoiceSubscriptions: []});
    default: {
      return state;
    }
  }
};
