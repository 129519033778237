import {
  GET_SNAPSHOT_REQUEST,
  GET_SNAPSHOT_SUCCESS,
  GET_SNAPSHOT_ERROR,
} from "configurations/Types";
import moment from "moment";




const INITIAL_STATE = {
  snap: {},
  loading: false,
  monthlyIncome: [],
  invoiceIncome:[]
};

const SnapshotReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_SNAPSHOT_REQUEST: {
      return Object.assign({}, state, { loading: true });
    }
    case GET_SNAPSHOT_SUCCESS: {
      console.log(action,"PAYLOADDDD")
  
     

      
      let snapShotData = action.payload.snapshot;
      

        // action?.date_type == "yearly" ?
      if (action.graph_type=='payments') {
        let recievedData = action.monthlyIncome
        var monthData = [
          { name: "Jan", count: 0 },
          { name: "Feb", count: 0 },
          { name: "Mar", count: 0 },
          { name: "Apr", count: 0 },
          { name: "May", count: 0 },
          { name: "Jun", count: 0 },
          { name: "Jul", count: 0 },
          { name: "Aug", count: 0 },
          { name: "Sep", count: 0 },
          { name: "Oct", count: 0 },
          { name: "Nov", count: 0 },
          { name: "Dec", count: 0 },
        ];
    
        monthData.forEach((item) => {
          recievedData.forEach((recievedDataItem) => {
            if (moment(recievedDataItem.date).format("MMM") === item.name) {
              item.count = recievedDataItem.amount;
            }
          });
        })
        return Object.assign({}, state, {
          loading: false,
          snap: snapShotData,
          monthlyIncome:monthData,
          // dailyIncome:dateData
        });
      }
  
      if(action.graph_type=='invoices' ) {
        // :
        let recievedData1 = action.invoiceIncome;
        var dateData = [
          { name: "Jan", count: 0 },
          { name: "Feb", count: 0 },
          { name: "Mar", count: 0 },
          { name: "Apr", count: 0 },
          { name: "May", count: 0 },
          { name: "Jun", count: 0 },
          { name: "Jul", count: 0 },
          { name: "Aug", count: 0 },
          { name: "Sep", count: 0 },
          { name: "Oct", count: 0 },
          { name: "Nov", count: 0 },
          { name: "Dec", count: 0 },
        ];
        
        
        dateData.forEach((item) => {
          recievedData1.forEach((recievedDataItem) => {
            if (moment(recievedDataItem.date).format("MMM") === item.name) {
              item.count = recievedDataItem.amount;
            }
          });
        });
        return Object.assign({}, state, {
          loading: false,
          snap: snapShotData,
          // monthlyIncome:monthData,
          invoiceIncome:dateData
        });
      }
   
    }
    case GET_SNAPSHOT_ERROR: {
      return Object.assign({}, state, { loading: false });
    }
    default: {
      return state;
    }
  }
};

export default SnapshotReducer;
