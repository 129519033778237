import {put, takeLatest } from "redux-saga/effects";
import { GET_CHARTS_REQUEST, GET_CHARTS_DROPDOWNS_REQUEST, CHART_REPORT_REQUEST } from "../configurations/Types";
import {
  CHARTS_ENDPOINT,
  CHART_DROPDOWNS_ENDPOINT,
  CHARTS_EXPORT_ENDPOINT
} from "../configurations/Constants";
import {
  getChartsError,
  getChartsSuccess,
  chartDropdownsSuccess,
  chartDropdownsError,
  chartReportSuccess,
  chartReportError
} from "../actions/Charts";
// import moment from "moment";
import info from "../components/message/index";
import { privateAgent } from "../configurations/AxiosAgent";

const getChartsAPI = body => {
  return privateAgent.get(`${CHARTS_ENDPOINT}`, {params: body});
}

function* getCharts(action) {
  let res;
  try {
    console.log("Charts API: ", action.body)
    res = yield getChartsAPI(action.body);
    yield put(getChartsSuccess(res.data.data, action.body.date_type));
    console.log("Charts Response", res);
  } catch (e) {
    if (e.response) {
      try {
        info("error", `${e.response.data.error.message}`);
      } catch(e) {}
    }
    if (action.cb) {
      action.cb(e);
    }
    yield put(getChartsError());
  }
}

const chartDropdownsAPI = () => {
  return privateAgent.get(`${CHART_DROPDOWNS_ENDPOINT}`);
}

function* chartDropdowns(action) {
  let res;
  try {
    console.log("Charts API: ", action);
    res = yield chartDropdownsAPI();

    console.log("Charts Response", res);
    yield put(chartDropdownsSuccess(res.data.data));
  } catch (e) {
    if (e.response) {
      try {
        info("error", `${e.response.data.error.message}`);
      } catch(e) {}
    }
    if (action.cb) {
      action.cb(e);
    }
    yield put(chartDropdownsError());
  }
}

// -- Get Chart Report
// let chartReportToken;

const chartReportAPI = (params, token) =>
  privateAgent.get(`${CHARTS_EXPORT_ENDPOINT}`, {
    // cancelToken: token.token,
    params: params
  });

function* chartReport(action) {
  console.log(action.params,"qqqqqqqq")
  if (action.params.date_type === 'all') {
    let start_day = "01"
    let start_month = "01"
    let start_year = new Date(new Date().setFullYear(new Date().getFullYear() - 50)).getFullYear()
    let end_day = new Date(new Date().setMonth(new Date().getMonth(11) + 1, 0)).getDate()
    let end_month = "12"
    let end_year = new Date(new Date().setFullYear(new Date().getFullYear() + 50)).getFullYear()

   action.params.start_date = `${start_year}-${start_month}-${start_day}`
   action.params.end_date = `${end_year}-${end_month}-${end_day}`
   action.params.date_type = "all"
  }


  let res;
  try {
    // if (chartReportToken) {
    //   chartReportToken.cancel();
    // }
    // chartReportToken = CancelToken.source();

    res = yield chartReportAPI(action.params
      // , chartReportToken
      );

    console.log("CHART REPORT API GET", res.data);

    yield put(chartReportSuccess(res.data.data));

    if (action.cb) {
      action.cb(res.data.data);
    }
  } 
  catch (e) {
    console.log("ERROR", e.response);
    
    if (e.response) {
      //info("error", `${e.response.data.error.message}`);
    }

    yield put(chartReportError(e));
  } 
  finally {

    // if (yield cancelled()) {
    //   console.log("CANCELLED");
    // }

  }
}


// Our watcher Saga: spawn a CHART on each GET_CHARTS_REQUEST
export function* watchCharts() {
  yield takeLatest(GET_CHARTS_REQUEST, getCharts);
  yield takeLatest(GET_CHARTS_DROPDOWNS_REQUEST, chartDropdowns);
  yield takeLatest(CHART_REPORT_REQUEST, chartReport);
}
