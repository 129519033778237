import { put, takeLatest, cancelled, takeEvery } from "redux-saga/effects";
import { privateAgent } from "configurations/AxiosAgent";
import { GET_SNAPSHOT_REQUEST } from "configurations/Types";
import info from "components/message/index";
import {
  USERS_URL,
  SNAPSHOT_ENDPOINT,
} from "configurations/Constants";
import { fetchSnapshotSuccess, fetchSnapshotError } from "actions/Snapshot";

const snapshotApi = (year,graph_type) =>
  privateAgent.get(`${USERS_URL}${SNAPSHOT_ENDPOINT}`, { params: {
    year,
    graph_type
  }, });

export function* getSnapshot(params) {
  console.log(params,"PAPAPAPAP")
  let res;
  const { year, graph_type } = params;
  try {
    res = yield snapshotApi(year,graph_type);
    console.log("SNAPSHOT RES: ", res);
    yield put(fetchSnapshotSuccess(res.data.data,graph_type));
  } catch (e) {
    if (e.response?.data.error.message) {
      info("error", e.response?.data.error.message);
    }
    yield put(fetchSnapshotError(e.response));
  } finally {
    if (yield cancelled()) {
      // console.log("CANCELLED");
    }
  }
}

export function* watchSnapshotActions() {
  yield takeEvery(GET_SNAPSHOT_REQUEST, getSnapshot);
}
