import React from "react";
import { Row, Col, Popconfirm } from "antd";
import CardWrapper from "../card/index";
import { RiDeleteBinLine } from "react-icons/ri";
import RadioWrapper from "../radio/Radio";

const ListWrapper = ({
  cardStyle,
  radioStyle,
  iconStyle,
  textStyle,
  onHandleDelete,
  onRadioChange,
  isActive,
  isBankAccount,
  isPayment,
  ...props
}) => {
  return (
    <Row align="middle">
      {!isBankAccount && (
        <Col span={2}>
          <RadioWrapper
            checked={isActive == "true"}
            style={radioStyle}
            onChange={onRadioChange}
          />
        </Col>
      )}
      <Col lg={20} md={20} xs={24} sm={24} xl={20}>
        <CardWrapper style={cardStyle}>
          <Row align='middle'>
            <Col span={24}>
              {isPayment && (
                <h2>
                  Payment has been made
                  <span style={{ color: "rgb(88, 186, 39)" }}> successfully. </span>
                </h2>
              )}
              {!isPayment && <p style={textStyle}>{props.bankName}</p>}
            </Col>
          </Row>

        </CardWrapper>
      </Col>
      {!isBankAccount && (
        <Col span={2}>
          <Popconfirm
            title="Are you sure you want to delete this account from application？"
            okText="Yes"
            // disabled={item.status === "completed"}
            cancelText="No"
            onConfirm={onHandleDelete}
          >
            <RiDeleteBinLine style={iconStyle} size={20} />
          </Popconfirm>
        </Col>
      )}
    </Row>
  );
};

export default ListWrapper;
