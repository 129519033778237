import { combineReducers } from "redux";
import appReducer from "./App";
import modalReducer from "./Modal";
import authReducer from "./Auth";
import SnapshotReducer from "./Snapshot";
import OrganizationReducer from "./Organization";
import DashboardReducer from "./Dashboard";
import Settings from "./Settings";
import TransactionReducer from "./Transactions";
import InvoiceReducer from "./Invoices";
import Charts from "./Charts";
import ReportReducer from "./Report";
import Subscription from "./Subscription";
import StripeReducer from "./Stripe";

export default combineReducers({
  App: appReducer,
  Auth: authReducer,
  snapshot: SnapshotReducer,
  Dashboard: DashboardReducer,
  Org: OrganizationReducer,
  Modal: modalReducer,
  Settings,
  Charts,
  Subscription,
  Transactions: TransactionReducer,
  Invoices: InvoiceReducer,
  Reports: ReportReducer,
  StripeReducer,
});

// lazmi krna hai
// function combine(state, action) {
//     if(action.type === "logout"} {
//         state = null;
//     }
// }
