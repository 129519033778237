import {
  SEND_REPORT_REQUEST,
  SEND_REPORT_SUCCESS,
  SEND_REPORT_ERROR,
  GET_AR_ANALYSIS_REQUEST,
  GET_AR_ANALYSIS_SUCCESS,
  GET_AR_ANALYSIS_ERROR,
  GET_AGING_REPORT_REQUEST,
  GET_AGING_REPORT_SUCCESS,
  GET_AGING_REPORT_ERROR,
  CHART_OF_ACCOUNTS_REQUEST,
  CHART_OF_ACCOUNTS_SUCCESS,
  CHART_OF_ACCOUNTS_ERROR,
  AR_SEARCH_TOOL_REPORT_REQUEST,
  AR_SEARCH_TOOL_REPORT_SUCCESS,
  AR_SEARCH_TOOL_REPORT_ERROR,
  TRIAL_BALANCE_SUMMARY_REQUEST,
  TRIAL_BALANCE_SUMMARY_SUCCESS,
  TRIAL_BALANCE_SUMMARY_ERROR,
  CUSTOMER_BALANCE_SUMMARY_REQUEST,
  CUSTOMER_BALANCE_SUMMARY_SUCCESS,
  CUSTOMER_BALANCE_SUMMARY_ERROR,
  CUSTOMER_BALANCE_DETAIL_REQUEST,
  CUSTOMER_BALANCE_DETAIL_SUCCESS,
  CUSTOMER_BALANCE_DETAIL_ERROR,
  ACCOUNTS_RECEIVABLE_GRAPH_REQUEST,
  ACCOUNTS_RECEIVABLE_GRAPH_SUCCESS,
  ACCOUNTS_RECEIVABLE_GRAPH_ERROR,
  VENDORS_SUMMARY_REPORT_REQUEST,
  VENDORS_SUMMARY_REPORT_SUCCESS,
  VENDORS_SUMMARY_REPORT_ERROR,
  CREDIT_CARD_DETAIL_REPORT_REQUEST,
  CREDIT_CARD_DETAIL_REPORT_SUCCESS,
  CREDIT_CARD_DETAIL_REPORT_ERROR,
  LAWYERS_RANK_REPORT_REQUEST,
  LAWYERS_RANK_REPORT_SUCCESS,
  LAWYERS_RANK_REPORT_ERROR,
  JOB_RANK_REPORT_REQUEST,
  JOB_RANK_REPORT_SUCCESS,
  JOB_RANK_REPORT_ERROR,
  CUSTOMER_CREDIT_BALANCE_REPORT_REQUEST,
  CUSTOMER_CREDIT_BALANCE_REPORT_SUCCESS,
  CUSTOMER_CREDIT_BALANCE_REPORT_ERROR,
  AR_INCOME_METRICS_REQUEST,
  AR_INCOME_METRICS_SUCCESS,
  AR_INCOME_METRICS_ERROR,
  SUMMARY_REPORT_REQUEST,
  SUMMARY_REPORT_SUCCESS,
  SUMMARY_REPORT_ERROR
} from "../configurations/Types";

//Send report
export const sendReportRequest = (body, cb) => {
  return {
    type: SEND_REPORT_REQUEST,
    body,
    cb,
  };
};

export const sendReportSuccess = (payload) => {
  return {
    type: SEND_REPORT_SUCCESS,
    payload,
  };
};

export const sendReportError = (error) => {
  return {
    type: SEND_REPORT_ERROR,
    error,
  };
};

//get AR Analysis

export const getArAnalysisRequest = (params, cb) => {
  return {
    type: GET_AR_ANALYSIS_REQUEST,
    params,
    cb,
  };
};
//Send AR Search report



export const getArAnalysisSuccess = (payload) => {
  return {
    type: GET_AR_ANALYSIS_SUCCESS,
    payload,
  };
};


export const getArAnalysisError = (error) => {
  return {
    type: GET_AR_ANALYSIS_ERROR,
    error,
  };
};



//get Aging Reports

export const getAgingReportRequest = (params, cb) => {
  return {
    type:  GET_AGING_REPORT_REQUEST,
    params,
    cb,
  };
};

export const getAgingReportSuccess = (payload) => {
  return {
    type: GET_AGING_REPORT_SUCCESS,
    payload,
  };
};

export const getAgingReportError = (error) => {
  return {
    type: GET_AGING_REPORT_ERROR,
    error,
  };
};



//get Chart of Accounts

export const chartOfAccountsRequest = () => {
  return {
    type:  CHART_OF_ACCOUNTS_REQUEST,
  };
};

export const chartOfAccountsSuccess = (payload) => {
  return {
    type: CHART_OF_ACCOUNTS_SUCCESS,
    payload,
  };
};

export const chartOfAccountsError = (error) => {
  return {
    type: CHART_OF_ACCOUNTS_ERROR,
    error,
  };
};

export const arSearchToolReportRequest = (params, cb) => {
  return {
    type: AR_SEARCH_TOOL_REPORT_REQUEST,
    params,
    cb,
  };
};

export const arSearchToolReportSuccess = (payload) => {
  return {
    type: AR_SEARCH_TOOL_REPORT_SUCCESS,
    payload,
  };
};


export const arSearchToolReportError = (error) => {
  return {
    type: AR_SEARCH_TOOL_REPORT_ERROR,
    error,
  };
};

export const trialBalanceSummaryRequest = (params, cb) => {
  return {
    type:  TRIAL_BALANCE_SUMMARY_REQUEST,
    params,
    cb,
  };
};

export const trialBalanceSummarySuccess = (payload) => {
  return {
    type: TRIAL_BALANCE_SUMMARY_SUCCESS,
    payload,
  };
};

export const trialBalanceSummaryError = (error) => {
  return {
    type: TRIAL_BALANCE_SUMMARY_ERROR,
    error,
  };
};

//Customer Balance Summary 

export const customerBalanceSummaryRequest=(params,cb)=>{
  return {
    type:CUSTOMER_BALANCE_SUMMARY_REQUEST,
    params,
    cb
  }
}

export const customerBalanceSummarySuccess=(payload)=>{
  return {
    type:CUSTOMER_BALANCE_SUMMARY_SUCCESS,
    payload
  }
}
export const customerBalanceSummaryError=(error)=>{
  return {
    type:CUSTOMER_BALANCE_SUMMARY_ERROR,
    error
  }
}


//Customer Balance Detail

export const customerBalanceDetailRequest=(params,cb)=>{
  return {
    type:CUSTOMER_BALANCE_DETAIL_REQUEST,
    params,
    cb
  }
}

export const customerBalanceDetailSuccess=(payload)=>{
  return {
    type:CUSTOMER_BALANCE_DETAIL_SUCCESS,
    payload
  }
}
export const customerBalanceDetailError=(error)=>{
  return {
    type:CUSTOMER_BALANCE_DETAIL_ERROR,
    error
  }
}


//Accounts receiveable graph

export const accountsReceivableGraphRequest=(params,cb)=>{
  return {
    type:ACCOUNTS_RECEIVABLE_GRAPH_REQUEST,
    params,
    cb
  }
}

export const accountsReceivableGraphSuccess=(payload)=>{
  return {
    type:ACCOUNTS_RECEIVABLE_GRAPH_SUCCESS,
    payload
  }
}
export const accountsReceivableGraphError=(error)=>{
  return {
    type:ACCOUNTS_RECEIVABLE_GRAPH_ERROR,
    error
  }
}



//Vendor Report Summary

export const vendorsSummaryReportRequest=(params,cb)=>{
  return {
    type:VENDORS_SUMMARY_REPORT_REQUEST,
    params,
    cb
  }
}

export const vendorsSummaryReportSuccess=(payload)=>{
  return {
    type:VENDORS_SUMMARY_REPORT_SUCCESS,
    payload
  }
}
export const vendorsSummaryReportError=(error)=>{
  return {
    type:VENDORS_SUMMARY_REPORT_ERROR,
    error
  }
}




//Credit Card Detail report

export const creditCardDetailReportRequest=(params,cb)=>{
  return {
    type:CREDIT_CARD_DETAIL_REPORT_REQUEST,
    params,
    cb
  }
}

export const creditCardDetailReportSuccess=(payload)=>{
  return {
    type:CREDIT_CARD_DETAIL_REPORT_SUCCESS,
    payload
  }
}
export const creditCardDetailReportError=(error)=>{
  return {
    type:CREDIT_CARD_DETAIL_REPORT_ERROR,
    error
  }
}




//Lawyers rank report

export const lawyersRankReportRequest=(params,cb)=>{
  return {
    type:LAWYERS_RANK_REPORT_REQUEST,
    params,
    cb
  }
}

export const lawyersRankReportSuccess=(payload)=>{
  return {
    type:LAWYERS_RANK_REPORT_SUCCESS,
    payload
  }
}
export const lawyersRankReportError=(error)=>{
  return {
    type:LAWYERS_RANK_REPORT_ERROR,
    error
  }
}

//Job rank report

export const jobRankReportRequest=(params,cb)=>{
  return {
    type:JOB_RANK_REPORT_REQUEST,
    params,
    cb
  }
}

export const jobRankReportSuccess=(payload)=>{
  return {
    type:JOB_RANK_REPORT_SUCCESS,
    payload
  }
}
export const jobRankReportError=(error)=>{
  return {
    type:JOB_RANK_REPORT_ERROR,
    error
  }
}


//Customers credit balance report

export const customerCreditBalanceReportRequest=(params,cb)=>{
  console.log("CUSTOMERR CREDDITTT BALANCE CB",cb)
  console.log("CUSTOMEMRR CREDDITTT BALANCE PARAMSSS",params)
  return {
    type:CUSTOMER_CREDIT_BALANCE_REPORT_REQUEST,
    params,
    cb
  }
}

export const customerCreditBalanceReportSuccess=(payload)=>{
  return {
    type:CUSTOMER_CREDIT_BALANCE_REPORT_SUCCESS,
    payload
  }
}
export const customerCreditBalanceReportError=(error)=>{
  return {
    type:CUSTOMER_CREDIT_BALANCE_REPORT_ERROR,
    error
  }
}


//AR Income Metrics

export const arIncomeMetricsRequest=(params,cb)=>{
  return {
    type:AR_INCOME_METRICS_REQUEST,
    params,
    cb
  }
}

export const arIncomeMetricsSuccess=(payload)=>{
  return {
    type:AR_INCOME_METRICS_SUCCESS,
    payload
  }
}
export const arIncomeMetricsError=(error)=>{
  return {
    type:AR_INCOME_METRICS_ERROR,
    error
  }
}

//Summary Report

export const summaryReportRequest=(params,cb)=>{
  return {
    type:SUMMARY_REPORT_REQUEST,
    params,
    cb
  }
}

export const summaryReportSuccess=(payload)=>{
  return {
    type:SUMMARY_REPORT_SUCCESS,
    payload
  }
}
export const summaryReportError=(error)=>{
  return {
    type:SUMMARY_REPORT_ERROR,
    error
  }
}
