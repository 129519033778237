import {
  GET_CHARTS_ERROR,
  GET_CHARTS_SUCCESS,
  GET_CHARTS_REQUEST,
  GET_CHARTS_DROPDOWNS_REQUEST,
  GET_CHARTS_DROPDOWNS_SUCCESS,
  GET_CHARTS_DROPDOWNS_ERROR,
  CHART_REPORT_REQUEST,
  CHART_REPORT_SUCCESS,
  CHART_REPORT_ERROR,
  CLEAR_CHARTS,
} from "../configurations/Types";

import moment from "moment";

const INITIAL_STATE = {
  chartLoad: false,
  totalAmount: null,
  totalIncome: null,
  totalExpense: null,
  totalFixedCost: null,
  currency: "",
  accountType: "",
  transactions: [],
  incomeTrans: [],
  expenseTrans: [],
  fixedCostTrans: [],
  transTotals: [],
  totalsByDate: [],

  dropdowns: {},
  chartDropdownsLoading: false,

  chartReportLoading: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_CHARTS_REQUEST: {
      return Object.assign({}, state, { chartLoad: true });
    }
    case GET_CHARTS_SUCCESS:
      let transIncome = [],
        transExpense = [],
        transFixedCost = [],
        totals = [],
        dateTotals = [];
      if (action.payload) {
        const {
          total_amount,
          currency,
          account_type,
          transaction,
          total_income,
          total_expense,
          total_fixedcost
          // date_type,
        } = action.payload;
        let { date_type } = action;
        // const [income, expense] = // Acha Tareeqa hai but abhi nhi! // Use "deconstruction" style assignment
        // transaction
        //   .reduce((result, element) => {
        //     result[element.transaction_type.toLowerCase() === "income" ? 0 : 1].push(element); // Determine and push to small/large arr
        //     return result;
        //   },
        //   [[], []]);
        // ---- Extract Income and Expense types on to a new array ----
        if (account_type === "both") {
          transIncome = transaction.filter(
            (val) => val.transaction_type.toLowerCase() === "time"
          );
          transExpense = transaction.filter(
            (val) => val.transaction_type.toLowerCase() === "expense"
          );
          transFixedCost= transaction.filter(
            (val) => val.transaction_type.toLowerCase() === "fixed cost"
          );  
          let byDate = {};

          totals = transaction.reduce(
            (total, curr) => {
              let dateString;
              if (date_type === "monthly") {
                let weekOfMonth =
                  moment(curr.create_date).week() -
                  moment(curr.create_date)
                    .subtract("days", moment(curr.create_date).date() - 1)
                    .week() +
                  1;
                dateString = moment(curr.create_date).format("MMM, YYYY");

                dateString = "Week " + weekOfMonth;
                //+ " " + dateString;
              } else if (date_type === "yearly") {
                dateString = moment(curr.create_date).format("MMM");
              } else if (date_type === "quaterly") {
                dateString = moment(curr.create_date).format("MMM");
              } else if (date_type === "custom") {
                //Either this or same as monthly above
                dateString = moment(curr.create_date).format("MMM");
              }

              if (curr.transaction_type.toLowerCase() === "time") {
                total[0].value = total[0].value + curr.value;
                total[0].label = curr.label;

                if (byDate[dateString]) {
                  byDate[dateString].time =
                    byDate[dateString].time + curr.value;
                } else {
                  byDate[dateString] = {
                    time: curr.value,
                    expense: 0,
                    label: curr.label,
                  };
                }
              } 
              else if (curr.transaction_type.toLowerCase() === "expense"){
                total[1].value = total[1].value + curr.value;
                total[1].label = curr.label;

                if (byDate[dateString]) {
                  byDate[dateString].expense =
                    byDate[dateString].expense + curr.value;
                } else {
                  byDate[dateString] = {
                    expense: curr.value,
                    time: 0,
                    label: curr.label,
                  };
                }
              }
              else {
                total[2].value = total[2].value + curr.value;
                total[2].label = curr.label;

                if (byDate[dateString]) {
                  byDate[dateString].expense =
                    byDate[dateString].expense + curr.value;
                } else {
                  byDate[dateString] = {
                    expense: curr.value,
                    time: 0,
                    label: curr.label,
                  };
                }
              }
              return total;
            },
            [
              {
                a: null,
                value: 0,
                label: "",
                d: null,
                e: null,
                account_name: "time",
              },
              {
                a: null,
                value: 0,
                label: "",
                d: null,
                e: null,
                account_name: "expense",
              },
              {
                a: null,
                value: 0,
                label: "",
                d: null,
                e: null,
                account_name: "fixed cost",
              },
            ]
          );

          dateTotals = Object.entries(byDate).map((val) => ({
            name: val[0],
            time: val[1].time,
            expense: val[1].expense,
            fixedcost: val[1]["fixed cost"],
            label: val[1].label,
          }));
        }
        return Object.assign({}, state, {
          chartLoad: false,
          totalAmount: total_amount,
          totalIncome: total_income,
          totalExpense: total_expense,
          totalFixedCost: total_fixedcost,
          currency,
          accountType: account_type,
          transactions: transaction,
          incomeTrans: transIncome,
          expenseTrans: transExpense,
          fixedCostTrans: transFixedCost,
          transTotals: totals,
          totalsByDate: dateTotals,
        });
      }
      
    case GET_CHARTS_ERROR:
      return Object.assign({}, state, { chartLoad: false });

    case GET_CHARTS_DROPDOWNS_REQUEST:
      return Object.assign({}, state, { chartDropdownsLoading: true });

    case GET_CHARTS_DROPDOWNS_SUCCESS:
      return Object.assign({}, state, { dropdowns: action.payload, chartDropdownsLoading: false });

    case GET_CHARTS_DROPDOWNS_ERROR:
      return Object.assign({}, state, { chartDropdownsLoading: false });

    
    case CHART_REPORT_REQUEST: {
      return Object.assign({}, state, { chartReportLoading: true });
    }
    case CHART_REPORT_SUCCESS: {
      return Object.assign({}, state, { chartReportLoading: false });
    }
    case CHART_REPORT_ERROR: {
      return Object.assign({}, state, { chartReportLoading: false });
    }


    case CLEAR_CHARTS:
      return { ...INITIAL_STATE, dropdowns: state.dropdowns };

    default:
      return state;
  }
};
