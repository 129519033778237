import {
  REGISTER_REQUEST,
  REGISTER_SUCCESS,
  REGISTER_ERROR,
  REGISTER_COMPLETE_REQUEST,
  REGISTER_COMPLETE_SUCCESS,
  REGISTER_COMPLETE_ERROR,
  SIGNUP_VALIDATION_REQUEST,
  SIGNUP_VALIDATION_SUCCESS,
  SIGNUP_VALIDATION_ERROR,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  RESEND_EMAIL,
  LOGOUT,
  PASSWORD_RESET_REQUEST,
  PASSWORD_RESET_SUCCESS,
  PASSWORD_RESET_ERROR,
  RESEND_CODE_REQUEST,
  RESEND_CODE_SUCCESS,
  RESEND_CODE_ERROR,
  VERIFY_TOKEN_REQUEST,
  VERIFY_TOKEN_SUCCESS,
  VERIFY_TOKEN_ERROR,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  CHANGE_HEADER_PROFILE_PICTURE,
} from "../configurations/Types";

export function registerRequest(body) {
  return {
    type: REGISTER_REQUEST,
    body,
  };
}

//Could use callbacks in success and error for loading bars or messages or could handle everything in reducer
export function registerSuccess(cb) {
  return {
    type: REGISTER_SUCCESS,
    cb,
  };
}

export function registerError() {
  return {
    type: REGISTER_ERROR,
  };
}
export function registerCompleteRequest(body, cb, isSubmitting) {
  return {
    type: REGISTER_COMPLETE_REQUEST,
    body,
    cb,
    isSubmitting
  };
}

//Could use callbacks in success and error for loading bars or messages or could handle everything in reducer
export function registerCompleteSuccess(payload) {
  return {
    type: REGISTER_COMPLETE_SUCCESS,
    payload
  };
}

export function registerCompleteError(err) {
  return {
    type: REGISTER_COMPLETE_ERROR,
    err
  };
}

export function loginRequest(credentials, cb, extras, navigationCallback ) {
  console.log('THIS IS NAVIGATION CALLBACK: ',  navigationCallback)

  return {
    type: LOGIN_REQUEST,
    body: credentials,
    cb,
    extras,
    navigationCallback
  };
}
//Signup validation Request action
export function signupValidationRquest(email, cb) {
  return {
    type: SIGNUP_VALIDATION_REQUEST,
    email,
    cb
  };
}

//signup email successfully validate
export function signupValidationSuccess(res, isEmailValid) {
  return {
    type: SIGNUP_VALIDATION_SUCCESS,
    payload: res,
    isEmailValid,
  };
}
////signup email error in validation
export function signupValidationError(res) {
  return {
    type: SIGNUP_VALIDATION_ERROR,
  };
}

//Could use callbacks in success and error for loading bars or messages or could handle everything in reducer
export function loginSuccess(profileDetails) {
  return {
    type: LOGIN_SUCCESS,
    payload: profileDetails,
  };
}

export function loginError(errorInfo) {
  return {
    type: LOGIN_ERROR,
    payload: errorInfo,
  };
}

export function resendEmailRequest(email) {
  return {
    type: RESEND_EMAIL,
    payload: email,
  };
}

export function logoutRequest(cb) {
  return {
    type: LOGOUT,
    cb
  };
}

//// Password Reset Step 1 Actions
export function passwordResetRequest(values, cb, resend) {
  return {
    type: PASSWORD_RESET_REQUEST,
    body: values.email,
    cb,
    resend,
  };
}

//Could use callbacks in success and error for loading bars or messages or could handle everything in reducer
export function passwordResetSuccess(code) {
  return {
    type: PASSWORD_RESET_SUCCESS,
    payload: code,
  };
}

export function passwordResetError(errorInfo) {
  return {
    type: PASSWORD_RESET_ERROR,
    payload: errorInfo,
  };
}
//// Password Reset Step 2 Actions Resend Code
export function resendCodeRequest(values, cb) {
  return {
    type: RESEND_CODE_REQUEST,
    body: values.email,
    cb,
  };
}

//Could use callbacks in success and error for loading bars or messages or could handle everything in reducer
export function resendCodeSuccess(code) {
  return {
    type: RESEND_CODE_SUCCESS,
    payload: code,
  };
}

export function resendCodeError(errorInfo) {
  return {
    type: RESEND_CODE_ERROR,
    payload: errorInfo,
  };
}
//// Password Reset Step 2 Actions Verfify Code
export function verificationCodeRequest(code, cb) {
  return {
    type: VERIFY_TOKEN_REQUEST,
    body: code,
    cb,
  };
}

//Could use callbacks in success and error for loading bars or messages or could handle everything in reducer
export function verificationCodeSuccess(code) {
  return {
    type: VERIFY_TOKEN_SUCCESS,
    payload: code,
  };
}

export function verificationCodeError(errorInfo) {
  return {
    type: VERIFY_TOKEN_ERROR,
    payload: errorInfo,
  };
}

//// Password Reset Step 3 Actions
export function changePasswordRequest(values, cb, extras) {
  let newData = { ...values, email: extras };
  return {
    type: RESET_PASSWORD_REQUEST,
    body: newData,
    cb,
  };
}

//Could use callbacks in success and error for loading bars or messages or could handle everything in reducer
export function changePasswordSuccess(code) {
  return {
    type: RESET_PASSWORD_SUCCESS,
    payload: code,
  };
}

export function changePasswordError(errorInfo) {
  return {
    type: RESET_PASSWORD_ERROR,
    payload: errorInfo,
  };
}

export function changeHeaderProfilePicture(payload) {
  return {
    type: CHANGE_HEADER_PROFILE_PICTURE,
    payload,
  };
}
