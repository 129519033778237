import React from "react";
import { InputNumber } from "antd";
import styled from "styled-components";

const NumberWrapper = styled(InputNumber)`
  & {
    width: 100%;
    background-color: transparent;
    color: ${props => props.theme[props.theme.mode].textColor.primary};
    border-radius: 0;
    border-top: 0;
    border-right: 0;
    border-left: 0;
    border-width: 0.15rem;
    border-color: ${props => props.theme[props.theme.mode].primary.main}66;
    font-style: italic;
    outline: none;
    box-shadow: none;
  }
  &:hover {
    border-color: ${props =>
      props.theme[props.theme.mode].primary.main} !important;
  }
  &:focus,
  &:active, & .ant-input-number-input-wrap {
    border-color: ${props =>
      props.theme[props.theme.mode].secondary.main} !important;
      outline: none;
  }
  
`;

const NumberWrap = props => {
  return (
    <React.Fragment>
      <NumberWrapper {...props} />
    </React.Fragment>
  );
};

export default NumberWrap;
