import {
  GET_OPEN_TRANSACTIONS_REQUEST,
  GET_OPEN_TRANSACTIONS_SUCCESS,
  GET_OPEN_TRANSACTIONS_ERROR,
  GET_RECURRING_TRANSACTIONS_ERROR,
  GET_RECURRING_TRANSACTIONS_SUCCESS,
  GET_RECURRING_TRANSACTIONS_REQUEST,
  GET_CLOSE_TRANSACTIONS_REQUEST,
  GET_CLOSE_TRANSACTIONS_SUCCESS,
  GET_CLOSE_TRANSACTIONS_ERROR,
  DEL_OPEN_TRANSACTION_REQUEST,
  DEL_OPEN_TRANSACTION_SUCCESS,
  DEL_OPEN_TRANSACTION_ERROR,
  ADD_OPEN_TIME_TRANSACTION_REQUEST,
  ADD_OPEN_TIME_TRANSACTION_SUCCESS,
  ADD_OPEN_TIME_TRANSACTION_ERROR,
  UPDATE_OPEN_TIME_TRANSACTION_REQUEST,
  UPDATE_OPEN_TIME_TRANSACTION_SUCCESS,
  UPDATE_OPEN_TIME_TRANSACTION_ERROR,
  ADD_EXPENSE_TRANSACTION_REQUEST,
  ADD_EXPENSE_TRANSACTION_SUCCESS,
  ADD_EXPENSE_TRANSACTION_ERROR,
  EDIT_EXPENSE_TRANSACTION_REQUEST,
  EDIT_EXPENSE_TRANSACTION_SUCCESS,
  EDIT_EXPENSE_TRANSACTION_ERROR,
  ADD_FIXED_COST_TRANSACTION_REQUEST,
  ADD_FIXED_COST_TRANSACTION_SUCCESS,
  ADD_FIXED_COST_TRANSACTION_ERROR,
  EDIT_FIXED_COST_TRANSACTION_REQUEST,
  EDIT_FIXED_COST_TRANSACTION_SUCCESS,
  EDIT_FIXED_COST_TRANSACTION_ERROR,
} from "configurations/Types";

const INITIAL_STATE = {
  loading: false,
  openTransactions: null,
  closeTransactions: null,
  recurringTransactions: null,
  addTimeTransactionLoading:false,
  addExpenseTransactionLoading:false,
  closeTransactionLoading: false,
  addFixedCostTransactionLoading: false
};

function TransactionReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_OPEN_TRANSACTIONS_REQUEST: {
      return Object.assign({}, state, {
        loading: true,
      });
    }
    case GET_OPEN_TRANSACTIONS_SUCCESS: {
      console.log("action trans: ", action.payload);
      return Object.assign({}, state, {
        loading: false,
        openTransactions: action.payload,
      });
    }
    case GET_OPEN_TRANSACTIONS_ERROR: {
      return Object.assign({}, state, {
        loading: false,
      });
    }
    case GET_RECURRING_TRANSACTIONS_REQUEST: {
      return Object.assign({}, state, {
        loading: true,
      });
    }
    case GET_RECURRING_TRANSACTIONS_SUCCESS: {
      console.log("action trans: ", action.payload);
      return Object.assign({}, state, {
        loading: false,
        recurringTransactions: action.payload,
      });
    }
    case GET_RECURRING_TRANSACTIONS_ERROR: {
      return Object.assign({}, state, {
        loading: false,
      });
    }
    case GET_CLOSE_TRANSACTIONS_REQUEST: {
      return Object.assign({}, state, {
        closeTransactionLoading: true,
      });
    }
    case GET_CLOSE_TRANSACTIONS_SUCCESS: {
      return Object.assign({}, state, {
        closeTransactionLoading: false,
        closeTransactions: action.payload,
      });
    }
    case GET_CLOSE_TRANSACTIONS_ERROR: {
      return Object.assign({}, state, {
        closeTransactionLoading: false,
      });
    }
    case DEL_OPEN_TRANSACTION_REQUEST:{
      return Object.assign({},state,{loading:true})
    }

    case DEL_OPEN_TRANSACTION_SUCCESS:{
      return Object.assign({},state,{loading:false})
    }
    case DEL_OPEN_TRANSACTION_ERROR:{
      return Object.assign({},state,{loading:false})
    
    }
   
    case ADD_OPEN_TIME_TRANSACTION_REQUEST:{
      return Object.assign({},state,{addTimeTransactionLoading:true})
    }
    
    case ADD_OPEN_TIME_TRANSACTION_SUCCESS:{
      return Object.assign({},state,{addTimeTransactionLoading:false})
    }
    
    case ADD_OPEN_TIME_TRANSACTION_ERROR:{
      return Object.assign({},state,{addTimeTransactionLoading:false})
    }
   
    case UPDATE_OPEN_TIME_TRANSACTION_REQUEST:{
      return Object.assign({},state,{addTimeTransactionLoading:true})
    }
    
    case UPDATE_OPEN_TIME_TRANSACTION_SUCCESS:{
      return Object.assign({},state,{addTimeTransactionLoading:false})
    }
    
    case UPDATE_OPEN_TIME_TRANSACTION_ERROR:{
      return Object.assign({},state,{addTimeTransactionLoading:false})
    }

    case ADD_EXPENSE_TRANSACTION_REQUEST:{
      return Object.assign({},state,{addExpenseTransactionLoading:true})
    }
    
    case ADD_EXPENSE_TRANSACTION_SUCCESS:{
      return Object.assign({},state,{addExpenseTransactionLoading:false})
    }
    
    case ADD_EXPENSE_TRANSACTION_ERROR:{
      return Object.assign({},state,{addExpenseTransactionLoading:false})
    }

    case ADD_FIXED_COST_TRANSACTION_REQUEST:{
      return Object.assign({},state,{addFixedCostTransactionLoading:true})
    }
    
    case ADD_FIXED_COST_TRANSACTION_SUCCESS:{
      return Object.assign({},state,{addFixedCostTransactionLoading:false})
    }
    
    case ADD_FIXED_COST_TRANSACTION_ERROR:{
      return Object.assign({},state,{addFixedCostTransactionLoading:false})
    }

    case EDIT_EXPENSE_TRANSACTION_REQUEST:{
      return Object.assign({},state,{addExpenseTransactionLoading:true})
    }
    
    case EDIT_EXPENSE_TRANSACTION_SUCCESS:{
      return Object.assign({},state,{addExpenseTransactionLoading:false})
    }
    
    case EDIT_EXPENSE_TRANSACTION_ERROR:{
      return Object.assign({},state,{addExpenseTransactionLoading:false})
    }

    case EDIT_FIXED_COST_TRANSACTION_REQUEST:{
      return Object.assign({},state,{addFixedTransactionLoading:true})
    }
    
    case EDIT_FIXED_COST_TRANSACTION_SUCCESS:{
      return Object.assign({},state,{addFixedTransactionLoading:false})
    }
    
    case EDIT_FIXED_COST_TRANSACTION_ERROR:{
      return Object.assign({},state,{addFixedTransactionLoading:false})
    }

    
    default: {
      return state;
    }
  }
}

export default TransactionReducer;