import {
  TOGGLE_MODE,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_ERROR,
  CONTACT_REQUEST,
  CONTACT_SUCCESS,
  CONTACT_ERROR,
  SET_HISTORY,
  REDIRECT,
  SET_TOUR,
  DELETE_DEMO_REQUEST,
  DELETE_DEMO_SUCCESS,
  DELETE_DEMO_ERROR,
} from "../configurations/Types";

import { setPushToken } from "actions/App";


const INITIAL_STATE = {
  pushToken: "",
  history: null,
  mode: "light",
  loading: false,
  hideWrapper: false,
  backButtonLabel: "",
  externalScreen: false,
  centered: false,
  guide: localStorage.getItem("guide") === 'true',

  contactFormLoading: false,

  // faqs: []
};

const AppReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case setPushToken.toString(): {
      return Object.assign({}, state, { pushToken: action.payload.token });
    }
    case TOGGLE_MODE: {
      return Object.assign({}, state, {
        mode: state.mode === "light" ? "dark" : "light",
      });
    }
    case CHANGE_PASSWORD_REQUEST: {
      return Object.assign({}, state, { loading: true });
    }
    case CHANGE_PASSWORD_SUCCESS: {
      return Object.assign({}, state, {
        profileDetails: action.payload,
        loading: false,
      });
    }
    case CHANGE_PASSWORD_ERROR: {
      return Object.assign({}, state, { loading: false });
    }

    case CONTACT_REQUEST: {
      return Object.assign({}, state, { contactFormLoading: true });
    }
    case CONTACT_SUCCESS: {
      return Object.assign({}, state, { contactFormLoading: false });
    }
    case CONTACT_ERROR: {
      return Object.assign({}, state, { contactFormLoading: false });
    }
    case SET_HISTORY: {
      return Object.assign({}, state, { history: action.payload });
    }
    case REDIRECT: {
      console.log("REDIRECT action", action);
      let url = action.payload;

      let externalScreen = false;
      let backButtonLabel = "";
      let centered = false;
      let hideWrapper = false;
      switch (url) {
        case "/signup": {
          externalScreen = true;
          backButtonLabel = "Sign up";
          centered = false;
          break;
        }
        case "/snapshot": {
          externalScreen = true;
          hideWrapper = true;
          // // backButtonLabel = "Sign up";
          // centered = false;
          break;
        }
        case "/subscribe": {
          externalScreen = true;
          hideWrapper = true;
          // // backButtonLabel = "Sign up";
          // centered = false;
          break;
        }
        case "/dashboard/users/add-user": {
          externalScreen = true;
          hideWrapper = true;
          // // backButtonLabel = "Sign up";
          // centered = false;
          break;
        }
        case "/dashboard/customers/add-customer": {
          externalScreen = true;
          hideWrapper = true;
          // // backButtonLabel = "Sign up";
          // centered = false;
          break;
        }
        case "/dashboard/jobs/add-jobs": {
          externalScreen = true;
          hideWrapper = true;
          // // backButtonLabel = "Sign up";
          // centered = false;
          break;
        }
        case "/dashboard/time-account/add-time-account": {
          externalScreen = true;
          hideWrapper = true;
          // // backButtonLabel = "Sign up";
          // centered = false;
          break;
        }
        case "/dashboard/fixed-cost-account/add-fixed-cost-account": {
          externalScreen = true;
          hideWrapper = true;
          // // backButtonLabel = "Sign up";
          // centered = false;
          break;
        }
        case "/dashboard/fixed-cost-account/fixed-cost-account": {
          externalScreen = true;
          hideWrapper = true;
          // // backButtonLabel = "Sign up";
          // centered = false;
          break;
        }
        case "/dashboard/expense-account/add-expense-account": {
          externalScreen = true;
          hideWrapper = true;
          // // backButtonLabel = "Sign up";
          // centered = false;
          break;
        }
        case "/dashboard/vendors/add-vendor": {
          externalScreen = true;
          hideWrapper = true;
          // // backButtonLabel = "Sign up";
          // centered = false;
          break;
        }
        case "/dashboard/cc-cards/add-credit-card": {
          externalScreen = true;
          hideWrapper = true;
          // // backButtonLabel = "Sign up";
          // centered = false;
          break;
        }
        case "/transactions/open/time-transaction-details/update-time-transaction": {
          externalScreen = true;
          hideWrapper = true;
          // // backButtonLabel = "Sign up";
          // centered = false;
          break;
        }
        case "/invoices/view-open-invoices/report": {
          externalScreen = true;
          hideWrapper = true;
          // // backButtonLabel = "Sign up";
          // centered = false;
          break;
        }
        case "/invoices/view-client-statements/reports": {
          externalScreen = true;
          hideWrapper = true;
          // // backButtonLabel = "Sign up";
          // centered = false;
          break;
        }


        case "/reports/aging-report/reports": {
          externalScreen = true;
          hideWrapper = true;
          // // backButtonLabel = "Sign up";
          // centered = false;
          break;
        }
        case "/reports/ar-search-tool/reports": {
          externalScreen = true;
          hideWrapper = true;
          // // backButtonLabel = "Sign up";
          // centered = false;
          break;
        }
        
        case "/charts/report": {
          externalScreen = true;
          hideWrapper = true;
          // // backButtonLabel = "Sign up";
          // centered = false;
          break;
        }
        case "/invoices/view-open-invoices/pay": {
          externalScreen = true;
          hideWrapper = true;
          // // backButtonLabel = "Sign up";
          // centered = false;
          break;
        }
        case "/invoices/view-open-invoices/paid-in-full": {
          externalScreen = true;
          hideWrapper = true;
          // // backButtonLabel = "Sign up";
          // centered = false;
          break;
        }
        case "/invoices/view-open-invoices/partial-payment": {
          externalScreen = true;
          hideWrapper = true;
          // // backButtonLabel = "Sign up";
          // centered = false;
          break;
        }
        case "/invoices/view-open-invoices/Over-Payment": {
          externalScreen = true;
          hideWrapper = true;
          // // backButtonLabel = "Sign up";
          // centered = false;
          break;
        }
        case "/invoices/view-open-invoices/Multiple-Export-Report": {
          externalScreen = true;
          hideWrapper = true;
          // // backButtonLabel = "Sign up";
          // centered = false;
          break;
        }
        case "/invoices/view-open-invoices/report/send-report": {
          externalScreen = true;
          hideWrapper = true;
          // // backButtonLabel = "Sign up";
          // centered = false;
          break;
        }
        case "/reports/chart-of-accounts/report/send-report": {
          externalScreen = true;
          hideWrapper = true;
          // // backButtonLabel = "Sign up";
          // centered = false;
          break;
        }

        //-------------------------CUSTOMER BALANCE SUMMARYYY---------------------------//

        case "/reports/ar-other-reports/customer-balance-summary": {
          externalScreen = true;
          hideWrapper = true;
          // // backButtonLabel = "Sign up";
          // centered = false;
          break;
        }
        case "/reports/ar-search-tool/reports/send-report": {
          externalScreen = true;
          hideWrapper = true;
          // // backButtonLabel = "Sign up";
          // centered = false;
          break;
        }
        case "/reports/Payment-History/reports": {
          externalScreen = true;
          hideWrapper = true;
          // // backButtonLabel = "Sign up";
          // centered = false;
          break;
        }
        case "/reports/ar-other-reports/customer-balance-summary/reports": {
          externalScreen = true;
          hideWrapper = true;
          // // backButtonLabel = "Sign up";
          // centered = false;
          break;
        }
        case "/reports/trial-balance-summary/reports": {
          externalScreen = true;
          hideWrapper = true;
          // // backButtonLabel = "Sign up";
          // centered = false;
          break;
        }

        //-------------------------CUSTOMER BALANCE DETAILSSSS---------------------------//

        case "/reports/ar-other-reports/customer-balance-detail": {
          externalScreen = true;
          hideWrapper = true;
          // // backButtonLabel = "Sign up";
          // centered = false;
          break;
        }
        case "/reports/trial-balance-summary/reports/send-report": {
          externalScreen = true;
          hideWrapper = true;
          // // backButtonLabel = "Sign up";
          // centered = false;
          break;
        }

        case "/reports/ar-other-reports/customer-balance-detail/reports": {
          externalScreen = true;
          hideWrapper = true;
          // // backButtonLabel = "Sign up";
          // centered = false;
          break;
        }

        //-------------------------ACCOUNTS RECEIVABLE GRAPHHH---------------------------//

        case "/reports/ar-other-reports/accounts-receivable-graph": {
          externalScreen = true;
          hideWrapper = true;
          // // backButtonLabel = "Sign up";
          // centered = false;
          break;
        }

        case "/reports/ar-other-reports/accounts-receivable-graph/reports": {
          externalScreen = true;
          hideWrapper = true;
          // // backButtonLabel = "Sign up";
          // centered = false;
          break;
        }

        //-------------------------VENDORS SUMMARYY REPORT---------------------------//

        case "/reports/ar-other-reports/vendors-summary-report": {
          externalScreen = true;
          hideWrapper = true;
          // // backButtonLabel = "Sign up";
          // centered = false;
          break;
        }
        case "/reports/ar-other-reports/vendors-summary-report/reports": {
          externalScreen = true;
          hideWrapper = true;
          // // backButtonLabel = "Sign up";
          // centered = false;
          break;
        }

        //-------------------------CREDIT CARD DETAIL Report---------------------------//

        case "/reports/ar-other-reports/credit-card-detail-report": {
          externalScreen = true;
          hideWrapper = true;
          // // backButtonLabel = "Sign up";
          // centered = false;
          break;
        }

        case "/reports/ar-other-reports/credit-card-detail-report/reports": {
          externalScreen = true;
          hideWrapper = true;
          // // backButtonLabel = "Sign up";
          // centered = false;
          break;
        }

        //-------------------------LAWYERRSSS RANKK REPORT---------------------------//

        case "/reports/ar-other-reports/users-rank-report": {
          externalScreen = true;
          hideWrapper = true;
          // // backButtonLabel = "Sign up";
          // centered = false;
          break;
        }

        case "/reports/ar-other-reports/users-rank-report/reports": {
          externalScreen = true;
          hideWrapper = true;
          // // backButtonLabel = "Sign up";
          // centered = false;
          break;
        }
         //-------------------------Job Rank Report---------------------------//

         case "/reports/ar-other-reports/job-rank-report": {
          externalScreen = true;
          hideWrapper = true;
          // // backButtonLabel = "Sign up";
          // centered = false;
          break;
        }

        case "/reports/ar-other-reports/job-rank-report/reports": {
          externalScreen = true;
          hideWrapper = true;
          // // backButtonLabel = "Sign up";
          // centered = false;
          break;
        }

        //-------------------------Customer Credit Balance REPORT---------------------------//

        case "/reports/ar-other-reports/customer-credit-balance-report": {
          externalScreen = true;
          hideWrapper = true;
          // // backButtonLabel = "Sign up";
          // centered = false;
          break;
        }

        case "/reports/ar-other-reports/customer-credit-balance-report/reports": {
          externalScreen = true;
          hideWrapper = true;
          // // backButtonLabel = "Sign up";
          // centered = false;
          break;
        }


        //-------------------------AR INCOME METRICS REPORT---------------------------//
        case "/reports/ar-other-reports/ar-income-metrics": {
          externalScreen = true;
          hideWrapper = true;
          // // backButtonLabel = "Sign up";
          // centered = false;
          break;
        }
        case "/reports/ar-other-reports/ar-income-metrics/reports": {
          externalScreen = true;
          hideWrapper = true;
          // // backButtonLabel = "Sign up";
          // centered = false;
          break;
        }

        //-------------------------SUMMARY REPORT---------------------------//
        case "/reports/ar-other-reports/summary-report": {
          externalScreen = true;
          hideWrapper = true;
          // // backButtonLabel = "Sign up";
          // centered = false;
          break;
        }
        case "/reports/ar-other-reports/summary-report/reports": {
          externalScreen = true;
          hideWrapper = true;
          // // backButtonLabel = "Sign up";
          // centered = false;
          break;
        }


        //-------------------------Manage Users---------------------------//
        case "/settings/manage-users/add-user": {
          externalScreen = true;
          hideWrapper = true;
          // // backButtonLabel = "Sign up";
          // centered = false;
          break;
        }

        case "/settings/taxable": {
          externalScreen = true;
          hideWrapper = true;
          // // backButtonLabel = "Sign up";
          // centered = false;
          break;
        }
        default: {
          // for non exact routes
          if (url.includes("/dashboard/users/update-user/")) {
            externalScreen = true;
            hideWrapper = true;
            // backButtonLabel = "Add Lawyer";
          }
          if (url.includes("/settings/manage-users/update-assign-user-role/")) {
            externalScreen = true;
            hideWrapper = true;
            // backButtonLabel = "Add Lawyer";
          }
          if (url.includes("/dashboard/customers/update-customer/")) {
            externalScreen = true;
            hideWrapper = true;
            // backButtonLabel = "Add Lawyer";
          }
          if (url.includes("/dashboard/jobs/update-job/")) {
            externalScreen = true;
            hideWrapper = true;
            // backButtonLabel = "Add Lawyer";
          }
          if (url.includes("/dashboard/time-account/update-time-account/")) {
            externalScreen = true;
            hideWrapper = true;
            // backButtonLabel = "Add Lawyer";
          }
          if (url.includes("/dashboard/fixed-cost-account/update-fixed-cost-account/")) {
            externalScreen = true;
            hideWrapper = true;
            // backButtonLabel = "Add Lawyer";
          }
          if (
            url.includes("/dashboard/expense-account/update-expense-account/")
          ) {
            externalScreen = true;
            hideWrapper = true;
            // backButtonLabel = "Add Lawyer";
          }
          if (url.includes("/dashboard/vendors/update-vendor/")) {
            externalScreen = true;
            hideWrapper = true;
            // backButtonLabel = "Add Lawyer";
          }
          if (url.includes("/dashboard/creditCards/update-credit-card/")) {
            externalScreen = true;
            hideWrapper = true;
            // backButtonLabel = "Add Lawyer";
          }
          if (url.includes("/dashboard/users/user/")) {
            externalScreen = true;
            hideWrapper = true;
            // backButtonLabel = "Add Lawyer";
          }
          if (url.includes("/dashboard/customers/customer/")) {
            externalScreen = true;
            hideWrapper = true;
            // backButtonLabel = "Add Lawyer";
          }
          console.log("CONSOLEEE1: ", hideWrapper);
          if (url.includes("/transactions/open/expense-transaction-details")) {
            console.log("CONSOLEEE2: ", hideWrapper);

            externalScreen = true;
            hideWrapper = true;
            console.log("CONSOLEEE3: ", hideWrapper);

            // backButtonLabel = "Add Lawyer";
          }
          if (url.includes("/transactions/open/fixed-cost-transaction-details")) {
            console.log("CONSOLEEE2: ", hideWrapper);

            externalScreen = true;
            hideWrapper = true;
            console.log("CONSOLEEE3: ", hideWrapper);

            // backButtonLabel = "Add Lawyer";
          }
          if (url.includes("/transactions/open/time-transaction-details")) {
            console.log("CONSOLEEE2: ", hideWrapper);

            externalScreen = true;
            hideWrapper = true;
            console.log("CONSOLEEE3: ", hideWrapper);

            // backButtonLabel = "Add Lawyer";
          }
          if (url.includes("/transactions/recurring/recurring-transaction-details")) {
            console.log("CONSOLEEE2: ", hideWrapper);

            externalScreen = true;
            hideWrapper = true;
            console.log("CONSOLEEE3: ", hideWrapper);

            // backButtonLabel = "Add Lawyer";
          }
          if (
            url.includes(
              "/transactions/open/time-transaction-details/update-time-transaction"
            )
          ) {
            externalScreen = true;
            hideWrapper = true;
            // backButtonLabel = "Edit time Transaction";
          }
        }
      }

      // console.log(externalScreen)

      return Object.assign({}, state, {
        externalScreen,
        backButtonLabel,
        hideWrapper,
        centered,
      });
    }
        //-------------------------OnBoarding---------------------------//
      case SET_TOUR:{
        return Object.assign({}, state, {
          guide: action.payload
        })
      }
      case DELETE_DEMO_REQUEST:{
        return Object.assign({}, state, {
          loading: true
        })
      }
      case DELETE_DEMO_SUCCESS:{
        return Object.assign({}, state, {
          guide: false,
          loading: false
        })
      }
      case DELETE_DEMO_ERROR:{
        return Object.assign({}, state, {
          guide: true,
          loading: false
        })
      }
    default: {
      return state;
    }
  }
};

export default AppReducer;
