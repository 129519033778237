import {
  EDIT_ORGANIZATION_PROFILE_REQUEST,
  EDIT_ORGANIZATION_PROFILE_SUCCESS,
  EDIT_ORGANIZATION_PROFILE_ERROR,
  GET_ORGANIZATION_PROFILE_REQUEST,
  GET_ORGANIZATION_PROFILE_SUCCESS,
  GET_ORGANIZATION_PROFILE_ERROR,
  UPDATE_PASSWORD_REQUEST,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_ERROR,
  UPDATE_SETTINGS_REQUEST,
  UPDATE_SETTINGS_SUCCESS,
  UPDATE_SETTINGS_ERROR,
  GET_SETTINGS_REQUEST,
  GET_SETTINGS_SUCCESS,
  GET_SETTINGS_ERROR,
  ASSIGN_USER_ROLE_REQUEST,
  ASSIGN_USER_ROLE_SUCCESS,
  ASSIGN_USER_ROLE_ERROR,
  FETCH_ASSIGN_USER_ROLE_REQUEST,
  FETCH_ASSIGN_USER_ROLE_SUCCESS,
  FETCH_ASSIGN_USER_ROLE_ERROR,
  FETCH_UNASSIGNED_USER_ROLE_REQUEST,
  FETCH_UNASSIGNED_USER_ROLE_SUCCESS,
  FETCH_UNASSIGNED_USER_ROLE_ERROR,
  EDIT_ASSIGN_USER_ROLE_REQUEST,
  EDIT_ASSIGN_USER_ROLE_SUCCESS,
  EDIT_ASSIGN_USER_ROLE_ERROR,
  DELETE_ASSIGN_USER_ROLE_REQUEST,
  DELETE_ASSIGN_USER_ROLE_SUCCESS,
  DELETE_ASSIGN_USER_ROLE_ERROR,
  SEARCH_ASSIGN_USER_ROLE_REQUEST,
  SEARCH_ASSIGN_USER_ROLE_SUCCESS,
  CLEAR_ASSIGN_USER_TYPE_SEARCH,
  CLEAR_UNASSIGNED_USER_TYPE_SEARCH
} from "configurations/Types";

const INITIAL_STATE = {
  organizationProfileLoading: false,
  updatePasswordLoading: false,
  updateSettingsLoading: false,
  assignUserRoleLoading: false,
  fetchAssignUserRoleLoading: false,
  fetchUnAssignedUserRoleLoading:false,
  deleteAssignUserRoleLoading: false,
  editAssignUserRoleLoading: false,
  searchAssignUserRoleLoading: false,
  search: "",
  settings: {},
  searched_assign_user_role: [],
  searched_unAssigned_user_role:[],
  assignUserRole: [],
  unAssignedUserRole:[]
};

const SettingsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case EDIT_ORGANIZATION_PROFILE_REQUEST: {
      return Object.assign({}, state, { organizationProfileLoading: true });
    }
    case EDIT_ORGANIZATION_PROFILE_SUCCESS: {
      return Object.assign({}, state, { organizationProfileLoading: false });
    }
    case EDIT_ORGANIZATION_PROFILE_ERROR: {
      return Object.assign({}, state, { organizationProfileLoading: false });
    }

    case GET_ORGANIZATION_PROFILE_REQUEST: {
      return Object.assign({}, state, { organizationProfileLoading: true });
    }
    case GET_ORGANIZATION_PROFILE_SUCCESS: {
      return Object.assign({}, state, { organizationProfileLoading: false });
    }
    case GET_ORGANIZATION_PROFILE_ERROR: {
      return Object.assign({}, state, { organizationProfileLoading: false });
    }
    case UPDATE_PASSWORD_REQUEST: {
      return Object.assign({}, state, { updatePasswordLoading: true });
    }
    case UPDATE_PASSWORD_SUCCESS: {
      return Object.assign({}, state, { updatePasswordLoading: false });
    }
    case UPDATE_PASSWORD_ERROR: {
      return Object.assign({}, state, { updatePasswordLoading: false });
    }
    case UPDATE_SETTINGS_REQUEST: {
      return Object.assign({}, state, { updateSettingsLoading: true });
    }
    case UPDATE_SETTINGS_SUCCESS: {
      return Object.assign({}, state, { updateSettingsLoading: false });
    }
    case UPDATE_SETTINGS_ERROR: {
      return Object.assign({}, state, { updateSettingsLoading: false });
    }

    // case GET_SETTINGS_REQUEST: {
    //   return Object.assign({}, state,);
    // }
    case GET_SETTINGS_SUCCESS: {
      return Object.assign({}, state, { settings: action.payload });
    }
    // case GET_SETTINGS_ERROR: {
    //   return Object.assign({}, state, );
    // }
    case SEARCH_ASSIGN_USER_ROLE_REQUEST: {
      return Object.assign({}, state, { loading: true, search: action.search });
    }
    case SEARCH_ASSIGN_USER_ROLE_SUCCESS: {
      console.log("STATE TO SEARCH", state);
      let search = state.search.toLocaleLowerCase();
      let searchArr = state.assignUserRole.filter(
        (obj) =>
          obj.full_name.toLocaleLowerCase().includes(search) ||
          obj.email.toLocaleLowerCase().includes(search) ||
          obj.user_role.toLocaleLowerCase().includes(search) ||
          obj.invitation_accepted.toLocaleLowerCase().includes(search)
        );

      return Object.assign({}, state, {
        searchAssignUserRoleLoading: false,
        searched_assign_user_role: searchArr,
      });
    }



    
    case ASSIGN_USER_ROLE_REQUEST: {
      return Object.assign({}, state, { assignUserRoleLoading: true });
    }
    case ASSIGN_USER_ROLE_SUCCESS: {
      return Object.assign({}, state, { assignUserRoleLoading: false });
    }
    case ASSIGN_USER_ROLE_ERROR: {
      return Object.assign({}, state, { assignUserRoleLoading: false });
    }

    case FETCH_ASSIGN_USER_ROLE_REQUEST: {
      return Object.assign({}, state, { fetchAssignUserRoleLoading: true });
    }
    case FETCH_ASSIGN_USER_ROLE_SUCCESS: {
      return Object.assign({}, state, {
        fetchAssignUserRoleLoading: false,
        assignUserRole: action.body,
      });
    }
    case FETCH_ASSIGN_USER_ROLE_ERROR: {
      return Object.assign({}, state, { fetchAssignUserRoleLoading: false });
    }
    



    case FETCH_UNASSIGNED_USER_ROLE_REQUEST: {
      return Object.assign({}, state, { fetchUnAssignedUserRoleLoading: true });
    }
    case FETCH_UNASSIGNED_USER_ROLE_SUCCESS: {
      return Object.assign({}, state, {
        fetchUnAssignedUserRoleLoading: false,
        unAssignedUserRole: action.body,
      });
    }
    case FETCH_UNASSIGNED_USER_ROLE_ERROR: {
      return Object.assign({}, state, { fetchUnAssignedUserRoleLoading: false });
    }







    case EDIT_ASSIGN_USER_ROLE_REQUEST: {
      return Object.assign({}, state, { editAssignUserRoleLoading: true });
    }
    case EDIT_ASSIGN_USER_ROLE_SUCCESS: {
      return Object.assign({}, state, { editAssignUserRoleLoading: false });
    }
    case EDIT_ASSIGN_USER_ROLE_ERROR: {
      return Object.assign({}, state, { editAssignUserRoleLoading: false });
    }
    case DELETE_ASSIGN_USER_ROLE_REQUEST: {
      return Object.assign({}, state, { deleteAssignUserRoleLoading: true });
    }
    case DELETE_ASSIGN_USER_ROLE_SUCCESS: {
      return Object.assign({}, state, { deleteAssignUserRoleLoading: false });
    }
    case DELETE_ASSIGN_USER_ROLE_ERROR: {
      return Object.assign({}, state, { deleteAssignUserRoleLoading: false });
    }

    case CLEAR_ASSIGN_USER_TYPE_SEARCH: {
      return Object.assign({}, state, {
        searched_assign_user_role: [],
        search: "",
      });
    }

    case CLEAR_UNASSIGNED_USER_TYPE_SEARCH: {
      return Object.assign({}, state, {
        searched_unAssigned_user_role: [],
        search: "",
      });
    }

    default: {
      return state;
    }
  }
};

export default SettingsReducer;
