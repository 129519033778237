import {
  SEND_REPORT_REQUEST,
  SEND_REPORT_SUCCESS,
  SEND_REPORT_ERROR,
  GET_AR_ANALYSIS_REQUEST,
  GET_AR_ANALYSIS_SUCCESS,
  GET_AR_ANALYSIS_ERROR,
  GET_AGING_REPORT_REQUEST,
  GET_AGING_REPORT_ERROR,
  GET_AGING_REPORT_SUCCESS,
  CHART_OF_ACCOUNTS_REQUEST,
  CHART_OF_ACCOUNTS_SUCCESS,
  CHART_OF_ACCOUNTS_ERROR,
  AR_SEARCH_TOOL_REPORT_ERROR,
  AR_SEARCH_TOOL_REPORT_SUCCESS,
  AR_SEARCH_TOOL_REPORT_REQUEST,
  TRIAL_BALANCE_SUMMARY_REQUEST,
  TRIAL_BALANCE_SUMMARY_SUCCESS,
  TRIAL_BALANCE_SUMMARY_ERROR,
  CUSTOMER_BALANCE_SUMMARY_REQUEST,
  CUSTOMER_BALANCE_SUMMARY_SUCCESS,
  CUSTOMER_BALANCE_SUMMARY_ERROR,
  CUSTOMER_BALANCE_DETAIL_REQUEST,
  CUSTOMER_BALANCE_DETAIL_SUCCESS,
  CUSTOMER_BALANCE_DETAIL_ERROR,
  ACCOUNTS_RECEIVABLE_GRAPH_REQUEST,
  ACCOUNTS_RECEIVABLE_GRAPH_SUCCESS,
  ACCOUNTS_RECEIVABLE_GRAPH_ERROR,
  VENDORS_SUMMARY_REPORT_REQUEST,
  VENDORS_SUMMARY_REPORT_SUCCESS,
  VENDORS_SUMMARY_REPORT_ERROR,
  CREDIT_CARD_DETAIL_REPORT_REQUEST,
  CREDIT_CARD_DETAIL_REPORT_SUCCESS,
  CREDIT_CARD_DETAIL_REPORT_ERROR,
  LAWYERS_RANK_REPORT_REQUEST,
  LAWYERS_RANK_REPORT_SUCCESS,
  LAWYERS_RANK_REPORT_ERROR,
  JOB_RANK_REPORT_REQUEST,
  JOB_RANK_REPORT_SUCCESS,
  JOB_RANK_REPORT_ERROR,
  CUSTOMER_CREDIT_BALANCE_REPORT_REQUEST,
  CUSTOMER_CREDIT_BALANCE_REPORT_SUCCESS,
  CUSTOMER_CREDIT_BALANCE_REPORT_ERROR,
  AR_INCOME_METRICS_REQUEST,
  AR_INCOME_METRICS_SUCCESS,
  AR_INCOME_METRICS_ERROR,
  SUMMARY_REPORT_REQUEST,
  SUMMARY_REPORT_SUCCESS,
  SUMMARY_REPORT_ERROR,
} from "../configurations/Types";

const INITIAL_STATE = {
  sendReportLoading: false,
  getArAnalysisLoading: false,
  getArAnalysisData: [],
  getAgingReportLoading: false,
  chartOfAccountsLoading: false,
  chartOfAccountsData: {},
  arSearchToolsLoading: false,
  trialBasedSummaryIsLoading: false,
  customerBalanceSummaryLoading: false,
  customerBalanceDetailLoading: false,
  accountsReceivableGraphLoading: false,
  vendorsSummaryReportLoading: false,
  creditCardDetailReportLoading: false,
  lawyersRankReportLoading: false,
  jobRankReportLoading: false,
  customersCreditBalanceReportLoading: false,
  arIncomeMetricsLoading: false,
  summaryReportLoading: false,
  customerBalanceSummaryReport: {},
  customerBalanceDetailReport: {},
  accountsReceivableGraphReport: {},
  vendorsSummaryReport: {},
  creditCardDetailReport: {},
  lawyersRankReport: {},
  jobRankReport: {},
  customerCreditBalanceReport: {},
  arIncomeMetrics: {},
  summaryReport: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SEND_REPORT_REQUEST: {
      return Object.assign({}, state, { sendReportLoading: true });
    }
    case SEND_REPORT_SUCCESS: {
      return Object.assign({}, state, { sendReportLoading: false });
    }
    case SEND_REPORT_ERROR: {
      return Object.assign({}, state, { sendReportLoading: false });
    }

    case GET_AR_ANALYSIS_REQUEST: {
      return Object.assign({}, state, { getArAnalysisLoading: true });
    }
    case GET_AR_ANALYSIS_SUCCESS: {
      return Object.assign({}, state, {
        getArAnalysisLoading: false,
        getArAnalysisData: action.payload,
      });
    }
    case GET_AR_ANALYSIS_ERROR: {
      return Object.assign({}, state, { getArAnalysisLoading: false });
    }

    case GET_AGING_REPORT_REQUEST: {
      return Object.assign({}, state, { getAgingReportLoading: true });
    }
    case GET_AGING_REPORT_ERROR: {
      return Object.assign({}, state, { getAgingReportLoading: false });
    }
    case GET_AGING_REPORT_SUCCESS: {
      return Object.assign({}, state, {
        getAgingReportLoading: false,
        getArAnalysisData: action.payload,
      });
    }

    case CHART_OF_ACCOUNTS_REQUEST: {
      return Object.assign({}, state, { chartOfAccountsLoading: true });
    }
    case CHART_OF_ACCOUNTS_ERROR: {
      return Object.assign({}, state, { chartOfAccountsLoading: false });
    }
    case CHART_OF_ACCOUNTS_SUCCESS: {
      return Object.assign({}, state, {
        chartOfAccountsLoading: false,
        chartOfAccountsData: action.payload,
      });
    }
    case AR_SEARCH_TOOL_REPORT_REQUEST: {
      return Object.assign({}, state, { arSearchToolLoading: true });
    }
    case AR_SEARCH_TOOL_REPORT_SUCCESS: {
      return Object.assign({}, state, { arSearchToolLoading: false });
    }
    case AR_SEARCH_TOOL_REPORT_ERROR: {
      return Object.assign({}, state, { arSearchToolLoading: false });
    }
    case TRIAL_BALANCE_SUMMARY_REQUEST: {
      return Object.assign({}, state, { trialBasedSummaryIsLoading: true });
    }
    case TRIAL_BALANCE_SUMMARY_SUCCESS: {
      return Object.assign({}, state, { trialBasedSummaryIsLoading: false });
    }
    case TRIAL_BALANCE_SUMMARY_ERROR: {
      return Object.assign({}, state, { trialBasedSummaryIsLoading: false });
    }
    case CUSTOMER_BALANCE_SUMMARY_REQUEST: {
      return Object.assign({}, state, { customerBalanceSummaryLoading: true });
    }
    case CUSTOMER_BALANCE_SUMMARY_SUCCESS: {
      return Object.assign({}, state, {
        customerBalanceSummaryLoading: false,
        customerBalanceSummaryReport: action.payload,
      });
    }
    case CUSTOMER_BALANCE_SUMMARY_ERROR: {
      return Object.assign({}, state, { customerBalanceSummaryLoading: false });
    }

    case CUSTOMER_BALANCE_DETAIL_REQUEST: {
      return Object.assign({}, state, { customerBalanceDetailLoading: true });
    }
    case CUSTOMER_BALANCE_DETAIL_SUCCESS: {
      return Object.assign({}, state, {
        customerBalanceDetailLoading: false,
        customerBalanceDetailReport: action.payload,
      });
    }
    case CUSTOMER_BALANCE_DETAIL_ERROR: {
      return Object.assign({}, state, { customerBalanceDetailLoading: false });
    }

    case ACCOUNTS_RECEIVABLE_GRAPH_REQUEST: {
      return Object.assign({}, state, { accountsReceivableGraphLoading: true });
    }
    case ACCOUNTS_RECEIVABLE_GRAPH_SUCCESS: {
      return Object.assign({}, state, {
        accountsReceivableGraphLoading: false,
        accountsReceivableGraphReport: action.payload,
      });
    }
    case ACCOUNTS_RECEIVABLE_GRAPH_ERROR: {
      return Object.assign({}, state, {
        accountsReceivableGraphLoading: false,
      });
    }

    case VENDORS_SUMMARY_REPORT_REQUEST: {
      return Object.assign({}, state, { vendorsSummaryReportLoading: true });
    }
    case VENDORS_SUMMARY_REPORT_SUCCESS: {
      return Object.assign({}, state, {
        vendorsSummaryReportLoading: false,
        vendorsSummaryReport: action.payload,
      });
    }
    case VENDORS_SUMMARY_REPORT_ERROR: {
      return Object.assign({}, state, {
        vendorsSummaryReportLoading: false,
      });
    }

    case CREDIT_CARD_DETAIL_REPORT_REQUEST: {
      return Object.assign({}, state, {
        creditCardDetailReportLoading: true,
      });
    }
    case CREDIT_CARD_DETAIL_REPORT_SUCCESS: {
      return Object.assign({}, state, {
        creditCardDetailReportLoading: false,
        creditCardDetailReport: action.payload,
      });
    }
    case CREDIT_CARD_DETAIL_REPORT_ERROR: {
      return Object.assign({}, state, {
        creditCardDetailReportLoading: false,
      });
    }

    case LAWYERS_RANK_REPORT_REQUEST: {
      return Object.assign({}, state, {
        lawyersRankReportLoading: true,
      });
    }
    case LAWYERS_RANK_REPORT_SUCCESS: {
      return Object.assign({}, state, {
        lawyersRankReportLoading: false,
        lawyersRankReport: action.payload,
      });
    }
    case LAWYERS_RANK_REPORT_ERROR: {
      return Object.assign({}, state, {
        lawyersRankReportLoading: false,
      });
    }

    case JOB_RANK_REPORT_REQUEST: {
      return Object.assign({}, state, {
        jobRankReportLoading: true,
      });
    }
    case JOB_RANK_REPORT_SUCCESS: {
      return Object.assign({}, state, {
        jobRankReportLoading: false,
        jobRankReport: action.payload,
      });
    }
    case JOB_RANK_REPORT_ERROR: {
      return Object.assign({}, state, {
        jobRankReportLoading: false,
      });
    }

    case CUSTOMER_CREDIT_BALANCE_REPORT_REQUEST: {
      return Object.assign({}, state, {
        customerCreditBalanceReportLoading: true,
      });
    }
    case CUSTOMER_CREDIT_BALANCE_REPORT_SUCCESS: {
      return Object.assign({}, state, {
        customerCreditBalanceReportLoading: false,
        customerCreditBalanceReport: action.payload,
      });
    }
    case CUSTOMER_CREDIT_BALANCE_REPORT_ERROR: {
      return Object.assign({}, state, {
        customerCreditBalanceReportLoading: false,
      });
    }

    case AR_INCOME_METRICS_REQUEST: {
      return Object.assign({}, state, {
        arIncomeMetricsLoading: true,
      });
    }
    case AR_INCOME_METRICS_SUCCESS: {
      return Object.assign({}, state, {
        arIncomeMetricsLoading: false,
        arIncomeMetrics: action.payload,
      });
    }
    case AR_INCOME_METRICS_ERROR: {
      return Object.assign({}, state, {
        arIncomeMetricsLoading: false,
      });
    }

    case SUMMARY_REPORT_REQUEST: {
      return Object.assign({}, state, {
        summaryReportLoading: true,
      });
    }
    case SUMMARY_REPORT_SUCCESS: {
      return Object.assign({}, state, {
        summaryReportLoading: false,
        summaryReport: action.payload,
      });
    }
    case SUMMARY_REPORT_ERROR: {
      return Object.assign({}, state, {
        summaryReportLoading: false,
      });
    }

    default: {
      return state;
    }
  }
};
