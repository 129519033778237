import { put, takeLatest, cancelled } from "redux-saga/effects";
import {
  privateAgent,
  CancelToken,
  publicAgent,
} from "configurations/AxiosAgent";
import moment from "moment";
import {
  StripeAccountSuc,
  getAllAccountsSuccess,
  getAllAccountsReq,
  StripeAccountErr,
  checkTokenSuccess,
  checkTokenError,
  checkTokenReq,
  CheckAccountSuc,
  CheckAccountErr,
} from "../actions/Stripe";
import {
  STRIPE_ACCOUNT_REQUEST,
  GET_ALL_ACCOUNT_REQ,
  DELETE_ACCOUNT_REQUEST,
  ACTIVE_ACCOUNT_REQUEST,
  CREATE_PAYMENT_REQUEST,
  RECORD_INVOICE_REQ,
  CHECK_TOKEN_REQ,
  CHECK_ACCOUNT_REQ
} from "configurations/Types";
import {
  CONNECT_ACCOUNT,
  STRIPE,
  GET_ALL_ACCOUNT,
  GET_LINKED_ACCOUNT,
  DELETE_ACCOUNT,
  ACTIVE_ACCOUNT,
  CREATE_PAYMENT,
  RECORD_INVOICE_PAYMENT,
  CHECK_TOKEN_ENDPOINT,
  ALL_SUBSCRIPTION_ENDPOINT,
  CHECK_ACCOUNT_ENDPOINT
} from "configurations/Constants";
import info from "components/message";
const connectStripeApi = (body) => {
  return privateAgent.post(`${STRIPE}${CONNECT_ACCOUNT}`, body);
};

const getAllAccountApi = () => {
  return privateAgent.get(`${STRIPE}${GET_ALL_ACCOUNT}`);
};

const getLinkedAccount = (id) => {
  return privateAgent.get(
    `${STRIPE}${GET_LINKED_ACCOUNT}?connect_account_id=${id}`
  );
};

const deleteAccountApi = (id) => {
  return privateAgent.delete(`${STRIPE}${DELETE_ACCOUNT}/${id}`);
};

const recordInvoiceApi = (body, id) => {
  return privateAgent.put(`${STRIPE}${RECORD_INVOICE_PAYMENT}/${id}`, body);
};
const activeAccountApi = (id) => {
  const currentDate = new Date();
  const gcurrent_time = moment(currentDate).format("YYYY-MM-DD HH:mm:ss");
  return privateAgent.put(`${STRIPE}${ACTIVE_ACCOUNT}/${id}`, {
    is_active: "true",
    current_time: gcurrent_time,
  });
};
const createPaymentApi = (body) => {
  return publicAgent.post(`${STRIPE}${CREATE_PAYMENT}`, body);
};
const checkTokenApi = (params) => {
  return publicAgent.get(`${STRIPE}/${CHECK_TOKEN_ENDPOINT}?${params}`);
};
const checkAccountApi = (body) => {
  console.log("body is: ", body)
  return privateAgent.post(`${STRIPE}${CHECK_ACCOUNT_ENDPOINT}`, {
    connect_account_id: body
  });
};
function* checkAccount(action) {
  let res;
  console.log("ACTION IS: ", action);
  try {
    res = yield checkAccountApi(action.payload);
    console.log("res===>", res);
    // info("success", `Purchase successful`);
    // //yield put(setSubscriptionStatus(res.data.data));
    console.log("GOT SUBSCRIPTION STATUS, NOW GOING TO CALL SUCCESS ACTION");
    yield put(CheckAccountSuc());
    //localStorage.removeItem("AccountID");
    if (action.callback) {
      // action.callback();
    }
  } catch (e) {
    if (e.response) {
      // info("error", `${e.response.data.error.message}`);
     
    }
    yield put(CheckAccountErr())
    // yield put(buySubscriptionError());
  }
}
function* connectStripe(action) {
  const { payload, cb } = action;
  console.log("PAYLOAD", cb);
  let formData = new FormData();

  for (const key in payload) {
    console.log(`Form Data: ${key} = ${payload[key]}`);
    formData.append(key, payload[key]);
  }
  console.log("RESS: ");
  try {
    let res = yield connectStripeApi(formData);
    // console.log("RESS: ", res);
    // info("success", `Purchase successful`);
    localStorage.setItem("AccountID", res.data.data.account_id);
    // //yield put(setSubscriptionStatus(res.data.data));    console.log("GOT SUBSCRIPTION STATUS, NOW GOING TO CALL SUCCESS ACTION");
    yield put(StripeAccountSuc(res.data.data));
    let resNew = yield getLinkedAccount(res.data.data.account_id);
    if (resNew) {
      // console.log('resNew==>',resNew)
      window.open(resNew?.data?.data?.url, "_blank");
    }
    if (cb) {
      // action.callback();
      cb(res.data.data);
    }
  } catch (e) {
    console.log("ERRROR : ", e);
    // info("error", `${res.error.message}`);
    if (e.response) {
      info("error", `${e.response.data.error.message}`);
    }
    yield put(StripeAccountErr());
  }
}

function* getAllAccount(action) {
  console.log("res Act", action);
  let res;
  try {
    let res = yield getAllAccountApi();
    console.log("res===>", res);
    // info("success", `Purchase successful`);
    // //yield put(setSubscriptionStatus(res.data.data));    console.log("GOT SUBSCRIPTION STATUS, NOW GOING TO CALL SUCCESS ACTION");
    yield put(getAllAccountsSuccess(res.data.data.accounts));

    if (action.callback) {
      action.callback(null, res.data.data.accounts);
    }
  } catch (e) {
    if (e.response) {
      // info("error", `${e.response.data.error.message}`);
    }
    // yield put(buySubscriptionError());
  }
}

function* deleteAccount(action) {
  let res;
  try {
    res = yield deleteAccountApi(action.id);
    console.log("res===>", res);
    // info("success", `Purchase successful`);
    // //yield put(setSubscriptionStatus(res.data.data));
    console.log("GOT SUBSCRIPTION STATUS, NOW GOING TO CALL SUCCESS ACTION");
    yield put(getAllAccountsReq());

    if (action.callback) {
      // action.callback();
    }
  } catch (e) {
    if (e.response) {
      // info("error", `${e.response.data.error.message}`);
    }
    // yield put(buySubscriptionError());
  }
}

function* activeAccount(action) {
  console.log("activeAccount===>", action);
  let res;
  try {
    res = yield activeAccountApi(action.id);
    // info("success", `Purchase successful`);
    yield put(getAllAccountsReq());

    // //yield put(setSubscriptionStatus(res.data.data));
    console.log("GOT SUBSCRIPTION STATUS, NOW GOING TO CALL SUCCESS ACTION");

    if (action.callback) {
      // action.callback();
    }
  } catch (e) {
    if (e.response) {
      // info("error", `${e.response.data.error.message}`);
    }
    // yield put(buySubscriptionError());
  }
}

function* createPayment(action) {
  try {
    let res = yield createPaymentApi(action.payload);
    console.log("Payment created successfully", res);
  } catch (e) {
    console.log("error", e);
  }
}

function* recordInvoice(action) {
  const obj = { ...action };
  delete obj.id;
  delete obj.type;
  delete obj.invoice_id;
  const formData = new FormData();
  for (const key in obj) {
    formData.append(key, obj[key]);
  }
  formData.append(
    "current_time",
    moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
  );

  let res;
  try {
    res = yield recordInvoiceApi(formData, action.invoice_id);
    console.log("recordInvoiceApi", res);
    // info("success", `Purchase successful`);
    // //yield put(setSubscriptionStatus(res.data.data));
    if (action.cb) {
      action.cb();
    }
  } catch (e) {
    if (e.response) {
      // info("error", `${e.response.data.error.message}`);
    }
    // yield put(buySubscriptionError());
  }
}
function* checkTokenMiddleware(action) {
  const params = new URLSearchParams(action.params);
  let res;
  try {
    res = yield checkTokenApi(params);
    // info("success", `Purchase successful`);
    // yield put(getAllAccountsReq());
    yield put(checkTokenSuccess());

    // //yield put(setSubscriptionStatus(res.data.data));
    console.log("GOT SUBSCRIPTION STATUS, NOW GOING TO CALL SUCCESS ACTION");

    if (action.callback) {
      // action.callback();
    }
  } catch (e) {
    console.log("error", e);
    yield put(checkTokenError());
    if (e.response) {
      // info("error", `${e.response.data.error.message}`);
    }
    // yield put(buySubscriptionError());
  }
}

export function* watchStripeSagas() {
  yield takeLatest(STRIPE_ACCOUNT_REQUEST, connectStripe);
  yield takeLatest(GET_ALL_ACCOUNT_REQ, getAllAccount);
  yield takeLatest(DELETE_ACCOUNT_REQUEST, deleteAccount);
  yield takeLatest(ACTIVE_ACCOUNT_REQUEST, activeAccount);
  yield takeLatest(CREATE_PAYMENT_REQUEST, createPayment);
  yield takeLatest(RECORD_INVOICE_REQ, recordInvoice);
  yield takeLatest(CHECK_TOKEN_REQ, checkTokenMiddleware);
  yield takeLatest(CHECK_ACCOUNT_REQ, checkAccount);
}
