import { put, takeLatest, cancelled } from "redux-saga/effects";
import { privateAgent, CancelToken } from "configurations/AxiosAgent";
import {
  //ADD REQUESTS
  ADD_LAWYER_REQUEST,
  ADD_CUSTOMER_REQUEST,
  ADD_JOB_REQUEST,
  ADD_TIME_ACCOUNT_REQUEST,
  ADD_FIXED_COST_ACCOUNT_REQUEST,
  ADD_EXPENSE_ACCOUNT_REQUEST,
  ADD_VENDOR_REQUEST,
  ADD_CREDIT_CARD_REQUEST,

  //FETCH REQUESTS
  GET_DASHBOARD_REQUEST,
  FETCH_LAWYERS_REQUEST,
  FETCH_CUSTOMERS_REQUEST,
  FETCH_JOBS_REQUEST,
  FETCH_ALL_ACCOUNTS_REQUEST,
  FETCH_TIME_ACCOUNTS_REQUEST,
  FETCH_FIXED_COST_ACCOUNTS_REQUEST,
  FETCH_EXPENSE_ACCOUNTS_REQUEST,
  FETCH_VENDORS_REQUEST,
  FETCH_CREDIT_CARDS_REQUEST,

  // UPDATE REQUESTS
  UPDATE_LAWYER_REQUEST,
  UPDATE_CUSTOMER_REQUEST,
  UPDATE_JOB_REQUEST,
  UPDATE_TIME_ACCOUNT_REQUEST,
  UPDATE_FIXED_COST_ACCOUNT_REQUEST,
  UPDATE_EXPENSE_ACCOUNT_REQUEST,
  UPDATE_VENDOR_REQUEST,
  UPDATE_CREDIT_CARD_REQUEST,

  // Details
  LAWYER_DETAILS_REQUEST,
  CUSTOMER_DETAILS_REQUEST,

  // Assign
  LAWYER_ASSIGN_REQUEST,
  CUSTOMER_ASSIGN_REQUEST,

  // delete
  DEL_TIME_ACCOUNT_REQUEST,
  DEL_EXPENSE_ACCOUNT_REQUEST,
  DEL_FIXED_COST_ACCOUNT_REQUEST
} from "configurations/Types";
import info from "components/message/index";
import {
  USERS_URL,
  CONTACTS,
  TRANSACTIONS,
  CHARTS,
  FETCH_ALL_ACCOUNTS,
  //ADD ENDPOINTS
  ADD_LAWYER_ENDPOINT,
  ADD_CUSTOMER_ENDPOINT,
  ADD_JOB_ENDPOINT,
  ADD_TIME_ACCOUNT_ENDPOINT,
  ADD_EXPENSE_ACCOUNT_ENDPOINT,
  ADD_VENDOR_ENDPOINT,
  ADD_CREDIT_CARD_ENDPOINT,
  //FETCH ENDPOINTS
  DASHBOARD_ENDPOINT,
  FETCH_LAWYERS_ENDPOINT,
  FETCH_CUSTOMERS_ENDPOINT,
  FETCH_JOBS_ENDPOINT,
  FETCH_ACCOUNTS_ENDPOINT,
  FETCH_VENDORS_ENDPOINT,
  FETCH_CREDIT_CARDS_ENDPOINT,
  // UPDATE ENDPOINTS
  UPDATE_LAWYER_ENDPOINT,
  UPDATE_TRANSACTION_ENDPOINT,
  UPDATE_CREDIT_CARD_ENDPOINT,
  UPDATE_VENDOR_ENDPOINT,
  UPDATE_JOB_ENDPOINT,
  UPDATE_CUSTOMER_ENDPOINT,
  // get details ENDPOINTS
  GET_LAWYER_DETAILS_ENDPOINT,
  GET_CUSTOMERS_DETAILS_ENDPOINT,
  // Assign Endpoints
  ASSIGN_LAWYER_ENDPOINT,
  ASSIGN_CUSTOMER_ENDPOINT,
  // Delete Endpoints
  DELETE_ACCOUNT_ENDPOINT,
} from "configurations/Constants";
import {
  //add actions
  addLawyerError,
  addLawyerSuccess,
  addCustomerError,
  addCustomerSuccess,
  addJobSuccess,
  addJobError,
  addTimeAccountSuccess,
  addTimeAccountError,
  addFixedCostAccountSuccess,
  addFixedCostAccountError,
  addExpenseAccountError,
  addExpenseAccountSuccess,
  addVendorError,
  addVendorSuccess,
  addCreditCardSuccess,
  addCreditCardError,

  //fetch actions
  getDashboardSuccess,
  getDashboardError,
  fetchLawyersError,
  fetchLawyersSuccess,
  fetchCustomersRequest,
  fetchCustomersError,
  fetchCustomersSuccess,
  fetchJobsSuccess,
  fetchJobsError,
  fetchAllAccountsError,
  fetchAllAccountsSuccess,
  fetchTimeAccountsError,
  fetchFixedCostAccountsError,
  fetchTimeAccountsSuccess,
  fetchFixedCostAccountsSuccess,
  fetchExpenseAccountsSuccess,
  fetchExpenseAccountsError,
  fetchVendorsSuccess,
  fetchVendorsError,
  fetchCreditCardsSuccess,
  fetchCreditCardsError,

  // update actions
  updateLawyerSuccess,
  updateLawyerError,
  updateTimeAccSuccess,
  updateTimeAccError,
  updateFixedCostAccountSuccess,
  updateFixedCostAccountError,
  updateExpenseAccSuccess,
  updateExpenseAccError,
  updateCreditCardSuccess,
  updateCreditCardError,
  updateVendorSuccess,
  updateVendorError,
  updateJobSuccess,
  updateJobError,
  updateCustomerSuccess,
  updateCustomerError,

  // get details
  getLawyerDetailsSuccess,
  getLawyerDetailsError,
  getCustomerDetailsSuccess,
  getCustomerDetailsError,

  // assign
  assignLawyerSuccess,
  assignLawyerError,
  assignCustomerError,
  assignCustomerSuccess,

  // delete
  delTimeAccSuccess,
  delTimeAccError,
  delExpenseAccSuccess,
  delExpenseAccError,
  fetchAllAccountsRequest,
  deleteFixedCostAccountSuccess,
  deleteFixedCostAccountError,
} from "actions/Dashboard";

import moment from "moment";
/*------------------------------------------------------FETCH API`s--------------------------------------------------------------------*/
let token;
let current_time = moment().format("YYYY-MM-DD HH:mm:ss");
const getDashboardApi = () =>
  privateAgent.get(`${USERS_URL}${DASHBOARD_ENDPOINT}`);

const fetchLawyersApi = (params, token) => {
  return privateAgent.get(`${CONTACTS}${FETCH_LAWYERS_ENDPOINT}`, {
    cancelToken: token.token,
    params: {
      limit: 1000,
      offset: 0,
      ...params

    },
  });
};
const fetchCustomersApi = (params, token) => {
  return privateAgent.get(`${CONTACTS}${FETCH_CUSTOMERS_ENDPOINT}`, {
    cancelToken: token.token,
    params: {
      limit: 1000,
      offset: 0,
      lawyer_id: params
    },
  });
};
const fetchJobsApi = (params, token) => {
  return privateAgent.get(`${TRANSACTIONS}${FETCH_JOBS_ENDPOINT}`, {
    cancelToken: token.token,
    params: {
      limit: 1000,
      offset: 0,
      customer_id: params
    },
  });
};
// const fetchAllAccountsApi=(token)=>{
//   return privateAgent.get(`${CHARTS}${FETCH_ALL_ACCOUNTS}`,{
//     cancelToken: token.token
//   })
// }

const fetchAllAccountsApi=(token)=>{
  return privateAgent.get(`${CHARTS}${FETCH_ALL_ACCOUNTS}`,{
    cancelToken: token.token
  })
}
const fetchAccountsApi = (params, token) => {
  return privateAgent.get(`${TRANSACTIONS}${FETCH_ACCOUNTS_ENDPOINT}`, {
    cancelToken: token.token,
    params: params || {
      limit: 1000,
      offset: 0
    },
  });
};

const fetchVendorsApi = (params, token) => {
  return privateAgent.get(`${CONTACTS}${FETCH_VENDORS_ENDPOINT}`, {
    cancelToken: token.token,
    params: params || {
      limit: 1000,
      offset: 0,
    },
  });
};

const fetchCreditCardsApi = (params, token) => {
  return privateAgent.get(`${TRANSACTIONS}${FETCH_CREDIT_CARDS_ENDPOINT}`, {
    cancelToken: token.token,
    params: params || {
      limit: 1000,
      offset: 0,
    },
  });
};

const fetchTimeTransationsApi = (params, token) => {
  return privateAgent.get(`${TRANSACTIONS}${FETCH_ACCOUNTS_ENDPOINT}`, {
    cancelToken: token.token,
    params: params || {
      limit: 1000,
      offset: 0,
    },
  });
};

const fetchExpenseTransationsApi = (params, token) => {
  return privateAgent.get(`${TRANSACTIONS}${FETCH_ACCOUNTS_ENDPOINT}`, {
    cancelToken: token.token,
    params: params || {
      limit: 1000,
      offset: 0,
    },
  });
};

/*------------------------------------------------------ADD API`s--------------------------------------------------------------------*/

//ADD LAWYER API
const addLawyerApi = (body) => {
  return privateAgent.post(`${CONTACTS}${ADD_LAWYER_ENDPOINT}`, {
    ...body,
    current_time,
  });
};

//ADD CUSTOMER API
const addCustomerApi = (body) => {
  return privateAgent.post(`${CONTACTS}${ADD_CUSTOMER_ENDPOINT}`, {
    ...body,
    current_time,
  });
};
//ADD JOB API
const addJobApi = (body) => {
  return privateAgent.post(`${TRANSACTIONS}${ADD_JOB_ENDPOINT}`, {
    ...body,
    current_time,
  });
};

//ADD TIME ACCOUNT API

const addTimeAccountApi = (body) => {
  return privateAgent.post(`${TRANSACTIONS}${ADD_TIME_ACCOUNT_ENDPOINT}`, {
    ...body,
    account_type: "time",
    current_time,
  });
};

//ADD FIXED COST ACCOUNT API

const addFixedCostAccountApi = (body) => {
  return privateAgent.post(`${TRANSACTIONS}${ADD_TIME_ACCOUNT_ENDPOINT}`, {
    ...body,
    account_type: "fixed cost",
    current_time,
  });
};

//ADD EXPENSE ACCOUNT API
const addExpenseAccountApi = (body) => {
  return privateAgent.post(`${TRANSACTIONS}${ADD_EXPENSE_ACCOUNT_ENDPOINT}`, {
    ...body,
    account_type: "expense",
    current_time,
  });
};

//ADD VENDOR API
const addVendorApi = (body) => {
  return privateAgent.post(`${CONTACTS}${ADD_VENDOR_ENDPOINT}`, {
    ...body,
    current_time,
  });
};

//ADD CREDIT CARD API
const addCreditCardApi = (body) => {
  return privateAgent.post(`${TRANSACTIONS}${ADD_CREDIT_CARD_ENDPOINT}`, {
    ...body,
    current_time,
  });
};

/*------------------------------------------------------ADD API REQUESTS`s--------------------------------------------------------------------*/

//ADD LAWYER
export function* addLawyer(action) {
  let res;
  try {
    res = yield addLawyerApi(action.body);
    info("success", res?.data.data.message);
    yield put(addLawyerSuccess());
    // yield put(fetchLawyersRequest());
    action.cb && action.cb();
  } catch (e) {
    if (e.response?.data?.error?.message) {
      info("error", e.response?.data?.error?.message);
    }
    yield put(addLawyerError(e.response));
  } finally {
    if (yield cancelled()) {
    }
  }
}

//ADD CUSTOMER

export function* addCustomer(action) {
  let res;
  try {
    res = yield addCustomerApi(action.body);
    info("success", res?.data.data.message);
    yield put(addCustomerSuccess());
    yield put(fetchCustomersRequest());
    if (action.cb) action.cb(res?.data.data);
  } catch (e) {
    if (e.response?.data?.error?.message) {
      info("error", e.response?.data?.error?.message);
    }
    yield put(addCustomerError(e.response));
  } finally {
    if (yield cancelled()) {
    }
  }
}
export function* watchAddCustomerActions() {
  yield takeLatest(ADD_CUSTOMER_REQUEST, addCustomer);
}

//ADD JOB
export function* addJob(action) {
  let res;
  try {
    res = yield addJobApi(action.body);
    info("success", res?.data.data.message);
    yield put(addJobSuccess());
    console.log("JOB DATA ; ", res);
    action.cb && action.cb(res?.data.data);
  } catch (e) {
    if (e.response?.data?.error?.message) {
      info("error", e.response?.data?.error?.message);
    }
    yield put(addJobError(e.response));
  } finally {
    if (yield cancelled()) {
    }
  }
}

//ADD TIME ACCOUNT
function* addTimeAccount(action) {
  let res;
  try {
    res = yield addTimeAccountApi(action.body);
    info("success", res?.data.data.message);
    yield put(addTimeAccountSuccess());
    action.cb && action.cb();
  } catch (e) {
    if (e.response?.data?.error?.message) {
      info("error", e.response?.data?.error?.message);
    }
    yield put(addTimeAccountError(e.response));
  } finally {
    if (yield cancelled()) {
    }
  }
}

//ADD FIXED COST ACCOUNT
function* addFixedCostAccount(action) {
  let res;
  try {
    res = yield addFixedCostAccountApi(action.body);
    info("success", res?.data.data.message);
    yield put(addFixedCostAccountSuccess());
    action.cb && action.cb();
  } catch (e) {
    if (e.response?.data?.error?.message) {
      info("error", e.response?.data?.error?.message);
    }
    yield put(addFixedCostAccountError(e.response));
  } finally {
    if (yield cancelled()) {
    }
  }
}


//ADD EXPENSE ACCOUNT

function* addExpenseAccount(action) {
  let res;
  try {
    res = yield addExpenseAccountApi(action.body);
    info("success", res?.data.data.message);
    yield put(addExpenseAccountSuccess());

    action.cb && action.cb();
  } catch (e) {
    if (e.response?.data?.error?.message) {
      info("error", e.response?.data?.error?.message);
    }
    yield put(addExpenseAccountError(e.response));
  } finally {
    if (yield cancelled()) {
    }
  }
}

//ADD VENDOR

function* addVendor(action) {
  let res;
  try {
    res = yield addVendorApi(action.body);
    info("success", res?.data.data.message);
    yield put(addVendorSuccess());
    action.cb && action.cb();
  } catch (e) {
    if (e.response?.data?.error?.message) {
      info("error", e.response?.data?.error?.message);
    }
    yield put(addVendorError(e.response));
  } finally {
    if (yield cancelled()) {
    }
  }
}

//ADD CREDIT CARD

function* addCreditCard(action) {
  let res;
  try {
    res = yield addCreditCardApi(action.body);
    info("success", res?.data.data.message);
    yield put(addCreditCardSuccess());
    action.cb && action.cb();
  } catch (e) {
    if (e.response?.data?.error?.message) {
      info("error", e.response?.data?.error?.message);
    }
    yield put(addCreditCardError(e.response));
  } finally {
    if (yield cancelled()) {
    }
  }
}

/*------------------------------------------------------FETCH API REQUESTS`s--------------------------------------------------------------------*/

function* getDashboard() {
  let res;
  try {
    res = yield getDashboardApi();
    yield put(getDashboardSuccess(res.data.data.dashboard));
  } catch (e) {
    if (e.response?.data?.error?.message) {
      info("error", e.response?.data?.error?.message);
    }
    console.error("Error: ", e);
    yield put(getDashboardError(e.response));
  }
}
//FETCH LAWYERS
function* fetchLawyers(action) {
  let res;
  try {
    if (token) {
      token.cancel();
    }
    token = CancelToken.source();
    res = yield fetchLawyersApi(action.params, token);
    yield put(fetchLawyersSuccess(res.data.data.lawyers));
    action.cb && action.cb(null, res.data.data.lawyers);

  } catch (e) {
    if (e.response?.data?.error?.message) {
      info("error", e.response?.data?.error?.message);
    }
    yield put(fetchLawyersError(e.response));
  } finally {
    if (yield cancelled()) {
    }
  }
}
//FETCH CUSTOMERS
function* fetchCustomers(action) {
  console.log('fetchCustomers', action)
  let res;
  try {
    if (token) {
      token.cancel();
    }
    token = CancelToken.source();
    res = yield fetchCustomersApi(action.params, token);
    yield put(fetchCustomersSuccess(res.data.data.customers));
    console.log("fetchCustomersSuccess", res)
    action.cb && action.cb(null, res.data.data.customers)
  } catch (e) {
    if (e.response?.data?.error?.message) {
      info("error", e.response?.data?.error?.message);
    }
    yield put(fetchCustomersError(e.response));
  } finally {
    if (yield cancelled()) {
    }
  }
}

//FETCH JOBS
function* fetchJobs(action) {
  let res;
  try {
    if (token) {
      token.cancel();
    }
    token = CancelToken.source(null, token);
    res = yield fetchJobsApi(action.params, token);
    console.log('JOBS SAGA: ', res.data.data.jobs)
    yield put(fetchJobsSuccess(res.data.data.jobs));
    // console.log(res, "action.cb && action.cb(res.data.data.jobs)")
    action.cb && action.cb(null, res.data.data.jobs)
  } catch (e) {
    if (e.response?.data?.error?.message) {
      info("error", e.response?.data?.error?.message);
    }
    yield put(fetchJobsError(e.response));
  } finally {
    if (yield cancelled()) {
    }
  }
}

// export function* watchFetchJobsActions() {
//   yield takeLatest(FETCH_JOBS_REQUEST, fetchJobs);
// }

//FETCH All ACCOUNTS
function* fetchAllAccounts(action) {
  const {cb} = action;
  let res;
  try {
    if (token) {
      token.cancel();
    }
    token = CancelToken.source();
    res = yield fetchAccountsApi(null,token);
    // set res data for both account types
    console.log("fetch acc: ", res.data.data);
    const { accounts } = res.data.data
    console.log("ACCCCOUNNTTSSSSSS",accounts)
    cb && cb(res.data.data);
    yield put(fetchAllAccountsSuccess(accounts))
    // action.cb && action.cb(null, tyexp_accounts)
  } catch (e) {
    if (e.response?.data?.error?.message) {
      info("error", e.response?.data?.error?.message);
    }
    yield put(fetchAllAccountsError(e.response))
  } finally {
    if (yield cancelled()) {
    }
  }
}




//FETCH TIME ACCOUNTS
function* fetchAccounts(action) {
  let res;
  try {
    if (token) {
      token.cancel();
    }
    token = CancelToken.source();
    res = yield fetchAccountsApi(null, token);
    // set res data for both account types
    console.log("fetch acc: ", res.data.data);
    const { accounts } = res.data.data;
    const time_accounts = accounts.filter(
      (predicate) => predicate.account_type === "time"
    );
    const exp_accounts = accounts.filter(
      (predicate) => predicate.account_type === "expense"
    );
    const fixed_cost_accounts = accounts.filter(
      (predicate) => predicate.account_type === "fixed cost"
    );
    yield put(fetchTimeAccountsSuccess(time_accounts));
    action.cb && action.cb(null, action.accountType === 'time' ? time_accounts : action.accountType === 'fixed cost' ? fixed_cost_accounts : exp_accounts)
    yield put(fetchExpenseAccountsSuccess(exp_accounts));
    yield put(fetchFixedCostAccountsSuccess(fixed_cost_accounts));
    // action.cb && action.cb(null, tyexp_accounts)
  } catch (e) {
    if (e.response?.data?.error?.message) {
      info("error", e.response?.data?.error?.message);
    }
    yield put(fetchTimeAccountsError(e.response));
    yield put(fetchExpenseAccountsError(e.response));
    yield put(fetchFixedCostAccountsError(e.response));
  } finally {
    if (yield cancelled()) {
    }
  }
}



//FETCH VENDORS
function* fetchVendors(action) {
  let res;
  try {
    if (token) {
      token.cancel();
    }
    token = CancelToken.source();
    res = yield fetchVendorsApi(null, token);
    action.cb && action.cb(null, res.data.data.vendors)
    yield put(fetchVendorsSuccess(res.data.data.vendors));
  } catch (e) {
    if (e.response?.data?.error?.message) {
      info("error", e.response?.data?.error?.message);
    }
    yield put(fetchVendorsError(e.response));
  } finally {
    if (yield cancelled()) {
    }
  }
}

//FETCH CREDIT CARDS
function* fetchCreditCards(action) {
  let res;
  try {
    if (token) {
      token.cancel();
    }
    token = CancelToken.source();
    res = yield fetchCreditCardsApi(null, token);
    action.cb && action.cb(null, res.data.data.credit_cards)
    yield put(fetchCreditCardsSuccess(res.data.data.credit_cards));
  } catch (e) {
    if (e.response?.data?.error?.message) {
      info("error", e.response?.data?.error?.message);
    }
    yield put(fetchCreditCardsError(e.response));
  } finally {
    if (yield cancelled()) {
    }
  }
}

/* ---------------------------------- Update Lawyer ------------------------------------ */
const updateLawyerApi = (id, body) => {
  return privateAgent.put(`${CONTACTS}${UPDATE_LAWYER_ENDPOINT}/${id}`, {
    ...body,
    current_time,
  });
};
function* updateLawyer(action) {
  let res;
  const { lawyer_id, ...rest } = action.body;
  try {
    res = yield updateLawyerApi(lawyer_id, rest);
    info("success", res.data.data.message);
    console.log("update lawyer succes", res.data.data);
    yield put(updateLawyerSuccess());
    if (action.cb) action.cb();
  } catch (e) {
    if (e.response?.data?.error?.message) {
      info("error", e.response?.data?.error?.message);
    }
    yield put(updateLawyerError());
  } finally {
    if (yield cancelled()) {
    }
  }
}

/* ---------------------------------- Update Credit Card ------------------------------------ */
const updateCreditCardApi = (id, body) => {
  let current_time = moment().format("YYYY-MM-DD HH:mm:ss");
  return privateAgent.put(
    `${TRANSACTIONS}${UPDATE_CREDIT_CARD_ENDPOINT}/${id}`,
    {
      ...body,
      current_time,
    }
  );
};
function* updateCreditCard(action) {
  let res;
  const { credit_card_id, ...rest } = action.body;
  try {
    res = yield updateCreditCardApi(credit_card_id, rest);
    info("success", res.data.data.message);
    yield put(updateCreditCardSuccess());
    if (action.cb) action.cb();
  } catch (e) {
    if (e.response?.data?.error?.message) {
      info("error", e.response?.data?.error?.message);
    }
    yield put(updateCreditCardError());
  } finally {
    if (yield cancelled()) {
    }
  }
}

/* ---------------------------------- Update Vendor ------------------------------------ */
const updateVendorApi = (id, body) => {
  let current_time = moment().format("YYYY-MM-DD HH:mm:ss");
  return privateAgent.put(`${CONTACTS}${UPDATE_VENDOR_ENDPOINT}/${id}`, {
    ...body,
    current_time,
  });
};
function* updateVendor(action) {
  let res;
  const { vendor_id, ...rest } = action.body;
  try {
    res = yield updateVendorApi(vendor_id, rest);
    info("success", res.data.data.message);
    yield put(updateVendorSuccess());
    if (action.cb) action.cb();
  } catch (e) {
    if (e.response?.data?.error?.message) {
      info("error", e.response?.data?.error?.message);
    }
    yield put(updateVendorError());
  } finally {
    if (yield cancelled()) {
    }
  }
}

/* ---------------------------------- Update Job ------------------------------------ */
const updateJobApi = (id, body) => {
  let current_time = moment().format("YYYY-MM-DD HH:mm:ss");
  return privateAgent.put(`${TRANSACTIONS}${UPDATE_JOB_ENDPOINT}/${id}`, {
    ...body,
    current_time,
  });
};
function* updateJob(action) {
  let res;
  const { job_id, ...rest } = action.body;
  try {
    res = yield updateJobApi(job_id, rest);
    info("success", res.data.data.message);
    console.log("update JOB succes", res.data.data);
    yield put(updateJobSuccess());
    if (action.cb) action.cb();
  } catch (e) {
    if (e.response?.data?.error?.message) {
      info("error", e.response?.data?.error?.message);
    }
    yield put(updateJobError());
  } finally {
    if (yield cancelled()) {
    }
  }
}

/* ---------------------------------- Update Customer ------------------------------------ */
const updateCustomerApi = (id, body) => {
  let current_time = moment().format("YYYY-MM-DD HH:mm:ss");
  return privateAgent.put(`${CONTACTS}${UPDATE_CUSTOMER_ENDPOINT}/${id}`, {
    ...body,
    current_time,
  });
};
function* updateCustomer(action) {
  let res;
  const { customer_id, ...rest } = action.body;
  try {
    res = yield updateCustomerApi(customer_id, rest);
    info("success", res.data.data.message);
    console.log("update JOB succes", res.data.data);
    yield put(updateCustomerSuccess());
    if (action.cb) action.cb();
  } catch (e) {
    if (e.response?.data?.error?.message) {
      info("error", e.response?.data?.error?.message);
    }
    yield put(updateCustomerError());
  } finally {
    if (yield cancelled()) {
    }
  }
}

const updateAccountApi = (id, body) => {
  return privateAgent.put(
    `${TRANSACTIONS}${UPDATE_TRANSACTION_ENDPOINT}/${id}`,
    {
      ...body,
      current_time,
    }
  );
};
function* updateTransaction(action) {
  let res;
  const { id, amount, ...rest } = action.body;
  try {
    res = yield updateAccountApi(id, rest);
    info("success", res.data.data.message);
    yield put(updateTimeAccSuccess());
    yield put(updateExpenseAccSuccess());
    yield put(updateFixedCostAccountSuccess());
    if (action.cb) action.cb();
  } catch (e) {
    if (e.response?.data?.error?.message) {
      info("error", e.response?.data?.error?.message);
    }
    yield put(updateTimeAccError());
    yield put(updateExpenseAccError());
    yield put(updateFixedCostAccountError())
  } finally {
    if (yield cancelled()) {
    }
  }
}

// --------------------------------- get details ---------------------------------
const fetchLawyerDetailsApi = (params, id) =>
  privateAgent.get(`${CONTACTS}${GET_LAWYER_DETAILS_ENDPOINT}/${id}`, {
    params,
  });

function* getLawyerDetails(action) {
  console.log("getLawyerDetails", action)
  let res;
  try {
    res = yield fetchLawyerDetailsApi(action.params, action.id);
    console.log("Get lawyer details api:", res.data.data);
    yield put(getLawyerDetailsSuccess(res.data.data));
    action.cb && action.cb(null, res.data.data.assign_customers)
  } catch (e) {
    if (e.response?.data?.error?.message) {
      info("error", e.response?.data?.error?.message);
    }
    yield put(getLawyerDetailsError(e.response));
  }
}

const fetchCustomersDetailsApi = (params, id) =>
  privateAgent.get(`${CONTACTS}${GET_CUSTOMERS_DETAILS_ENDPOINT}/${id}?enable=true`, {
    params,
  });

function* getCustomersDetails(action) {
  let res;
  try {
    console.log("CUSTOMERS")
    res = yield fetchCustomersDetailsApi(action.params, action.id);
    console.log("Get lawyer details api: ", res.data.data);
    yield put(getCustomerDetailsSuccess(res.data.data));
    action.cb && action.cb(null, res.data.data.assign_jobs)
  } catch (e) {
    console.log("CUSTOMERS ERROR", e)
    if (e.response?.data?.error?.message) {
      info("error", e.response?.data?.error?.message);
    }
    yield put(getCustomerDetailsError(e.response));
  }
}

// --------------------------------- assign ---------------------------------
const assignLawyerApi = (body) =>
  privateAgent.put(`${CONTACTS}${ASSIGN_LAWYER_ENDPOINT}`, {
    ...body,
    current_time,
  });

function* assignLawyer(action) {
  let res;
  try {
    res = yield assignLawyerApi(action.body);
    info("success", res.data.data.message);
    yield put(assignLawyerSuccess());
    if (action.cb) action.cb();
  } catch (e) {
    if (e.response?.data?.error?.message) {
      info("error", e.response?.data?.error?.message);
    }
    yield put(assignLawyerError());
  }
}

const assignCustomerApi = (body) =>
  privateAgent.put(`${CONTACTS}${ASSIGN_CUSTOMER_ENDPOINT}`, {
    ...body,
    current_time,
  });

function* assignCustomer(action) {
  let res;
  try {
    res = yield assignCustomerApi(action.body);
    info("success", res.data.data.message);
    yield put(assignCustomerSuccess());
    if (action.cb) action.cb();
  } catch (e) {
    if (e.response?.data?.error?.message) {
      info("error", e.response?.data?.error?.message);
    }
    yield put(assignCustomerError());
  }
}

// ----------------------- delete api ---------------------------------------
const deleteAccountApi = (id, token) =>
  privateAgent.delete(`${TRANSACTIONS}${DELETE_ACCOUNT_ENDPOINT}/${id}`, {
    cancelToken: token.token,
  });

function* deleteAccount(action) {
  let res;
  try {
    if (token) {
      token.cancel();
    }
    token = CancelToken.source();
    res = yield deleteAccountApi(action.id, token);
    info("success", res.data.data.message);
    yield put(delTimeAccSuccess());
    yield put(delExpenseAccSuccess());
    yield put(deleteFixedCostAccountSuccess());
    if (action.cb) action.cb();
  } catch (e) {
    if (e.response?.data?.error?.message) {
      info("error", e.response?.data?.error?.message);
    }
    yield put(delTimeAccError());
    yield put(delExpenseAccError());
    yield put(deleteFixedCostAccountError());
  } finally {
    if (yield cancelled()) {
    }
  }
}

// LAWYER_ASSIGN_REQUEST
export function* watchDashboardActions() {
  yield takeLatest(GET_DASHBOARD_REQUEST, getDashboard);
  // add
  yield takeLatest(ADD_LAWYER_REQUEST, addLawyer);
  yield takeLatest(ADD_CUSTOMER_REQUEST, addCustomer);
  yield takeLatest(ADD_JOB_REQUEST, addJob);
  yield takeLatest(ADD_TIME_ACCOUNT_REQUEST, addTimeAccount);
  yield takeLatest(ADD_FIXED_COST_ACCOUNT_REQUEST, addFixedCostAccount);
  yield takeLatest(ADD_EXPENSE_ACCOUNT_REQUEST, addExpenseAccount);
  yield takeLatest(ADD_VENDOR_REQUEST, addVendor);
  yield takeLatest(ADD_CREDIT_CARD_REQUEST, addCreditCard);
  // fetch
  yield takeLatest(FETCH_LAWYERS_REQUEST, fetchLawyers);
  yield takeLatest(FETCH_CUSTOMERS_REQUEST, fetchCustomers);
  yield takeLatest(FETCH_JOBS_REQUEST, fetchJobs);
  yield takeLatest(FETCH_ALL_ACCOUNTS_REQUEST,fetchAllAccounts)
  yield takeLatest(FETCH_TIME_ACCOUNTS_REQUEST, fetchAccounts);
  yield takeLatest(FETCH_FIXED_COST_ACCOUNTS_REQUEST, fetchAccounts);
  yield takeLatest(FETCH_EXPENSE_ACCOUNTS_REQUEST, fetchAccounts);
  yield takeLatest(FETCH_VENDORS_REQUEST, fetchVendors);
  yield takeLatest(FETCH_CREDIT_CARDS_REQUEST, fetchCreditCards);
  // update
  yield takeLatest(UPDATE_LAWYER_REQUEST, updateLawyer);
  yield takeLatest(UPDATE_TIME_ACCOUNT_REQUEST, updateTransaction);
  yield takeLatest(UPDATE_FIXED_COST_ACCOUNT_REQUEST, updateTransaction);
  yield takeLatest(UPDATE_EXPENSE_ACCOUNT_REQUEST, updateTransaction);
  yield takeLatest(UPDATE_CREDIT_CARD_REQUEST, updateCreditCard);
  yield takeLatest(UPDATE_VENDOR_REQUEST, updateVendor);
  yield takeLatest(UPDATE_JOB_REQUEST, updateJob);
  yield takeLatest(UPDATE_CUSTOMER_REQUEST, updateCustomer);
  // details
  yield takeLatest(LAWYER_DETAILS_REQUEST, getLawyerDetails);
  yield takeLatest(CUSTOMER_DETAILS_REQUEST, getCustomersDetails);
  // assign
  yield takeLatest(LAWYER_ASSIGN_REQUEST, assignLawyer);
  yield takeLatest(CUSTOMER_ASSIGN_REQUEST, assignCustomer);
  // delete
  yield takeLatest(DEL_TIME_ACCOUNT_REQUEST, deleteAccount);
  yield takeLatest(DEL_EXPENSE_ACCOUNT_REQUEST, deleteAccount);
  yield takeLatest(DEL_FIXED_COST_ACCOUNT_REQUEST, deleteAccount);
}
