import { put, takeLatest, takeEvery, cancelled } from "redux-saga/effects";
import { privateAgent, CancelToken } from "configurations/AxiosAgent";
import info from "components/message/index";
import moment from "moment";
import {
  INVOICES,
  GET_INVOICE_TRANSACTION_ENDPOINT,
  CREATE_INVOICE_ENDPOINT,
  GET_OPEN_INVOICES_ENDPOINT,
  PRINT_INVOICE_ENDPOINT,
  DELETE_INVOICE_ENDPOINT,
  PAYMENT_HISTORY_ENDPOINT,
  RECORD_INVOICE_ENDPOINT,
  MULTIPLE_EXPORT_ENDPOINT,
  GET_CLIENT_STATEMENT_ENDPOINT,
  MULTIPLE_STATEMENT_EXPORT_ENDPOINT,
} from "configurations/Constants";
import {
  GET_TRANSACTION_INVOICES,
  GET_TRANSACTION_INVOICES_SUCCESS,
  GET_TRANSACTION_INVOICES_ERROR,
  CREATE_INVOICE,
  CREATE_INVOICE_SUCCESS,
  CREATE_INVOICE_ERROR,
  GET_OPEN_INVOICES_REQUEST,
  PRINT_INVOICE_REQUEST,
  DELETE_INVOICE_REQUEST,
  RECORD_INVOICE_REQUEST,
  MULTIPLE_EXPORT_REQUEST,
  RECORD_PAYMENT_REQUEST,
  GET_CLIENT_STATEMENT_SUCCESS,
  GET_CLIENT_STATEMENT_ERROR,
  GET_CLIENT_STATEMENT_REQUEST,
  MULTIPLE_STATEMENT_EXPORT_REQUEST
} from "configurations/Types";
import {
  getTransactionInvoicesRequest,
  getTransactionInvoicesSuccess,
  getTransactionInvoicesError,
  createInvoice,
  createInvoiceSuccess,
  createInvoiceError,
  getOpenInvoicesRequest,
  getOpenInvoicesSuccess,
  getOpenInvoicesError,
  printInvoicesSuccess,
  printInvoicesError,
  deleteInvoicesRequest,
  deleteInvoicesSuccess,
  deleteInvoicesError,
  recordInvoicesSuccess,
  recordInvoicesError,
  recordPaymentRequest,
  recordPaymentSuccess,
  recordPaymentError,
  getClientStatementSuccess,
  getClientStatementError,
  multipleExportRequest,
  multipleExportSuccess,
  multipleExportError,
  multipleStatementExportError,
  multipleStatementExportRequest,
  multipleStatementExportSuccess
} from "actions/Invoices";
import { actions } from "react-table";

/*------------------------------------------------------FETCH API`s--------------------------------------------------------------------*/
let token;
let current_time = moment().format("YYYY-MM-DD HH:mm:ss");
const fetchInvoicesApi = (params, token) => {
  return privateAgent.get(`${INVOICES}${GET_INVOICE_TRANSACTION_ENDPOINT}`, {
    // cancelToken: token.token,
    params: params || {
      limit: 1000,
      offset: 0,
    },
  });
};

// Get Open Invoices//
const getOpenInvoicesApi = (params, token) => {
  return privateAgent.get(`${INVOICES}${GET_OPEN_INVOICES_ENDPOINT}`, {
    // cancelToken: token.token,
    params: {
      limit: 1000,
      offset: 0,
      ...params,
    },
  });
};

const printInvoiceApi = (body) => {
  console.log('THE BODY: ', body)
  return privateAgent.get(`${INVOICES}${PRINT_INVOICE_ENDPOINT}`, {
    params: {
      ...body,
    },
  });
};

/*------------------------------------------------------POSTS API`s--------------------------------------------------------------------*/

const createInvoicesAPI = (body) => {
  console.log("FORM DATA BODY: ", body);
  return privateAgent.post(`${INVOICES}${CREATE_INVOICE_ENDPOINT}`, body, {
    headers: {
      "Content-Type": "application/application/x-www-form-urlencoded",
    },
  });
};

const multipleExportAPI = (body) => {
  console.log("FORM DATA BODY: ", body);
 
  return privateAgent.post(`${INVOICES}${MULTIPLE_EXPORT_ENDPOINT}`, body, {
    headers: {
      "Content-Type": "application/application/x-www-form-urlencoded",
    },
  });
};
const multipleExportStatementsAPI = (body) => {
  console.log("FORM DATA BODY: ", body);
 
  return privateAgent.post(`${INVOICES}${MULTIPLE_STATEMENT_EXPORT_ENDPOINT}`, body, {
    headers: {
      "Content-Type": "application/application/x-www-form-urlencoded",
    },
  });
};

const recordInvoicesAPI = (id, body) => {
  console.log("FORM DATA BODY: ", body);
  return privateAgent.put(`${INVOICES}${RECORD_INVOICE_ENDPOINT}${id}`, body, {
    headers: {
      "Content-Type": "application/application/x-www-form-urlencoded",
    },
  });
};

const recordPaymentAPI = (params) => {
  console.log("FORM DATA BODY: ", params);
  return privateAgent.get(`${INVOICES}${PAYMENT_HISTORY_ENDPOINT}`, { params });
};


const deleteInvoiceAPI = (body) => {
  console.log("DELETE BODY: ", body);
  return privateAgent.delete(`${INVOICES}${DELETE_INVOICE_ENDPOINT}${body.id}`, {
    params: {
      is_reopen: body.is_reopen
    },
  });
};
/*------------------------------------------------------SAGAS--------------------------------------------------------------------*/

function* getTransactionInvoices(action) {
  console.log("YEARRRRRRRRRRRR: ", action.params);
  console.log("SAGA IS RUNNING");
  let res;
  try {
    res = yield fetchInvoicesApi({
      limit: 1000,
      offset: 0,
      year: moment().year(),
      month: moment().month() + 1,
      ...action.params,
    });
    console.log("invoices: ", res.data.data);
    yield put(getTransactionInvoicesSuccess({ data: res.data.data }));
  } catch (e) {
    if (e.response?.data.error.message) {
      info("error", e.response?.data.error.message);
    }
    yield put(getTransactionInvoicesError(e.response));
  } finally {
    if (yield cancelled()) {
    }
  }
}

function* getOpenInvoices(action) {
  console.log("SAGA IS RUNNING");
  let res;
  try {
    res = yield getOpenInvoicesApi({
      limit: 1000,
      offset: 0,
      year: moment().year(),
      ...action.params,
    });
    console.log("invoices open:", res.data.data);
    yield put(getOpenInvoicesSuccess(res.data.data));
  } catch (e) {
    if (e.response?.data?.error?.message) {
      info("error", e.response?.data?.error?.message);
    }
    yield put(getOpenInvoicesError(e?.response));
  }
}
function* createInvoices(action) {
  const { year, month, ...rest } = action.body;
  let data = { ...rest };
  console.log("BODYYY: ", action.body);
  let formData = new FormData();
  for (const key in  action.body) {
    console.log("FOMR LOOPS: ", key);
    formData.append(key,  action.body[key]);
  }
  for (var pair of formData.entries()) {
    console.log("FORMDATA", pair[0] + ", " + pair[1]);
  }
  console.log("FORMMMMDATAAA: ", formData);
  let res;
  try {
    res = yield createInvoicesAPI(formData);
    info("success", res.data.data.message);
    yield put(createInvoiceSuccess());
    yield put(getTransactionInvoicesRequest());

    // cb && cb(res);
  } catch (e) {
    info("error", e.response?.data.error.message);
    yield put(createInvoiceError());
  } finally {
    if (yield cancelled()) {
    }
  }
}
//BATCH EMAILING
function* multipleExport(action) {
  const payload = action.params;
  const CallBack=action.cb
  console.log("PAYLOADINGGG: ",payload);
  let formData = new FormData()
  formData.append("ids", JSON.stringify(payload.ids))
  // for (const key in payload) {
  //   console.log("FOMR LOOPS: ", key);
  //   formData.append(key, payload[key]);
  // }
  formData.append("current_time", current_time)

  let res;
  try {
    res = yield multipleExportAPI(formData);
    if (res.status == 200) {
  
      info("success", res.data.data.message);
      if (CallBack) {
        CallBack(true, false);
      }
   
      info("success", res.data.data.message);
      yield put(multipleStatementExportSuccess(res));  
      // yield put(getTransactionInvoicesRequest());

      // cb && cb(res);
    }
  } catch (e) {
    info("error", e.response?.data.error.message);
    yield put(multipleStatementExportError());
  } finally {
    if (yield cancelled()) {
    }
  }
}
//STATEMENT BATCH EMAILING
function* multipleStatementExport(action) {
  const payload = action.params;
  const CallBack=action.cb
  console.log("PAYLOADINGGG: ",CallBack);
  let formData = new FormData()
  formData.append("ids", JSON.stringify(payload.ids))
  // for (const key in payload) {
  //   console.log("FOMR LOOPS: ", key);
  //   formData.append(key, payload[key]);
  // }
  formData.append("current_time", current_time)

  let res;
  try {
    res = yield multipleExportStatementsAPI(formData);
    if (res.status == 200) {
  
      info("success", res.data.data.message);
      if (CallBack) {
        CallBack(true, false);
      }
   
      info("success", res.data.data.message);
      yield put(multipleStatementExportSuccess(res));
      // yield put(getTransactionInvoicesRequest());

      // cb && cb(res);
    }
  } catch (e) {
    info("error", e.response?.data.error.message);
    yield put(multipleStatementExportError());
  } finally {
    if (yield cancelled()) {
    }
  }
}
function* printInvoices(action) {
  let payload;
  let invoice_format = action.params.invoice_format;
  if(action.params.invoice_format){
    payload = action.params.payload;
  }
  else {
    payload = action.params;
  }
  console.log("PAYLOADINGGG: ", action);
  let res;
  try {
    res = yield printInvoiceApi({
      ...payload,
      invoice_format,
      current_time: current_time,
    });
    console.log("invoices open:", res.data.data);
    yield put(printInvoicesSuccess(res.data.data));
    action.cb(res.data.data)
  } catch (e) {
    if (e.response?.data.error) {
      info("error", e.response?.data.error);
    }
    yield put(printInvoicesError(e.response));
  }
}

function* deleteInvoice(action) {
  const payload = action.params;
  console.log("PAYLOADINGGGSSSSS: ", payload);
  let res;
  console.log('THIS LINE IS RUNNING')
  try {
    res = yield deleteInvoiceAPI({
      ...payload,
    });
    console.log("invoices open:", res.data.data);
    yield put(deleteInvoicesSuccess(res.data.data));
    yield put(action.cb());

  } catch (e) {
    if (e.response?.data.error.message) {
      info("error", e.response?.data.error.message);
    }
    yield put(deleteInvoicesError(e.response));
  }
}


// Get Client statement
const getClientStatementApi = (params) => {
  return privateAgent.get(`${INVOICES}${GET_CLIENT_STATEMENT_ENDPOINT}`, { params });
};

function* getClientStatement(action) {
  const { cb, params } = action;
  let res;
  try {
    const { customer_name, job_name, ...rest } = params
    res = yield getClientStatementApi(rest)
    console.log("INVOICCESS RESS", res)
    yield put(getClientStatementSuccess(res.data.data));
    cb && cb(res.data.data);
  } catch (e) {
    if (e.response?.data?.error?.message) {
      info("error", e.response?.data?.error?.message);
    }
    yield put(getClientStatementError(e.response));
  }
  finally {
    if (yield cancelled()) {
    }
  }
}

function* recordInvoice(action) {
  // console.log('ACTIONSS: ', action)
  // let id = action.params.id
  const { id, ...rest } = action.params
  // console.log('id: ', id)
  // console.log('rest: ', rest)
  // const { year, month, ...rest } = action.body;
  let data = { ...rest, current_time: current_time };
  // console.log("BODYYY: ", rest);
  console.log('SAGA DATA: ', data)
  let formData = new FormData();
  for (const key in data) {
    console.log("FOMR LOOPS: ", key);
    formData.append(key, data[key]);
  }
  for (var pair of formData.entries()) {
    console.log("FORMDATA", pair[0] + ", " + pair[1]);
  }
  console.log("FORMMMMDATAAA: ", formData);
  let res;
  try {
    res = yield recordInvoicesAPI(id, formData);
    info("success", res.data.data.message);
    yield put(recordInvoicesSuccess());
    action.cb()
    // yield put(getTransactionInvoicesRequest());

    // cb && cb(res);
  } catch (e) {
    info("error", e.response?.data.error.message);
    yield put(recordInvoicesError());
    action.cb()

  } finally {
    if (yield cancelled()) {
    }
  }
}

//GET PAYMENT History

function* paymentHistory(action) {
  // console.log('ACTIONSS: ', action)
  // let id = action.params.id
  // const {id, ...rest} = action.params
  // // console.log('id: ', id)
  // // console.log('rest: ', rest)
  // // const { year, month, ...rest } = action.body;
  // let data = { ...rest, current_time: current_time };
  // // console.log("BODYYY: ", rest);
  // console.log('SAGA DATA: ', data)
  // let formData = new FormData();
  // for (const key in data) {
  //   console.log("FOMR LOOPS: ", key);
  //   formData.append(key, data[key]);
  // }
  // for (var pair of formData.entries()) {
  //   console.log("FORMDATA", pair[0] + ", " + pair[1]);
  // }
  // console.log("FORMMMMDATAAA: ", formData);
  const { cb, params} = action;
  delete params.month;
  delete params.job
  // delete params.job_id
  // delete params.customer_id
  // delete params.year
  console.log('AR SEARCH TOOL ACTIONS: ', params)

  let res;
  try {
    const { ...rest } = params
    let body = { ...rest, current_time: current_time }
    console.log('MY BODY: ', body)
    res = yield recordPaymentAPI(body);
    // info("success", res.data.data.message);
    yield put(recordPaymentSuccess(res.data.data));
    cb && cb(res.data.data);
    // action.cb()
    // yield put(getTransactionInvoicesRequest());

    // cb && cb(res);
  } catch (e) {
    info("error", e.response?.data.error.message);
    yield put(recordPaymentError());
    action.cb()

  } finally {
    if (yield cancelled()) {
    }
  }
}

// Get Open Invoices//

export function* WatchInvoiceActions() {
  yield takeLatest(GET_TRANSACTION_INVOICES, getTransactionInvoices);
  yield takeLatest(GET_OPEN_INVOICES_REQUEST, getOpenInvoices);
  yield takeLatest(CREATE_INVOICE, createInvoices);
  yield takeLatest(PRINT_INVOICE_REQUEST, printInvoices);
  yield takeLatest(DELETE_INVOICE_REQUEST, deleteInvoice);
  yield takeLatest(RECORD_INVOICE_REQUEST, recordInvoice);
  yield takeLatest(MULTIPLE_EXPORT_REQUEST, multipleExport);
  yield takeLatest(MULTIPLE_STATEMENT_EXPORT_REQUEST, multipleStatementExport);
  yield takeLatest(RECORD_PAYMENT_REQUEST, paymentHistory);
  yield takeLatest(GET_CLIENT_STATEMENT_REQUEST, getClientStatement)
}
