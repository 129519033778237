import React from "react";
import { Radio } from "antd";
import styled from "styled-components";

const StyledRadio = styled(Radio)`
  .ant-radio-wrapper:hover .ant-radio,
  .ant-radio:hover .ant-radio-inner,
  .ant-radio-input:focus + .ant-radio-inner {
    border-color: #58BA27 !important;
  }
  .ant-radio-checked .ant-radio-inner {
    border-color: #58BA27 !important;
  }
  .ant-radio-inner::after {
    background-color: #58BA27 !important;
  }
  .ant-radio-inner {
    border-color: #58BA27 !important;
  }
`;

const RadioWrapper = (props) => {
  return <StyledRadio {...props} />;
};

export default RadioWrapper;
