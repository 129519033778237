import { put, takeLatest } from "redux-saga/effects";
import {
  BUY_SUBSCRIPTION_REQUEST,
  CANCEL_SUBSCRIPTION_REQUEST,
  GET_SUBSCRIPTION_REQUEST,
  ALL_SUBSCRIPTION_REQUEST,
  CHECK_SUBSCRIPTION_REQUEST
} from "../configurations/Types";
import {
  BUY_SUBSCRIPTION_ENDPOINT,
  CANCEL_SUBSCRIPTION_ENDPOINT,
  SUBSCRIPTION_ENDPOINT,
  LOCAL_STORAGE_KEY,
  ALL_SUBSCRIPTION_ENDPOINT,
  CHECK_SUBSCRIPTION_ENDPOINT
  //   CANCEL_SUBSCRIPTION,
} from "../configurations/Constants";

import {
  buySubscriptionSuccess,
  buySubscriptionError,
  cancelSubscriptionSuccess,
  cancelSubscriptionError,
  getSubscriptionStatus,
  getSubscriptionStatusSuccess,
  getSubscriptionStatusError,
  setSubscriptionStatus,
  allSubscriptionError,
  allSubscriptionRequest,
  allSubscriptionSuccess,
  checkSubscriptionError,
  checkSubscriptionRequest,
  checkSubscriptionSuccess
} from "../actions/Subscription";
import moment from "moment";
import info from "../components/message/index";
import { privateAgent } from "../configurations/AxiosAgent";

// buy subscription
const buySubscriptionApi = (paymentMethod, params) =>
  privateAgent.post(`${BUY_SUBSCRIPTION_ENDPOINT}`, {
    ...params,
    receipt_data: paymentMethod,
    platform: "stripe",
    current_time: moment().format("YYYY-MM-DD HH:mm:ss"),
  });
function* buySubscription(action) {
  let res;
  try {
    let { paymentMethod, error } = yield action.generatePaymentMethod();

    if (error) throw error;

    res = yield buySubscriptionApi(paymentMethod, { ...action.params
      //, country: paymentMethod.card.country 
    });
    console.log('BUY SUB RESPONSE: ', res)

    info("success", `Purchase successful`);
    //yield put(setSubscriptionStatus(res.data.data));
    yield put(getSubscriptionStatus());
    console.log('GOT SUBSCRIPTION STATUS, NOW GOING TO CALL SUCCESS ACTION')
    yield put(buySubscriptionSuccess(action.callback));

    if (action.callback) {
     // action.callback();
    }
  } catch (e) {
    if (e.response) {
      info("error", `${e.response.data.error.message}`);
    }
    yield put(buySubscriptionError());
  }
}

const allSubscriptionApi = () => {
  //console.log('THE PARAMS: ',params)
  return privateAgent.get(`${BUY_SUBSCRIPTION_ENDPOINT}${ALL_SUBSCRIPTION_ENDPOINT}`);
};
function *allSubscription(action){
  const {cb,params} = action;
  //console.log('AR SEARCH TOOL ACTIONS: ', params)
  let res;
  try {
    res=yield allSubscriptionApi();
    console.log("AALL SUBSCRIPTIONS",res)
    yield put(allSubscriptionSuccess(res.data.data));
    cb && cb(res.data.data);
  } catch (e) {
    if (e.response?.data?.error?.message) {
      info("error", e.response?.data?.error?.message);
    }
    yield put(allSubscriptionError(e.response));
  }
  finally {
    yield put(allSubscriptionError());
    //if (yield cancelled()) {
    }
  }
// cancel subscription
const cancelSubscriptionApi = (params) =>
  privateAgent.post(
    `${BUY_SUBSCRIPTION_ENDPOINT}${CANCEL_SUBSCRIPTION_ENDPOINT}`,
    {
      ...params,
      status: true,
      current_time: moment().format("YYYY-MM-DD HH:mm:ss"),
    }
  );
function* cancelSubscription(action) {
  let res;
  try {
    res = yield cancelSubscriptionApi(action.params);
    info("success", `Your subscription has been cancelled`);

    //yield put(setSubscriptionStatus({ plan: "none" }));
    //Set new subscription in localStorage
    // let newStorage = JSON.parse(localStorage.getItem("escrowauth"));
    // newStorage.subscription = { plan: "none" };

    // localStorage.setItem("escrowauth", JSON.stringify(newStorage));

    yield put(cancelSubscriptionSuccess());
    yield put(getSubscriptionStatus());
    // if (action.cb) {
    //   action.cb();
    // }
  } catch (e) {
    if (e.response) {
      info("error", `${e.response.data.error.message}`);
    }
    yield put(cancelSubscriptionError());
  }
}

// get subscriptions
const getSubscriptionApi = () => privateAgent.get(`${SUBSCRIPTION_ENDPOINT}`);
function* getSubscription() {
  let res;
  try {
    res = yield getSubscriptionApi();
    console.log(res, "getSubscriptionApi", res);
    // info("success", `${res.data.data.message}`);

    yield put(setSubscriptionStatus(res.data.data.subscription));
    //Set new subscription in localStorage
    let newStorage = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY));
    newStorage.subscription = res.data.data.subscription;

    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(newStorage));

    yield put(getSubscriptionStatusSuccess());
    // if (action.cb) {
    //   action.cb();
    // }
  } catch (e) {
    if (e.response) {
      info("error", `${e.response.data.error.message}`);
    }

    yield put(getSubscriptionStatusError());
  }
}

const checkSubscriptionApi = (id) => privateAgent.get(`${CHECK_SUBSCRIPTION_ENDPOINT}?invoice_ids=${id}`);
function* checkSubscription(params) {
  let res;
  const {id} = params;
  console.log("ID: ", params);
  try {
    res = yield checkSubscriptionApi(params.params);
    console.log("getSubscriptionApi", res);
    // info("success", `${res.data.data.message}`);

    //yield put(setSubscriptionStatus(res.data.data.subscription));
    //Set new subscription in localStorage
    // let newStorage = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY));
    // newStorage.subscription = res.data.data.subscription;

    // localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(newStorage));

    yield put(checkSubscriptionSuccess(res.data.data));
    // if (action.cb) {
    //   action.cb();
    // }
  } catch (e) {
    if (e.response) {
      info("error", `${e.response.data.error.message}`);
    }

    yield put(checkSubscriptionError());
  }
}
// get subscription
export function* watchSubscription() {
  yield takeLatest(BUY_SUBSCRIPTION_REQUEST, buySubscription);
  yield takeLatest(CANCEL_SUBSCRIPTION_REQUEST, cancelSubscription);
  yield takeLatest(GET_SUBSCRIPTION_REQUEST, getSubscription);
  yield takeLatest(ALL_SUBSCRIPTION_REQUEST, allSubscription);
  yield takeLatest(CHECK_SUBSCRIPTION_REQUEST, checkSubscription);
}
