import {
  GET_SNAPSHOT_REQUEST,
  GET_SNAPSHOT_SUCCESS,
  GET_SNAPSHOT_ERROR,
} from "configurations/Types";

export function fetchSnapshotRequest(year,graph_type) {
  console.log(graph_type,"qqqqqqqqqqqqqq")

  return {
    type: GET_SNAPSHOT_REQUEST,
    year,
    graph_type
 
  }
}
export function fetchSnapshotSuccess(payload, graph_type) {
  console.log(payload,"qwerty")
  return {
    type: GET_SNAPSHOT_SUCCESS,
    payload,
    graph_type,
    invoiceIncome: graph_type == "invoices" ? payload.monthly_income : [],
    monthlyIncome:graph_type ="payments" ? payload.monthly_income : [],
  }
}
export function fetchSnapshotError (error) {
  return {
    type: GET_SNAPSHOT_ERROR,
    error
  }
}