export const TOGGLE_MODE = "TOGGLE_MODE";

export const OPEN_MODAL = "OPEN_MODAL";
export const CLOSE_MODAL = "CLOSE_MODAL";
export const CLOSE_ALL_MODAL = "CLOSE_ALL_MODAL";

//App
export const SET_STRIPE = "SET_STRIPE";
export const SET_HISTORY = "SET_HISTORY";
export const REDIRECT = "REDIRECT";
export const SET_TOUR = "SET_TOUR";
export const DELETE_DEMO_REQUEST = "DELETE_DEMO_REQUEST";
export const DELETE_DEMO_SUCCESS = "DELETE_DEMO_SUCCESS";
export const DELETE_DEMO_ERROR = "DELETE_DEMO_ERROR";

// Contact
export const CONTACT_REQUEST = "CONTACT_REQUEST";
export const CONTACT_SUCCESS = "CONTACT_SUCCESS";
export const CONTACT_ERROR = "CONTACT_ERROR";

//Auth
export const REGISTER_REQUEST = "REGISTER_REQUEST";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_ERROR = "REGISTER_ERROR";
export const REGISTER_COMPLETE_REQUEST = "REGISTER_COMPLETE_REQUEST";
export const REGISTER_COMPLETE_SUCCESS = "REGISTER_COMPLETE_SUCCESS";
export const REGISTER_COMPLETE_ERROR = "REGISTER_ERROR";
export const SIGNUP_VALIDATION_REQUEST = "SIGNUP_VALIDATION_REQUEST";
export const SIGNUP_VALIDATION_SUCCESS = "SIGNUP_VALIDATION_SUCCESS";
export const SIGNUP_VALIDATION_ERROR = "SIGNUP_VALIDATION_ERROR";
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const RESEND_EMAIL = "RESEND_EMAIL";
export const LOGOUT = "LOGOUT";
export const PASSWORD_RESET_REQUEST = "PASSWORD_RESET_REQUEST";
export const PASSWORD_RESET_SUCCESS = "PASSWORD_RESET_SUCCESS";
export const PASSWORD_RESET_ERROR = "PASSWORD_RESET_ERROR";
export const RESEND_CODE_REQUEST = "RESEND_CODE_REQUEST";
export const RESEND_CODE_SUCCESS = "RESEND_CODE_SUCCESS";
export const RESEND_CODE_ERROR = "RESEND_CODE_ERROR";
export const VERIFY_TOKEN_REQUEST = "VERIFY_TOKEN_REQUEST";
export const VERIFY_TOKEN_SUCCESS = "VERIFY_TOKEN_SUCCESS";
export const VERIFY_TOKEN_ERROR = "VERIFY_TOKEN_ERROR";
export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR";
export const CHANGE_PASSWORD_REQUEST = "CHANGE_PASSWORD_REQUEST";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_ERROR = "CHANGE_PASSWORD_ERROR";
export const CHANGE_HEADER_PROFILE_PICTURE = "CHANGE_HEADER_PROFILE_PICTURE";

//profile name and email update
export const PROFILE_UPDATE_REQUEST = "PROFILE_UPDATE_REQUEST";
export const PROFILE_UPDATE_SUCCESS = "PROFILE_UPDATE_SUCCESS";
export const PROFILE_UPDATE_ERROR = "PROFILE_UPDATE_ERROR";

export const GET_SNAPSHOT_REQUEST = "GET_SNAPSHOT_REQUEST";
export const GET_SNAPSHOT_SUCCESS = "GET_SNAPSHOT_SUCCESS";
export const GET_SNAPSHOT_ERROR = "GET_SNAPSHOT_ERROR";

// Organization
export const GET_ORGANIZATION_REQUEST = "GET_ORGANIZATION_REQUEST";
export const GET_ORGANIZATION_SUCCESS = "GET_ORGANIZATION_SUCCESS";
export const GET_ORGANIZATION_ERROR = "GET_ORGANIZATION_ERROR";

// Dashboard
export const GET_DASHBOARD_REQUEST = "GET_DASHBOARD_REQUEST";
export const GET_DASHBOARD_SUCCESS = "GET_DASHBOARD_SUCCESS";
export const GET_DASHBOARD_ERROR = "GET_DASHBOARD_ERROR";

//--------------------------Dashboard types--------------------------
// for lawyers
export const FETCH_LAWYERS_REQUEST = "FETCH_LAWYERS_REQUEST";
export const FETCH_LAWYERS_SUCCESS = "FETCH_LAWYERS_SUCCESS";
export const FETCH_LAWYERS_ERROR = "FETCH_LAWYERS_ERROR";

export const UPDATE_LAWYER_REQUEST = "UPDATE_LAWYER_REQUEST";
export const UPDATE_LAWYER_SUCCESS = "UPDATE_LAWYER_SUCCESS";
export const UPDATE_LAWYER_ERROR = "UPDATE_LAWYER_ERROR";

export const DEL_LAWYER_REQUEST = "DEL_LAWYER_REQUEST";
export const DEL_LAWYER_SUCCESS = "DEL_LAWYER_SUCCESS";
export const DEL_LAWYER_ERROR = "DEL_LAWYER_ERROR";

export const LAWYER_DETAILS_REQUEST = "LAWYER_DETAILS_REQUEST";
export const LAWYER_DETAILS_SUCCESS = "LAWYER_DETAILS_SUCCESS";
export const LAWYER_DETAILS_ERROR = "LAWYER_DETAILS_ERROR";
export const LAWYER_DETAILS_EMPTY = "LAWYER_DETAILS_EMPTY";

export const LAWYER_ASSIGN_REQUEST = "LAWYER_ASSIGN_REQUEST";
export const LAWYER_ASSIGN_SUCCESS = "LAWYER_ASSIGN_SUCCESS";
export const LAWYER_ASSIGN_ERROR = "LAWYER_ASSIGN_ERROR";

export const ADD_LAWYER_REQUEST = "ADD_LAWYER_REQUEST";
export const ADD_LAWYER_SUCCESS = "ADD_LAWYER_SUCCESS";
export const ADD_LAWYER_ERROR = "ADD_LAWYER_ERROR";

export const SEARCH_LAWYER_REQUEST = "SEARCH_LAWYER_REQUEST";
export const SEARCH_LAWYER_SUCCESS = "SEARCH_LAWYER_SUCCESS";

// for customers
export const FETCH_CUSTOMERS_REQUEST = "FETCH_CUSTOMERS_REQUEST";
export const FETCH_CUSTOMERS_SUCCESS = "FETCH_CUSTOMERS_SUCCESS";
export const FETCH_CUSTOMERS_ERROR = "FETCH_CUSTOMERS_ERROR";

export const UPDATE_CUSTOMER_REQUEST = "UPDATE_CUSTOMER_REQUEST";
export const UPDATE_CUSTOMER_SUCCESS = "UPDATE_CUSTOMER_SUCCESS";
export const UPDATE_CUSTOMER_ERROR = "UPDATE_CUSTOMER_ERROR";

export const DEL_CUSTOMER_REQUEST = "DEL_CUSTOMER_REQUEST";
export const DEL_CUSTOMER_SUCCESS = "DEL_CUSTOMER_SUCCESS";
export const DEL_CUSTOMER_ERROR = "DEL_CUSTOMER_ERROR";

export const CUSTOMER_DETAILS_REQUEST = "CUSTOMER_DETAILS_REQUEST";
export const CUSTOMER_DETAILS_SUCCESS = "CUSTOMER_DETAILS_SUCCESS";
export const CUSTOMER_DETAILS_ERROR = "CUSTOMER_DETAILS_ERROR";

export const CUSTOMER_ASSIGN_REQUEST = "CUSTOMER_ASSIGN_REQUEST";
export const CUSTOMER_ASSIGN_SUCCESS = "CUSTOMER_ASSIGN_SUCCESS";
export const CUSTOMER_ASSIGN_ERROR = "CUSTOMER_ASSIGN_ERROR";

export const ADD_CUSTOMER_REQUEST = "ADD_CUSTOMER_REQUEST";
export const ADD_CUSTOMER_SUCCESS = "ADD_CUSTOMER_SUCCESS";
export const ADD_CUSTOMER_ERROR = "ADD_CUSTOMER_ERROR";

export const SEARCH_CUSTOMER_REQUEST = "SEARCH_CUSTOMER_REQUEST";
export const SEARCH_CUSTOMER_SUCCESS = "SEARCH_CUSTOMER_SUCCESS";

// for jobs
export const FETCH_JOBS_REQUEST = "FETCH_JOBS_REQUEST";
export const FETCH_JOBS_SUCCESS = "FETCH_JOBS_SUCCESS";
export const FETCH_JOBS_ERROR = "FETCH_JOBS_ERROR";

export const UPDATE_JOB_REQUEST = "UPDATE_JOB_REQUEST";
export const UPDATE_JOB_SUCCESS = "UPDATE_JOB_SUCCESS";
export const UPDATE_JOB_ERROR = "UPDATE_JOB_ERROR";

export const DEL_JOB_REQUEST = "DEL_JOB_REQUEST";
export const DEL_JOB_SUCCESS = "DEL_JOB_SUCCESS";
export const DEL_JOB_ERROR = "DEL_JOB_ERROR";

export const ADD_JOB_REQUEST = "ADD_JOB_REQUEST";
export const ADD_JOB_SUCCESS = "ADD_JOB_SUCCESS";
export const ADD_JOB_ERROR = "ADD_JOB_ERROR";

export const SEARCH_JOB_REQUEST = "SEARCH_JOB_REQUEST";
export const SEARCH_JOB_SUCCESS = "SEARCH_JOB_SUCCESS";

//for All Accounts
export const FETCH_ALL_ACCOUNTS_REQUEST = "FETCH_ALL_ACCOUNTS_REQUEST";
export const FETCH_ALL_ACCOUNTS_SUCCESS = "FETCH_ALL_ACCOUNTS_SUCCESS";
export const FETCH_ALL_ACCOUNTS_ERROR = "FETCH_ALL_ACCOUNTS_ERROR";

// for time account
export const FETCH_TIME_ACCOUNTS_REQUEST = "FETCH_TIME_ACCOUNTS_REQUEST";
export const FETCH_TIME_ACCOUNTS_SUCCESS = "FETCH_TIME_ACCOUNTS_SUCCESS";
export const FETCH_TIME_ACCOUNTS_ERROR = "FETCH_TIME_ACCOUNTS_ERROR";

export const UPDATE_TIME_ACCOUNT_REQUEST = "UPDATE_TIME_ACCOUNT_REQUEST";
export const UPDATE_TIME_ACCOUNT_SUCCESS = "UPDATE_TIME_ACCOUNT_SUCCESS";
export const UPDATE_TIME_ACCOUNT_ERROR = "UPDATE_TIME_ACCOUNR_ERROR";

export const DEL_TIME_ACCOUNT_REQUEST = "DEL_TIME_ACCOUNT_REQUEST";
export const DEL_TIME_ACCOUNT_SUCCESS = "DEL_TIME_ACCOUNT_SUCCESS";
export const DEL_TIME_ACCOUNT_ERROR = "DEL_TIME_ACCOUNT_ERROR";

export const ADD_TIME_ACCOUNT_REQUEST = "ADD_TIME_ACCOUNT_REQUEST";
export const ADD_TIME_ACCOUNT_SUCCESS = "ADD_TIME_ACCOUNT_SUCCESS";
export const ADD_TIME_ACCOUNT_ERROR = "ADD_TIME_ACCOUNT_ERROR";

export const SEARCH_TIME_ACCOUNT_REQUEST = "SEARCH_TIME_ACCOUNT_REQUEST";
export const SEARCH_TIME_ACCOUNT_SUCCESS = "SEARCH_TIME_ACCOUNT_SUCCESS";

// for fixed cost account
export const FETCH_FIXED_COST_ACCOUNTS_REQUEST = "FETCH_FIXED_COST_ACCOUNTS_REQUEST";
export const FETCH_FIXED_COST_ACCOUNTS_SUCCESS = "FETCH_FIXED_COST_ACCOUNTS_SUCCESS";
export const FETCH_FIXED_COST_ACCOUNTS_ERROR = "FETCH_FIXED_COST_ACCOUNTS_ERROR";

export const UPDATE_FIXED_COST_ACCOUNT_REQUEST = "UPDATE_FIXED_COST_ACCOUNT_REQUEST";
export const UPDATE_FIXED_COST_ACCOUNT_SUCCESS = "UPDATE_FIXED_COST_ACCOUNT_SUCCESS";
export const UPDATE_FIXED_COST_ACCOUNT_ERROR = "UPDATE_FIXED_COST_ACCOUNR_ERROR";

export const DEL_FIXED_COST_ACCOUNT_REQUEST = "DEL_FIXED_COST_ACCOUNT_REQUEST";
export const DEL_FIXED_COST_ACCOUNT_SUCCESS = "DEL_FIXED_COST_ACCOUNT_SUCCESS";
export const DEL_FIXED_COST_ACCOUNT_ERROR = "DEL_FIXED_COST_ACCOUNT_ERROR";

export const ADD_FIXED_COST_ACCOUNT_REQUEST = "ADD_FIXED_COST_ACCOUNT_REQUEST";
export const ADD_FIXED_COST_ACCOUNT_SUCCESS = "ADD_FIXED_COST_ACCOUNT_SUCCESS";
export const ADD_FIXED_COST_ACCOUNT_ERROR = "ADD_FIXED_COST_ACCOUNT_ERROR";

export const SEARCH_FIXED_COST_ACCOUNT_REQUEST = "SEARCH_FIXED_COST_ACCOUNT_REQUEST";
export const SEARCH_FIXED_COST_ACCOUNT_SUCCESS = "SEARCH_FIXED_COST_ACCOUNT_SUCCESS";
// for expense account
export const FETCH_EXPENSE_ACCOUNTS_REQUEST = "FETCH_EXPENSE_ACCOUNTS_REQUEST";
export const FETCH_EXPENSE_ACCOUNTS_SUCCESS = "FETCH_EXPENSE_ACCOUNTS_SUCCESS";
export const FETCH_EXPENSE_ACCOUNTS_ERROR = "FETCH_EXPENSE_ACCOUNTS_ERROR";

export const UPDATE_EXPENSE_ACCOUNT_REQUEST = "UPDATE_EXPENSE_ACCOUNT_REQUEST";
export const UPDATE_EXPENSE_ACCOUNT_SUCCESS = "UPDATE_EXPENSE_ACCOUNT_SUCCESS";
export const UPDATE_EXPENSE_ACCOUNT_ERROR = "UPDATE_EXPENSE_ACCOUNT_ERROR";

export const DEL_EXPENSE_ACCOUNT_REQUEST = "DEL_EXPENSE_ACCOUNT_REQUEST";
export const DEL_EXPENSE_ACCOUNT_SUCCESS = "DEL_EXPENSE_ACCOUNT_SUCCESS";
export const DEL_EXPENSE_ACCOUNT_ERROR = "DEL_EXPENSE_ACCOUNT_ERROR";

export const ADD_EXPENSE_ACCOUNT_REQUEST = "ADD_EXPENSE_ACCOUNT_REQUEST";
export const ADD_EXPENSE_ACCOUNT_SUCCESS = "ADD_EXPENSE_ACCOUNT_SUCCESS";
export const ADD_EXPENSE_ACCOUNT_ERROR = "ADD_EXPENSE_ACCOUNT_ERROR";

export const SEARCH_EXPENSE_ACCOUNT_REQUEST = "SEARCH_EXPENSE_ACCOUNT_REQUEST";
export const SEARCH_EXPENSE_ACCOUNT_SUCCESS = "SEARCH_EXPENSE_ACCOUNT_SUCCESS";

// for vendor
export const FETCH_VENDORS_REQUEST = "FETCH_VENDORS_REQUEST";
export const FETCH_VENDORS_SUCCESS = "FETCH_VENDORS_SUCCESS";
export const FETCH_VENDORS_ERROR = "FETCH_VENDORS_ERROR";

export const UPDATE_VENDOR_REQUEST = "UPDATE_VENDOR_REQUEST";
export const UPDATE_VENDOR_SUCCESS = "UPDATE_VENDOR_SUCCESS";
export const UPDATE_VENDOR_ERROR = "UPDATE_VENDOR_ERROR";

export const DEL_VENDOR_REQUEST = "DEL_VENDOR_REQUEST";
export const DEL_VENDOR_SUCCESS = "DEL_VENDOR_SUCCESS";
export const DEL_VENDOR_ERROR = "DEL_VENDOR_ERROR";

export const ADD_VENDOR_REQUEST = "ADD_VENDOR_REQUEST";
export const ADD_VENDOR_SUCCESS = "ADD_VENDOR_SUCCESS";
export const ADD_VENDOR_ERROR = "ADD_VENDOR_ERROR";

export const SEARCH_VENDOR_REQUEST = "SEARCH_VENDOR_REQUEST";
export const SEARCH_VENDOR_SUCCESS = "SEARCH_VENDOR_SUCCESS";

// for credit card
export const FETCH_CREDIT_CARDS_REQUEST = "FETCH_CREDIT_CARDS_REQUEST";
export const FETCH_CREDIT_CARDS_SUCCESS = "FETCH_CREDIT_CARDS_SUCCESS";
export const FETCH_CREDIT_CARDS_ERROR = "FETCH_CREDIT_CARDS_ERROR";

export const UPDATE_CREDIT_CARD_REQUEST = "UPDATE_CREDIT_CARD_REQUEST";
export const UPDATE_CREDIT_CARD_SUCCESS = "UPDATE_CREDIT_CARD_SUCCESS";
export const UPDATE_CREDIT_CARD_ERROR = "UPDATE_CREDIT_CARD_ERROR";

export const DEL_CREDIT_CARD_REQUEST = "DEL_CREDIT_CARD_REQUEST";
export const DEL_CREDIT_CARD_SUCCESS = "DEL_CREDIT_CARD_SUCCESS";
export const DEL_CREDIT_CARD_ERROR = "DEL_CREDIT_CARD_ERROR";

export const ADD_CREDIT_CARD_REQUEST = "ADD_CREDIT_CARD_REQUEST";
export const ADD_CREDIT_CARD_SUCCESS = "ADD_CREDIT_CARD_SUCCESS";
export const ADD_CREDIT_CARD_ERROR = "ADD_CREDIT_CARD_ERROR";

export const SEARCH_CREDIT_CARD_REQUEST = "SEARCH_CREDIT_CARD_REQUEST";
export const SEARCH_CREDIT_CARD_SUCCESS = "SEARCH_CREDIT_CARD_SUCCESS";

// for clear all search
export const CLEAR_TYPE_SEARCH = "CLEAR_TYPE_SEARCH";

export const CLEAR_ASSIGN_USER_TYPE_SEARCH = "CLEAR_ASSIGN_USER_TYPE_SEARCH";
export const CLEAR_UNASSIGNED_USER_TYPE_SEARCH =
  "CLEAR_UNASSIGNED_USER_TYPE_SEARCH";

/* --------------- Transactions Types ------------------- */

// get open transactions
export const GET_OPEN_TRANSACTIONS_REQUEST = "GET_OPEN_TRANSACTIONS_REQUEST";
export const GET_OPEN_TRANSACTIONS_SUCCESS = "GET_OPEN_TRANSACTIONS_SUCCESS";
export const GET_OPEN_TRANSACTIONS_ERROR = "GET_OPEN_TRANSACTIONS_ERROR";

// get recurring transactions
export const GET_RECURRING_TRANSACTIONS_REQUEST = "GET_RECURRING_TRANSACTIONS_REQUEST";
export const GET_RECURRING_TRANSACTIONS_SUCCESS = "GET_RECURRING_TRANSACTIONS_SUCCESS";
export const GET_RECURRING_TRANSACTIONS_ERROR = "GET_RECURRING_TRANSACTIONS_ERROR";

// get close transactions
export const GET_CLOSE_TRANSACTIONS_REQUEST = "GET_CLOSE_TRANSACTIONS_REQUEST";
export const GET_CLOSE_TRANSACTIONS_SUCCESS = "GET_CLOSE_TRANSACTIONS_SUCCESS";
export const GET_CLOSE_TRANSACTIONS_ERROR = "GET_CLOSE_TRANSACTIONS_ERROR";

//delete open transaction
export const DEL_OPEN_TRANSACTION_REQUEST = "DEL_OPEN_TRANSACTION_REQUEST";
export const DEL_OPEN_TRANSACTION_SUCCESS = "DEL_OPEN_TRANSACTION_SUCCESS";
export const DEL_OPEN_TRANSACTION_ERROR = "DEL_OPEN_TRANSACTION_ERROR";

//Add Time Transaction
export const ADD_OPEN_TIME_TRANSACTION_REQUEST =
  " ADD_OPEN_TIME_TRANSACTION_REQUEST";
export const ADD_OPEN_TIME_TRANSACTION_SUCCESS =
  " ADD_OPEN_TIME_TRANSACTION_SUCCESS";
export const ADD_OPEN_TIME_TRANSACTION_ERROR =
  " ADD_OPEN_TIME_TRANSACTION_ERROR";

//Update Time transaction
export const UPDATE_OPEN_TIME_TRANSACTION_REQUEST =
  " UPDATE_OPEN_TIME_TRANSACTION_REQUEST";
export const UPDATE_OPEN_TIME_TRANSACTION_SUCCESS =
  " UPDATE_OPEN_TIME_TRANSACTION_SUCCESS";
export const UPDATE_OPEN_TIME_TRANSACTION_ERROR =
  " UPDATE_OPEN_TIME_TRANSACTION_ERROR";

//Add Expense transaction
export const ADD_EXPENSE_TRANSACTION_REQUEST =
  " ADD_EXPENSE_TRANSACTION_REQUEST";
export const ADD_EXPENSE_TRANSACTION_SUCCESS =
  " ADD_EXPENSE_TRANSACTION_SUCCESS";
export const ADD_EXPENSE_TRANSACTION_ERROR = " ADD_EXPENSE_TRANSACTION_ERROR";

//Add Fixed Cost transaction

export const ADD_FIXED_COST_TRANSACTION_REQUEST =
  " ADD_FIXED_COST_TRANSACTION_REQUEST";
export const ADD_FIXED_COST_TRANSACTION_SUCCESS =
  " ADD_FIXED_COST_TRANSACTION_SUCCESS";
export const ADD_FIXED_COST_TRANSACTION_ERROR = " ADD_FIXED_COST_TRANSACTION_ERROR";

//Edit Fixed cost transaction
export const EDIT_FIXED_COST_TRANSACTION_REQUEST =
  " EDIT_FIXED_COST_TRANSACTION_REQUEST";
export const EDIT_FIXED_COST_TRANSACTION_SUCCESS =
  " EDIT_FIXED_COST_TRANSACTION_SUCCESS";
export const EDIT_FIXED_COST_TRANSACTION_ERROR = " EDIT_FIXED_COST_TRANSACTION_ERROR";


//Edit Expense transaction
export const EDIT_EXPENSE_TRANSACTION_REQUEST =
  " EDIT_EXPENSE_TRANSACTION_REQUEST";
export const EDIT_EXPENSE_TRANSACTION_SUCCESS =
  " EDIT_EXPENSE_TRANSACTION_SUCCESS";
export const EDIT_EXPENSE_TRANSACTION_ERROR = " EDIT_EXPENSE_TRANSACTION_ERROR";

//Settings
export const ASSIGN_USER_ROLE_REQUEST = "ASSIGN_USER_ROLE_REQUEST";
export const ASSIGN_USER_ROLE_SUCCESS = "ASSIGN_USER_ROLE_SUCCESS";
export const ASSIGN_USER_ROLE_ERROR = "ASSIGN_USER_ROLE_ERROR";

export const FETCH_ASSIGN_USER_ROLE_REQUEST = "FETCH_ASSIGN_USER_ROLE_REQUEST";
export const FETCH_ASSIGN_USER_ROLE_SUCCESS = "FETCH_ASSIGN_USER_ROLE_SUCCESS";
export const FETCH_ASSIGN_USER_ROLE_ERROR = "FETCH_ASSIGN_USER_ROLE_ERROR";

export const FETCH_UNASSIGNED_USER_ROLE_REQUEST =
  "FETCH_UNASSIGNED_USER_ROLE_REQUEST";
export const FETCH_UNASSIGNED_USER_ROLE_SUCCESS =
  "FETCH_UNASSIGNED_USER_ROLE_SUCCESS";
export const FETCH_UNASSIGNED_USER_ROLE_ERROR =
  "FETCH_UNASSIGNED_USER_ROLE_ERROR";

export const DELETE_ASSIGN_USER_ROLE_REQUEST =
  "DELETE_ASSIGN_USER_ROLE_REQUEST";
export const DELETE_ASSIGN_USER_ROLE_SUCCESS =
  "DELETE_ASSIGN_USER_ROLE_SUCCESS";
export const DELETE_ASSIGN_USER_ROLE_ERROR = "DELETE_ASSIGN_USER_ROLE_ERROR";

export const EDIT_ASSIGN_USER_ROLE_REQUEST = "EDIT_ASSIGN_USER_ROLE_REQUEST";
export const EDIT_ASSIGN_USER_ROLE_SUCCESS = "EDIT_ASSIGN_USER_ROLE_SUCCESS";
export const EDIT_ASSIGN_USER_ROLE_ERROR = "EDIT_ASSIGN_USER_ROLE_ERROR";

export const SEARCH_ASSIGN_USER_ROLE_REQUEST =
  "SEARCH_ASSIGN_USER_ROLE_REQUEST";
export const SEARCH_ASSIGN_USER_ROLE_SUCCESS =
  "SEARCH_ASSIGN_USER_ROLE_SUCCESS";

export const SEARCH_UNASSIGNED_USER_ROLE_REQUEST =
  "SEARCH_UNASSIGNED_USER_ROLE_REQUEST";
export const SEARCH_UNASSIGNED_USER_ROLE_SUCCESS =
  "SEARCH_UNASSIGNED_USER_ROLE_SUCCESS";

//Edit Organization Profile
export const EDIT_ORGANIZATION_PROFILE_REQUEST =
  " EDIT_ORGANIZATION_PROFILE_REQUEST";
export const EDIT_ORGANIZATION_PROFILE_SUCCESS =
  " EDIT_ORGANIZATION_PROFILE_SUCCESS";
export const EDIT_ORGANIZATION_PROFILE_ERROR =
  " EDIT_ORGANIZATION_PROFILE_ERROR";

//GET Organization Profile
export const GET_ORGANIZATION_PROFILE_REQUEST =
  "GET_ORGANIZATION_PROFILE_REQUEST";
export const GET_ORGANIZATION_PROFILE_SUCCESS =
  "GET_ORGANIZATION_PROFILE_SUCCESS";
export const GET_ORGANIZATION_PROFILE_ERROR = "GET_ORGANIZATION_PROFILE_ERROR";

//Update password

export const UPDATE_PASSWORD_REQUEST = "UPDATE_PASSWORD_REQUEST";
export const UPDATE_PASSWORD_SUCCESS = "UPDATE_PASSWORD_SUCCESS";
export const UPDATE_PASSWORD_ERROR = "UPDATE_PASSWORD_ERROR";

export const UPDATE_SETTINGS_REQUEST = "UPDATE_SETTINGS_REQUEST";
export const UPDATE_SETTINGS_SUCCESS = "UPDATE_SETTINGS_SUCCESS";
export const UPDATE_SETTINGS_ERROR = "UPDATE_SETTINGS_ERROR";

export const GET_SETTINGS_REQUEST = "GET_SETTINGS_REQUEST";
export const GET_SETTINGS_SUCCESS = "GET_SETTINGS_SUCCESS";
export const GET_SETTINGS_ERROR = "GET_SETTINGS_ERROR";

export const GET_TRANSACTION_INVOICES = "GET_TRANSACTION_INVOICES";
export const GET_TRANSACTION_INVOICES_SUCCESS =
  "GET_TRANSACTION_INVOICES_SUCCESS";
export const GET_TRANSACTION_INVOICES_ERROR = "GET_TRANSACTION_INVOICES_ERROR";

//Manage Users
export const ADD_USER_REQUEST = "ADD_USER_REQUEST";
export const ADD_USER_SUCCESS = "ADD_USER_SUCCESS";
export const ADD_USER_ERROR = "ADD_USER_ERROR";

// Create invoice

export const CREATE_INVOICE = "CREATE_INVOICE";
export const CREATE_INVOICE_SUCCESS = "CREATE_INVOICE_SUCCESS";
export const CREATE_INVOICE_ERROR = "CREATE_INVOICE_ERROR";

// GET Open/Closed Invoices

export const GET_OPEN_INVOICES_REQUEST = "GET_INVOICES_OPEN_REQUEST";
export const GET_OPEN_INVOICES_SUCCESS = "GET_INVOICES_OPEN_SUCCESS";
export const GET_OPEN_INVOICES_ERROR = "GET_INVOICES_OPEN_ERROR";

// Charts
export const GET_CHARTS_REQUEST = "GET_CHARTS_REQUEST";
export const GET_CHARTS_SUCCESS = "GET_CHARTS_SUCCESS";
export const GET_CHARTS_ERROR = "GET_CHARTS_ERROR";
export const GET_CHARTS_DROPDOWNS_REQUEST = "GET_CHARTS_DROPDOWNS_REQUEST";
export const GET_CHARTS_DROPDOWNS_SUCCESS = "GET_CHARTS_DROPDOWNS_SUCCESS";
export const GET_CHARTS_DROPDOWNS_ERROR = "GET_CHARTS_DROPDOWNS_ERROR";
export const CHART_REPORT_REQUEST = "CHART_REPORT_REQUEST";
export const CHART_REPORT_SUCCESS = "CHART_REPORT_SUCCESS";
export const CHART_REPORT_ERROR = "CHART_REPORT_ERROR";
export const CLEAR_CHARTS = "CLEAR_CHARTS";

// PRINT Open/Closed Invoices

export const PRINT_INVOICE_REQUEST = "PRINT_INVOICE_REQUEST";
export const PRINT_INVOICE_SUCCESS = "PRINT_INVOICE_SUCCESS";
export const PRINT_INVOICE_ERROR = "PRINT_INVOICE_ERROR";

// DELETE Open/Closed Invoices

export const DELETE_INVOICE_REQUEST = "DELETE_INVOICE_REQUEST";
export const DELETE_INVOICE_SUCCESS = "DELETE_INVOICE_SUCCESS";
export const DELETE_INVOICE_ERROR = "DELETE_INVOICE_ERROR";

// RECORD Open Invoices

export const RECORD_INVOICE_REQUEST = "RECORD_INVOICE_REQUEST";
export const RECORD_INVOICE_SUCCESS = "RECORD_INVOICE_SUCCESS";
export const RECORD_INVOICE_ERROR = "RECORD_INVOICE_ERROR";

// RECORD pAYMENT

export const RECORD_PAYMENT_REQUEST = "RECORD_PAYMENT_REQUEST";
export const RECORD_PAYMENT_SUCCESS = "RECORD_PAYMENT_SUCCESS";
export const RECORD_PAYMENT_ERROR = "RECORD_PAYMENT_ERROR";

// BATCH EMAILING
export const MULTIPLE_EXPORT_REQUEST = " MULTIPLE_EXPORT_REQUEST";
export const MULTIPLE_EXPORT_SUCCESS = " MULTIPLE_EXPORT_SUCCESS";
export const MULTIPLE_EXPORT_ERROR = " MULTIPLE_EXPORT_ERROR";

//BATCH STATEMENT EMAILING
export const MULTIPLE_STATEMENT_EXPORT_REQUEST = "MULTIPLE_STATEMENT_EXPORT_REQUEST"
export const MULTIPLE_STATEMENT_EXPORT_SUCCESS = "MULTIPLE_STATEMENT_EXPORT_SUCCESS"
export const MULTIPLE_STATEMENT_EXPORT_ERROR = "MULTIPLE_STATEMENT_EXPORT_ERROR"

// GET Client Statement

export const GET_CLIENT_STATEMENT_REQUEST = "GET_CLIENT_STATEMENT_REQUEST";
export const GET_CLIENT_STATEMENT_SUCCESS = "GET_CLIENT_STATEMENT_SUCCESS";
export const GET_CLIENT_STATEMENT_ERROR = "GET_CLIENT_STATEMENT_ERROR";

// SEND REPORTS

export const SEND_REPORT_REQUEST = "SEND_REPORT_REQUEST";
export const SEND_REPORT_SUCCESS = "SEND_REPORT_SUCCESS";
export const SEND_REPORT_ERROR = "SEND_REPORT_ERROR";

// SUBSCRIPTION
export const BUY_SUBSCRIPTION_REQUEST = "BUY_SUBSCRIPTION_REQUEST";
export const BUY_SUBSCRIPTION_SUCCESS = "BUY_SUBSCRIPTION_SUCCESS";
export const BUY_SUBSCRIPTION_ERROR = "BUY_SUBSCRIPTION_ERROR";
export const GET_SUBSCRIPTION_REQUEST = "GET_SUBSCRIPTION_REQUEST";
export const GET_SUBSCRIPTION_SUCCESS = "GET_SUBSCRIPTION_SUCCESS";
export const GET_SUBSCRIPTION_ERROR = "GET_SUBSCRIPTION_ERROR";
export const SET_SUBSCRIPTION = "SET_SUBSCRIPTION";
export const CANCEL_SUBSCRIPTION_REQUEST = "CANCEL_SUBSCRIPTION_REQUEST";
export const CANCEL_SUBSCRIPTION_SUCCESS = "CANCEL_SUBSCRIPTION_SUCCESS";
export const CANCEL_SUBSCRIPTION_ERROR = "CANCEL_SUBSCRIPTION_ERROR";
//GET AR ANALYSIS

export const GET_AR_ANALYSIS_REQUEST = "GET_AR_ANALYSIS_REQUEST";
export const GET_AR_ANALYSIS_SUCCESS = "GET_AR_ANALYSIS_SUCCESS";
export const GET_AR_ANALYSIS_ERROR = "GET_AR_ANALYSIS_ERROR";

//GET AR ANALYSIS

export const GET_AGING_REPORT_REQUEST = "GET_AGING_REPORT_REQUEST";
export const GET_AGING_REPORT_SUCCESS = "GET_AGING_REPORT_SUCCESS";
export const GET_AGING_REPORT_ERROR = "GET_AGING_REPORT_ERROR";

//Chart of Accounts

export const CHART_OF_ACCOUNTS_REQUEST = "CHART_OF_ACCOUNTS_REQUEST";
export const CHART_OF_ACCOUNTS_SUCCESS = "CHART_OF_ACCOUNTS_SUCCESS";
export const CHART_OF_ACCOUNTS_ERROR = "CHART_OF_ACCOUNTS_ERROR";
// AR SEARCH TOOLS

export const AR_SEARCH_TOOL_REPORT_REQUEST = "AR_SEARCH_TOOL_REPORT_REQUEST";
export const AR_SEARCH_TOOL_REPORT_SUCCESS = "AR_SEARCH_TOOL_REPORT_SUCCESS";
export const AR_SEARCH_TOOL_REPORT_ERROR = "AR_SEARCH_TOOL_REPORT_ERROR";

// ALL SUBSCRIPTIONS

export const ALL_SUBSCRIPTION_REQUEST = "ALL_SUBSCRIPTION_REQUEST";
export const ALL_SUBSCRIPTION_SUCCESS = "ALL_SUBSCRIPTION_SUCCESS";
export const ALL_SUBSCRIPTION_ERROR = "ALL_SUBSCRIPTION_ERROR";
// TRIAL BALANCE SUMMARY

export const TRIAL_BALANCE_SUMMARY_REQUEST = "TRIAL_BALANCE_SUMMARY_REQUEST";
export const TRIAL_BALANCE_SUMMARY_SUCCESS = "TRIAL_BALANCE_SUMMARY_SUCCESS";
export const TRIAL_BALANCE_SUMMARY_ERROR = "TRIAL_BALANCE_SUMMARY_ERROR";

//Customer Balance Summary
export const CUSTOMER_BALANCE_SUMMARY_REQUEST =
  "CUSTOMER_BALANCE_SUMMARY_REQUEST";
export const CUSTOMER_BALANCE_SUMMARY_SUCCESS =
  "CUSTOMER_BALANCE_SUMMARY_SUCCESS";
export const CUSTOMER_BALANCE_SUMMARY_ERROR = "CUSTOMER_BALANCE_SUMMARY_ERROR";

//Customer Balance Detail
export const CUSTOMER_BALANCE_DETAIL_REQUEST =
  "CUSTOMER_BALANCE_DETAIL_REQUEST";
export const CUSTOMER_BALANCE_DETAIL_SUCCESS =
  "CUSTOMER_BALANCE_DETAIL_SUCCESS";
export const CUSTOMER_BALANCE_DETAIL_ERROR = "CUSTOMER_BALANCE_DETAIL_ERROR";

//Accounts Receivables Graph
export const ACCOUNTS_RECEIVABLE_GRAPH_REQUEST =
  "ACCOUNTS_RECEIVABLE_GRAPH_REQUEST";
export const ACCOUNTS_RECEIVABLE_GRAPH_SUCCESS =
  "ACCOUNTS_RECEIVABLE_GRAPH_SUCCESS";
export const ACCOUNTS_RECEIVABLE_GRAPH_ERROR =
  "ACCOUNTS_RECEIVABLE_GRAPH_ERROR";


//Check Subscriptions 
export const CHECK_SUBSCRIPTION_REQUEST = "CHECK_SUBSCRIPTION-REQUEST"
export const CHECK_SUBSCRIPTION_SUCCESS = "CHECK_SUBSCRIPTION_SUCCESS"
export const CHECK_SUBSCRIPTION_ERROR = "CHECK_SUBSCRIPTION_ERROR"

//Vendors Summary Report
export const VENDORS_SUMMARY_REPORT_REQUEST = "VENDORS_SUMMARY_REPORT_REQUEST";
export const VENDORS_SUMMARY_REPORT_SUCCESS = "VENDORS_SUMMARY_REPORT_SUCCESS";
export const VENDORS_SUMMARY_REPORT_ERROR = "VENDORS_SUMMARY_REPORT_ERROR";

//Credit Card Report Detail
export const CREDIT_CARD_DETAIL_REPORT_REQUEST =
  "CREDIT_CARD_DETAIL_REPORT_REQUEST";
export const CREDIT_CARD_DETAIL_REPORT_SUCCESS =
  "CREDIT_CARD_DETAIL_REPORT_SUCCESS";
export const CREDIT_CARD_DETAIL_REPORT_ERROR =
  "CREDIT_CARD_DETAIL_REPORT_ERROR";

//Lawyers Rank Report
export const LAWYERS_RANK_REPORT_REQUEST = "LAWYERS_RANK_REPORT_REQUEST";
export const LAWYERS_RANK_REPORT_SUCCESS = "LAWYERS_RANK_REPORT_SUCCESS";
export const LAWYERS_RANK_REPORT_ERROR = "LAWYERS_RANK_REPORT_ERROR";

//Lawyers Rank Report
export const JOB_RANK_REPORT_REQUEST = "JOB_RANK_REPORT_REQUEST";
export const JOB_RANK_REPORT_SUCCESS = "JOB_RANK_REPORT_SUCCESS";
export const JOB_RANK_REPORT_ERROR = "JOB_RANK_REPORT_ERROR";

//Customer Credit Balance Report
export const CUSTOMER_CREDIT_BALANCE_REPORT_REQUEST =
  "CUSTOMER_CREDIT_BALANCE_REPORT_REQUEST";
export const CUSTOMER_CREDIT_BALANCE_REPORT_SUCCESS =
  "CUSTOMER_CREDIT_BALANCE_REPORT_SUCCESS";
export const CUSTOMER_CREDIT_BALANCE_REPORT_ERROR =
  "CUSTOMER_CREDIT_BALANCE_REPORT_ERROR";

//AR income metrics
export const AR_INCOME_METRICS_REQUEST = "AR_INCOME_METRICS_REQUEST";
export const AR_INCOME_METRICS_SUCCESS = "AR_INCOME_METRICS_SUCCESS";
export const AR_INCOME_METRICS_ERROR = "AR_INCOME_METRICS_ERROR";

//Summary report
export const SUMMARY_REPORT_REQUEST = "SUMMARY_REPORT_REQUEST";
export const SUMMARY_REPORT_SUCCESS = "SUMMARY_REPORT_SUCCESS";
export const SUMMARY_REPORT_ERROR = "SUMMARY_REPORT_ERROR";

//Stripe Integration
export const STRIPE_ACCOUNT_REQUEST = "STRIPE_ACCOUNT_REQUEST";
export const STRIPE_ACCOUNT_SUCCESS = "STRIPE_ACCOUNT_SUCCESS";
export const STRIPE_ACCOUNT_ERROR = "STRIPE_ACCOUNT_ERROR";

export const GET_ALL_ACCOUNT_REQ = "GET_ALL_ACCOUNT_REQ";
export const GET_ALL_ACCOUNT_SUCCESS = "GET_ALL_ACCOUNT_SUCCESS";
export const GET_ALL_ACCOUNT_ERROR = "GET_ALL_ACCOUNT_ERROR";

export const LINKED_ACCOUNT_REQUEST = "LINKED_ACCOUNT_REQUEST";
export const LINKED_ACCOUNT_SUCCESS = "LINKED_ACCOUNT_SUCCESS";
export const LINKED_ACCOUNT_ERROR = "LINKED_ACCOUNT_ERROR";

export const DELETE_ACCOUNT_SUCCESS = "DELETE_ACCOUNT_SUCCESS";
export const DELETE_ACCOUNT_REQUEST = "DELETE_ACCOUNT_REQUEST";
export const DELETE_ACCOUNT_ERROR = "DELETE_ACCOUNT_ERROR";

export const CHECK_ACCOUNT_REQ = "CHECK_ACCOUNT_REQ";
export const CHECK_ACCOUNT_SUCCESS = "CHECK_ACCOUNT_SUCCESS";
export const CHECK_ACCOUNT_ERROR = "CHECK_ACCOUNT_ERROR";

export const ACTIVE_ACCOUNT_SUCCESS = "ACTIVE_ACCOUNT_SUCCESS";
export const ACTIVE_ACCOUNT_REQUEST = "ACTIVE_ACCOUNT_REQUEST";
export const ACTIVE_ACCOUNT_ERROR = "ACTIVE_ACCOUNT_ERROR";

export const CREATE_PAYMENT_REQUEST = "CREATE_PAYMENT_REQUEST";
export const CREATE_PAYMENT_SUCCESS= "CREATE_PAYMENT_SUCCESS";
export const CREATE_PAYMENT_ERROR = "CREATE_PAYMENT_ERROR";

export const RECORD_INVOICE_SUC = "RECORD_INVOICE_SUC";
export const RECORD_INVOICE_REQ = "RECORD_INVOICE_REQ";
export const RECORD_INVOICE_ERR = "RECORD_INVOICE_ERR";

export const CHECK_TOKEN_REQ='CHECK_TOKEN_REQ'
export const CHECK_TOKEN_SUCCESS='CHECK_TOKEN_SUCCESS'
export const CHECK_TOKEN_ERROR='CHECK_TOKEN_ERROR'
