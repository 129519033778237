import {
  GET_TRANSACTION_INVOICES,
  GET_TRANSACTION_INVOICES_SUCCESS,
  GET_TRANSACTION_INVOICES_ERROR,
  CREATE_INVOICE,
  CREATE_INVOICE_SUCCESS,
  CREATE_INVOICE_ERROR,
  GET_OPEN_INVOICES_REQUEST,
  GET_OPEN_INVOICES_SUCCESS,
  GET_OPEN_TRANSACTIONS_ERROR,
  PRINT_INVOICE_REQUEST,
  PRINT_INVOICE_SUCCESS,
  PRINT_INVOICE_ERROR,
  DELETE_INVOICE_REQUEST,
  DELETE_INVOICE_SUCCESS,
  DELETE_INVOICE_ERROR,
  RECORD_INVOICE_REQUEST,
  RECORD_INVOICE_SUCCESS,
  RECORD_INVOICE_ERROR,
  GET_CLIENT_STATEMENT_REQUEST,
  GET_CLIENT_STATEMENT_SUCCESS,
  GET_CLIENT_STATEMENT_ERROR,
  RECORD_PAYMENT_REQUEST,
  RECORD_PAYMENT_SUCCESS,
  RECORD_PAYMENT_ERROR,
  MULTIPLE_EXPORT_REQUEST,
  MULTIPLE_EXPORT_SUCCESS,
  MULTIPLE_EXPORT_ERROR,
  MULTIPLE_STATEMENT_EXPORT_ERROR,
  MULTIPLE_STATEMENT_EXPORT_REQUEST,
  MULTIPLE_STATEMENT_EXPORT_SUCCESS
} from "configurations/Types";

// actions for get transactions
export const getTransactionInvoicesRequest = (params) => {
  console.log('it rannnnn: ayyy', params)
  return {
    type: GET_TRANSACTION_INVOICES,
    params,
  };
};
export const getTransactionInvoicesSuccess = (payload) => {
  console.log('SUCCESS PAYLOAD: ', payload)
  return {
    type: GET_TRANSACTION_INVOICES_SUCCESS,
    payload,
  };
};
export const getTransactionInvoicesError = (error) => {
  return {
    type: GET_TRANSACTION_INVOICES_ERROR,
    error,
  };
};

export const createInvoice = (body) => {
  console.log('SOMEEEE: ', body)
  return {
    type: CREATE_INVOICE,
    body,
  };
};
export const createInvoiceSuccess = (payload) => {
  return {
    type: CREATE_INVOICE_SUCCESS,
    payload,
  };
};
export const createInvoiceError = (error) => {
  return {
    type: CREATE_INVOICE_ERROR,
    error,
  };
};

export const getOpenInvoicesRequest = (params) => {
  return {

    type: GET_OPEN_INVOICES_REQUEST,
    params
  }
}

export const getOpenInvoicesSuccess = (payload) => {
  return {

    type: GET_OPEN_INVOICES_SUCCESS,
    payload
  }
}

export const getOpenInvoicesError = (error) => {
  return {
    type: GET_OPEN_TRANSACTIONS_ERROR,
    error
  }
}



export const printInvoicesRequest = (params, cb) => {
  return {
    type: PRINT_INVOICE_REQUEST,
    params,
    cb
  }
}

export const printInvoicesSuccess = (payload) => {
  return {
    type: PRINT_INVOICE_SUCCESS,
    payload
  }
}

export const printInvoicesError = (error) => {
  return {
    type: PRINT_INVOICE_ERROR,
    error
  }
}

export const deleteInvoicesRequest = (params, cb) => {
  return {
    type: DELETE_INVOICE_REQUEST,
    params,
    cb
  }
}

export const deleteInvoicesSuccess = (payload) => {
  return {
    type: DELETE_INVOICE_SUCCESS,
    payload
  }
}

export const deleteInvoicesError = (error) => {
  return {
    type: DELETE_INVOICE_ERROR,
    error
  }
}


export const recordInvoicesRequest = (params, cb) => {
  return {
    type: RECORD_INVOICE_REQUEST,
    params,
    cb
  }
}

export const recordInvoicesSuccess = (payload) => {
  return {
    type: RECORD_INVOICE_SUCCESS,
    payload
  }
}

export const recordInvoicesError = (error) => {
  return {
    type: RECORD_INVOICE_ERROR,
    error
  }
}
export const recordPaymentRequest = (params, cb) => {
  return {
    type: RECORD_PAYMENT_REQUEST,
    params,
    cb
  }
}

export const recordPaymentSuccess = (payload) => {
  return {
    type: RECORD_PAYMENT_SUCCESS,
    payload
  }
}

export const recordPaymentError = (error) => {
  return {
    type: RECORD_PAYMENT_ERROR,
    error
  }
}
export const multipleExportRequest = (params, cb) => {
  return {
    type: MULTIPLE_EXPORT_REQUEST,
    params,
    cb
  }
}

export const multipleExportSuccess = (payload) => {
  return {
    type: MULTIPLE_EXPORT_SUCCESS,
    payload
  }
}

export const multipleExportError = (error) => {
  return {
    type: MULTIPLE_EXPORT_ERROR,
    error
  }
}

export const multipleStatementExportRequest = (params, cb) => {
  return {
    type: MULTIPLE_STATEMENT_EXPORT_REQUEST,
    params,
    cb
  }
}

export const multipleStatementExportSuccess = (payload) => {
  return {
    type: MULTIPLE_STATEMENT_EXPORT_SUCCESS,
    payload
  }
}

export const multipleStatementExportError = (error) => {
  return {
    type: MULTIPLE_STATEMENT_EXPORT_ERROR,
    error
  }
}


export const getClientStatementSuccess=(payload)=>{
  return {
    type:GET_CLIENT_STATEMENT_SUCCESS,
    payload
  }
}


export const getClientStatementRequest=(params,cb)=>{
  console.log("CLIENT STATMENT ACCTIONN",params)
  return {
    type:GET_CLIENT_STATEMENT_REQUEST,
    params,
    cb
  }
}

export const getClientStatementError=(error)=>{
  return {
    type:GET_CLIENT_STATEMENT_ERROR,
    error
  }
}