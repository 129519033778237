import {
  EDIT_ORGANIZATION_PROFILE_REQUEST,
  EDIT_ORGANIZATION_PROFILE_SUCCESS,
  EDIT_ORGANIZATION_PROFILE_ERROR,
  GET_ORGANIZATION_PROFILE_REQUEST,
  GET_ORGANIZATION_PROFILE_SUCCESS,
  GET_ORGANIZATION_PROFILE_ERROR,
  UPDATE_PASSWORD_REQUEST,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_ERROR,
  UPDATE_SETTINGS_REQUEST,
  UPDATE_SETTINGS_SUCCESS,
  UPDATE_SETTINGS_ERROR,
  GET_SETTINGS_REQUEST,
  GET_SETTINGS_SUCCESS,
  GET_SETTINGS_ERROR,
  ASSIGN_USER_ROLE_REQUEST,
  ASSIGN_USER_ROLE_SUCCESS,
  ASSIGN_USER_ROLE_ERROR,
  FETCH_ASSIGN_USER_ROLE_REQUEST,
  FETCH_ASSIGN_USER_ROLE_SUCCESS,
  FETCH_ASSIGN_USER_ROLE_ERROR,
  FETCH_UNASSIGNED_USER_ROLE_ERROR,
  FETCH_UNASSIGNED_USER_ROLE_SUCCESS,
  FETCH_UNASSIGNED_USER_ROLE_REQUEST,
  DELETE_ASSIGN_USER_ROLE_REQUEST,
  DELETE_ASSIGN_USER_ROLE_SUCCESS,
  DELETE_ASSIGN_USER_ROLE_ERROR,
  EDIT_ASSIGN_USER_ROLE_REQUEST,
  EDIT_ASSIGN_USER_ROLE_SUCCESS,
  EDIT_ASSIGN_USER_ROLE_ERROR,
  SEARCH_ASSIGN_USER_ROLE_REQUEST,
  SEARCH_ASSIGN_USER_ROLE_SUCCESS,
  SEARCH_UNASSIGNED_USER_ROLE_SUCCESS,
  SEARCH_UNASSIGNED_USER_ROLE_REQUEST,
  CLEAR_ASSIGN_USER_TYPE_SEARCH,
  CLEAR_UNASSIGNED_USER_TYPE_SEARCH
} from "configurations/Types";

// actions for edit organization profile
export const editOrganizationProfileRequest = (id, body, cb) => {
  return {
    type: EDIT_ORGANIZATION_PROFILE_REQUEST,
    id,
    body,
    cb,
  };
};

export const editOrganizationProfileSuccess = () => {
  return {
    type: EDIT_ORGANIZATION_PROFILE_SUCCESS,
  };
};

export const editOrganizationProfileError = (error) => {
  return {
    type: EDIT_ORGANIZATION_PROFILE_ERROR,
    error,
  };
};

// actions to get organization profile

export const getOrganizationProfileRequest = () => {
  return {
    type: GET_ORGANIZATION_PROFILE_REQUEST,
  };
};

export const getOrganizationProfileSuccess = (payload) => {
  return {
    type: GET_ORGANIZATION_PROFILE_SUCCESS,
    payload,
  };
};

export const getOrganizationProfileError = (error) => {
  return {
    type: GET_ORGANIZATION_PROFILE_ERROR,
    error,
  };
};

// actions to Update password

export const updatePasswordRequest = (body, cb) => {
  return {
    type: UPDATE_PASSWORD_REQUEST,
    cb,
    body,
  };
};

export const updatePasswordSuccess = (payload) => {
  return {
    type: UPDATE_PASSWORD_SUCCESS,
    payload,
  };
};

export const updatePasswordError = (error) => {
  return {
    type: UPDATE_PASSWORD_ERROR,
    error,
  };
};

// actions to Get settings

export const getSettingsRequest = () => {
  return {
    type: GET_SETTINGS_REQUEST,
  };
};

export const getSettingsSuccess = (payload) => {
  return {
    type: GET_SETTINGS_SUCCESS,
    payload,
  };
};

export const getSettingsError = (error) => {
  return {
    type: GET_SETTINGS_ERROR,
    error,
  };
};

// actions to Update settings

export const updateSettingsRequest = (id, body, cb) => {
  return {
    type: UPDATE_SETTINGS_REQUEST,
    cb,
    id,
    body,
  };
};

export const updateSettingsSuccess = (payload) => {
  return {
    type: UPDATE_SETTINGS_SUCCESS,
    payload,
  };
};

export const updateSettingsError = (error) => {
  return {
    type: UPDATE_SETTINGS_ERROR,
    error,
  };
};

// actions to assign Role

export const assignUserRoleRequest = (body, cb) => {
  return {
    type: ASSIGN_USER_ROLE_REQUEST,
    cb,
    body,
  };
};

export const assignUserRoleSuccess = () => {
  return {
    type: ASSIGN_USER_ROLE_SUCCESS,
  };
};

export const assignUserRoleError = (error) => {
  return {
    type: ASSIGN_USER_ROLE_ERROR,
    error,
  };
};

// actions to fetch assign User Role

export const fetchAssignUserRoleRequest = (params, cb) => {
  return {
    type: FETCH_ASSIGN_USER_ROLE_REQUEST,
    params,
    cb,
  };
};

export const fetchAssignUserRoleSuccess = (body) => {
  return {
    type: FETCH_ASSIGN_USER_ROLE_SUCCESS,
    body,
  };
};

export const fetchAssignUserRoleError = (error) => {
  return {
    type: FETCH_ASSIGN_USER_ROLE_ERROR,
    error,
  };
};

export const fetchUnAssignedUserRoleRequest = (params, cb) => {
  console.log("LMAO IM RUNNING")
  return {
    type: FETCH_UNASSIGNED_USER_ROLE_REQUEST,
    params,
    cb,
  };
};

export const fetchUnAssignedUserRoleSuccess = (body) => {
  return {
    type: FETCH_UNASSIGNED_USER_ROLE_SUCCESS,
    body,
  };
};

export const fetchUnAssignedUserRoleError = (error) => {
  return {
    type: FETCH_UNASSIGNED_USER_ROLE_ERROR,
    error,
  };
};


// actions to Edit assign User Role

export const editAssignUserRoleRequest = (body, cb) => {
  return {
    type: EDIT_ASSIGN_USER_ROLE_REQUEST,
    body,
    cb,
  };
};

export const editAssignUserRoleSuccess = () => {
  return {
    type: EDIT_ASSIGN_USER_ROLE_SUCCESS,
  };
};

export const editAssignUserRoleError = (error) => {
  return {
    type: EDIT_ASSIGN_USER_ROLE_ERROR,
    error,
  };
};

// actions to Delete assign User Role

export const deleteAssignUserRoleRequest = (body,cb) => {
  console.log('DELETE ACTION dasdasdasd: ', body,cb)
  return {
    type: DELETE_ASSIGN_USER_ROLE_REQUEST,
    body,
    cb
  };
};

export const deleteAssignUserRoleSuccess = () => {
  return {
    type: DELETE_ASSIGN_USER_ROLE_SUCCESS,
  };
};

export const deleteAssignUserRoleError = (error) => {
  return {
    type: DELETE_ASSIGN_USER_ROLE_ERROR,
    error,
  };
};

// actions to Search assign User Role

export function searchAssignUserRoleRequest(search) {
  return {
    type: SEARCH_ASSIGN_USER_ROLE_REQUEST,
    search,
  };
}

export function searchAssignUserRoleSuccess() {
  return {
    type: SEARCH_ASSIGN_USER_ROLE_SUCCESS,
  };
}

export function clearAssignUserTypeSearch() {
  return {
    type: CLEAR_ASSIGN_USER_TYPE_SEARCH,
  };
}







export function searchUnAssignedUserRoleRequest(search) {
  return {
    type: SEARCH_UNASSIGNED_USER_ROLE_REQUEST,
    search,
  };
}

export function searchUnAssignedUserRoleSuccess() {
  return {
    type: SEARCH_UNASSIGNED_USER_ROLE_SUCCESS,
  };
}

export function clearUnAssignedUserTypeSearch() {
  return {
    type: CLEAR_UNASSIGNED_USER_TYPE_SEARCH,
  };
}

