import React, { useEffect, useState } from "react";
import { Row, Col, Radio } from "antd";
import RadioWrapper from "../../../components/radio/Radio";
import InputWrapper from "../../../components/input/Number";
import Button from "components/button/";
import Logo from "../../../assets/timetrakker/images/Logo.png";
import Image from "../../../components/image";
import StripeCheckout from "react-stripe-checkout";
import { useDispatch, useSelector } from "react-redux";
import { recordInvoiceReq, checkTokenReq, createPaymentReq } from "../../../actions/Stripe";
import { Redirect } from "react-router-dom";
import Loader from "components/loader";
import ListWrapper from "components/list/bankAccountlist";
export default function Payments(props) {
  const [payments, setPayments] = useState("");
  const [toggleInput, setToggleInput] = useState(false);
  const [partialAmount, setPartialAmount] = useState(0);
  const [error, setError] = useState("");
  let str = props.location.search;
  let searchString = props.location.search;
  const isLoading = useSelector((store) => store.StripeReducer.isLoading);
  const isPaid = useSelector((store) => store.StripeReducer.isPaid);
  const abc = Object.fromEntries(new URLSearchParams(searchString));
  const paramsObj = {
    token: abc.token,
    invoice_id: atob(abc.invoice_id),
  };

  const dispatch = useDispatch();
  const handelChange = (e) => {
    setError("");
    setToggleInput(!toggleInput);
    setPayments(e.target.value);
  };
  useEffect(() => {
    dispatch(checkTokenReq(paramsObj));
  }, []);


  if (!str) {
    return <Redirect to="/" />;
  }
  let obj = Object.fromEntries(new URLSearchParams(str));
  const data = Object.keys(obj).map((item) => {
    try {
      return { [item]: atob(obj[item]) };
    } catch (e) {
      console.log("eee", e);
    }
  });
  const searchObj = Object.assign({}, ...data);
  const { balance, invoice_id, user_id, connect_account_id } = searchObj;
  const onSubmit = () => {
    if (payments === "partialAmount" && partialAmount >= searchObj.balance) {
      setError("Amount must be less than full amount");
      return;
    }
  };

  async function handleToken(token) {
    const { card, email, id } = token;
    let last4 = card.last4;
    const pay = payments == "fullAmount" ? "paid in full" : "partial payment";
    const bal = payments === "fullAmount" ? balance : partialAmount;
    const payload = {
      card_token: id,
      email: email,
      amount: payments === "fullAmount" ? balance : partialAmount,
      user_id: user_id,
      connect_account_id
    }
    dispatch(createPaymentReq(payload))
    dispatch(
      recordInvoiceReq(invoice_id, last4, bal, pay, user_id, connect_account_id, () =>
        dispatch(checkTokenReq(paramsObj))
      )
    );
  }

  return (
    <Row
      justify="start"
      style={{ marginTop: "30px", marginLeft: "5rem" }}
      gutter={[0, 20]}
    >
      <Col span={21}>
        <Image src={Logo} alt="Check" width={"300px"} />
      </Col>
      {!isLoading ? (
        <>
          {isPaid && !isLoading && (
            <Col xs={22} sm={22} md={12} lg={12} xl={12}>
              <ListWrapper
                cardStyle={{
                  border: "1px solid #58BA27",
                }}
                textStyle={{
                  fontSize: "30px",
                  fontWeight: "500",
                }}
                // bankName={`Payment has been made successfully.`}
                isBankAccount={true}
                isPayment={true}
              />
            </Col>
          )}

          {!isPaid && !isLoading && (
            <>
              <Col span={21}>
                <Row gutter={[0, 30]}>
                  <Col span={24}>
                    <Radio.Group onChange={handelChange} value={payments}>
                      <Row gutter={[80, 0]}>
                        <Col xs={24} sm={24} md={12}>
                          <RadioWrapper value="fullAmount">
                            Pay Full Amount
                          </RadioWrapper>
                        </Col>
                        <Col xs={24} sm={24} md={12}>
                          <RadioWrapper value="partialAmount">
                            Pay Partial Amount
                          </RadioWrapper>
                        </Col>
                      </Row>
                    </Radio.Group>
                  </Col>
                </Row>
                <Col xs={20} sm={10} md={10} xl={7} lg={10}>
                  <Row justify="center">
                    <Col xs={24} sm={24} md={24} xl={20} lg={16}>
                      {payments === "partialAmount" && (
                        <InputWrapper
                          style={{
                            border: "1px  #F3F3F3",
                            backgroundColor: "#F3F3F3",
                            padding: "10px",
                            borderRadius: "10px",
                          }}
                          type="number"
                          placeholder="Enter Amount"
                          onChange={(e) => {
                            console.log("InputValue===>", e);
                            if (e < balance && e > 0) {
                              setPartialAmount(e);
                              setError("");
                            } else {
                              setError("Amount must be less than full amount");
                            }
                          }}
                          name={"partialAmount"}
                        />
                      )}
                    </Col>
                  </Row>
                </Col>
                <Col xs={15} sm={10} md={8} xl={7} lg={8}>
                  <Row justify="center">
                    <Col xs={24} sm={24} md={24} xl={20} lg={16}>
                      {" "}
                      <span style={{ color: "red", textAlign: "center" }}>
                        {error && error}
                      </span>
                    </Col>
                  </Row>
                </Col>
              </Col>
              <Col span={14}>
                <Row justify="center">
                  <Col span={18}>
                    <StripeCheckout
                      style={{
                        width: "40%",
                        background: "red",
                        color: "#AD1E23",
                        backgroundColor: "#58BA27",
                        backgroundImage: null,
                      }}
                      textStyle={{ color: "white", backgroundImage: "" }}
                      amount={
                        payments === "partialAmount"
                          ? partialAmount * 100
                          : balance * 100
                      }
                      stripeKey="pk_live_51Ky9LuEbq37F03wmv9VFp4EIAXSfI1svfA89oR35Be1RYdnP3xvZ0ztxllZHINPRSMVF8o01ILriE0iNUf93i2Qj00fu0UQWRM"
                      // stripeKey="pk_test_51Ky9LuEbq37F03wm8Wtb8QqSjKSk6UHTHAEEpcKy1vzXvnV5saqVQ3fhwvYYmy65NGJ7bsx7d24CyTpFbVvWpy1S00xSbdW4K3"
                      // stripeKey="pk_test_51KxWesJfLkdEma6mAzhpZoQimylINXLMLUdSFXwXdTMCCPTYQWJDMst2Dc8JnQRZXa1WqLsFdL6j4A2oJuhpkypL00rSQOE53u"
                      token={handleToken}
                      shippingAddress
                      billingAddress
                      triggerEvent="onClick"
                    >
                      <Button
                        className="mr-t-md mr-b-sm"
                        onClick={onSubmit}
                        disabled={
                          (payments === "partialAmount" &&
                            !Boolean(partialAmount)) ||
                          !payments ||
                          error.length
                        }
                      >
                        Continue
                      </Button>
                    </StripeCheckout>
                  </Col>
                </Row>
              </Col>
            </>
          )}
        </>
      ) : (
        <Col span={24}>
          <Loader margin="auto" size="2rem" />
        </Col>
      )}

      {/* <Col span={24} style={{ marginTop: "6px" }}>
        <Row justify="center"></Row>
      </Col> */}
    </Row>
  );
}
