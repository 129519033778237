import { resetHeaders, privateAgent } from "../configurations/AxiosAgent";
import { LOCAL_STORAGE_KEY } from "../configurations/Constants";
import { store } from "../ConfigureStore";
import { loginSuccess, logoutRequest } from "../actions/Auth";

// import { setPushToken } from "actions/App";

const login = (auth, cb, navigationCallback) => {
  //let auth = res.data.data;
  window.localStorage.setItem(
    LOCAL_STORAGE_KEY,
    JSON.stringify({ ...auth, access_token: auth.token })
  );

  privateAgent.defaults.headers = {
    ...privateAgent.defaults.headers,
    access_token: auth.token,
    user_id: auth.user_id,
  };

  // console.log("privateAgent.defaults.headers::",privateAgent.defaults.headers)
  if (cb) cb();
  store.dispatch(loginSuccess(auth));
  navigationCallback();
};

const logout = () => {
  // Clear token from firebase and backend before clearing localstorage
  // store.dispatch(setPushToken({ token: "", skipDatabaseSync: unauthenticated }));
  localStorage.removeItem(LOCAL_STORAGE_KEY);
  localStorage.removeItem("ExportReportformats");
  localStorage.clear();

  resetHeaders(privateAgent);
  store.dispatch(logoutRequest()); 
  window.location.reload()
    // window.location.href = "http://www.timetrakkerprime.com/";
  // setTimeout(()=>{
  //   console.log("clearing state...")
  //   store.dispatch(logoutRequest());
  // },3000)
};

const isLogin = () => {
  if (localStorage.getItem(LOCAL_STORAGE_KEY)) {
    //private agent headers set
    return true;
  }

  return false;
};

const redirect = (...params) => {
  //let history = store.getState().App.history;
  //console.log("HISTORY", history)
  //history.push(...params);
  // store.dispatch(redirect(url))
};

function urlToBase64(url, callback) {
  var xhr = new XMLHttpRequest();
  xhr.onload = function () {
    var reader = new FileReader();
    reader.onloadend = function () {
      callback(reader.result);
    };
    reader.readAsDataURL(xhr.response);
  };
  xhr.open("GET", url);
  xhr.responseType = "blob";
  xhr.send();
}

export { login, logout, isLogin, redirect, urlToBase64 };
