import { createAction } from "@reduxjs/toolkit";

export const uploadImages = createAction("UPLOAD_IMAGES", function prepare(cb, ...images) {
  return {
    payload: {
      cb,
      images
    }
  }
});


