import { put, takeLatest, takeEvery, cancelled } from "redux-saga/effects";
import { privateAgent, CancelToken } from "configurations/AxiosAgent";
import info from "components/message/index";
import moment from "moment";
import {
  TRANSACTIONS,
  GET_TRANSACTIONS_ENDPOINT,
  DEL_TRANSACTION_ENDPOINT,
  UPDATE_OPEN_TIME_TRANSACTION_ENDPOINT,
  ADD_OPEN_TIME_TRANSACTION_ENDPOINT,
  ADD_EXPENSE_TRANSACTION_ENDPOINT,
  EDIT_EXPENSE_TRANSACTION_ENDPOINT,
  LOCAL_STORAGE_KEY,
  ADD_FIXED_COST_TRANSACTION_ENDPOINT,
  EDIT_FIXED_COST_TRANSACTION_ENDPOINT,
} from "configurations/Constants";
import {
  GET_OPEN_TRANSACTIONS_REQUEST,
  GET_CLOSE_TRANSACTIONS_REQUEST,
  DEL_OPEN_TRANSACTION_REQUEST,
  UPDATE_OPEN_TIME_TRANSACTION_REQUEST,
  ADD_OPEN_TIME_TRANSACTION_REQUEST,
  ADD_EXPENSE_TRANSACTION_REQUEST,
  EDIT_EXPENSE_TRANSACTION_REQUEST,
  ADD_FIXED_COST_TRANSACTION_REQUEST,
  EDIT_FIXED_COST_TRANSACTION_REQUEST,
  GET_RECURRING_TRANSACTIONS_REQUEST
} from "configurations/Types";
import {
  getOpenTransRequest,
  getOpenTransSuccess,
  getOpenTransError,
  getRecurringTransSuccess,
  getRecurringTransError,
  getCloseTransRequest,
  getCloseTransSuccess,
  getCloseTransError,
  delOpenTransactionSuccess,
  delOpenTransactionError,
  addOpenTimeTransactionSuccess,
  addOpenTimeTransactionError,
  updateOpenTimeTransactionSuccess,
  updateOpenTimeTransactionError,
  addExpenseTransactionSuccess,
  addExpenseTransactionError,
  editExpenseTransactionSuccess,
  editExpenseTransactionError,
  addFixedCostTransactionSuccess,
  addFixedCostTransactionError,
  editFixedCostTransactionSuccess,
  editFixedCostTransactionError,
} from "actions/Transactions";
import { actions } from "react-table";

let openToken;
let closeToken;
let current_time = () => moment().format("YYYY-MM-DD HH:mm:ss");

// get transactions
const getTransactionsApi = (params, token) => {
  let role = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY)).role;
  let body = { ...params };
  // console.log(role,"role")
  if (role == "staff")
    body.created_by = JSON.parse(
      localStorage.getItem(LOCAL_STORAGE_KEY)
    ).user_id;

  return privateAgent.get(`${TRANSACTIONS}${GET_TRANSACTIONS_ENDPOINT}`, {
    cancelToken: token.token,
    params: { ...body },
  });
};
function* getOpenTransactions(action) {
  let res;
  try {
    if (openToken) {
      openToken.cancel();
    }
    openToken = CancelToken.source();
    res = yield getTransactionsApi(
      {
        limit: 1000,
        offset: 0,
        year: moment().year(),
        status: "open",
        ...action.params,
      },
      openToken
    );
    console.log("open trans: ", res.data);
    yield put(getOpenTransSuccess(res.data.data.transactions));
  } catch (e) {
    if (e.response?.data.error.message) {
      info("error", e.response?.data.error.message);
    }
    yield put(getOpenTransError(e.response));
  } finally {
    if (yield cancelled()) {
    }
  }
}
function* getRecurringTransactions(action) {
  let res;
  const {recurring, recurring_status} = action.params;
  try {
    if (openToken) {
      openToken.cancel();
    }
    openToken = CancelToken.source();
    res = yield getTransactionsApi(
      {
        limit: 1000,
        offset: 0,
        year: moment().year(),
        recurring,
        recurring_status,
        ...action.params,
      },
      openToken
    );
    console.log("open trans: ", res.data);
    yield put(getRecurringTransSuccess(res.data.data.transactions));
  } catch (e) {
    if (e.response?.data.error.message) {
      info("error", e.response?.data.error.message);
    }
    yield put(getRecurringTransError(e.response));
  } finally {
    if (yield cancelled()) {
    }
  }
}
function* getCloseTransactions(action) {
  let res;
  try {
    if (closeToken) {
      closeToken.cancel();
    }
    closeToken = CancelToken.source();
    res = yield getTransactionsApi(
      {
        limit: 1000,
        offset: 0,
        year: moment().year(),
        status: "close",
        ...action.params,
      },
      closeToken
    );
    console.log("close trans: ", res.data);
    yield put(getCloseTransSuccess(res.data.data.transactions));
  } catch (e) {
    if (e.response?.data.error.message) {
      info("error", e.response?.data.error.message);
    }
    yield put(getCloseTransError(e.response));
  } finally {
    if (yield cancelled()) {
    }
  }
}

const delTransactionApi = (id) =>
  privateAgent.delete(`${TRANSACTIONS}${DEL_TRANSACTION_ENDPOINT}/${id}`);

function* delOpenTransaction(action) {
  let res;
  console.log("CONNNN: ", action.cb);
  try {
    res = yield delTransactionApi(action.id);
    info("success", res.data.data.message);
    yield put(delOpenTransactionSuccess());
    yield put(getOpenTransRequest());
    yield put(getCloseTransRequest());
    // action.cb ? ()=>{action.cb()} : null
    if (action.cb) {
      action.cb();
    }
  } catch (e) {
    info("error", e.response?.data.error.message);
    yield put(delOpenTransactionError());
  } finally {
    if (yield cancelled()) {
    }
  }
}
const addOpenTimeTransactionAPI = (body) =>
  privateAgent.post(`${TRANSACTIONS}${ADD_OPEN_TIME_TRANSACTION_ENDPOINT}`, {
    ...body,
    current_time: current_time(),
  });

function* addOpenTimeTransaction(action) {
  const { body, cb } = action;
  let res;
  try {
    const {
      time_stamp,
      date_of_transaction,
      lawyer_name,
      customer_name,
      job_name,
      time_account_name,
      ...rest
    } = body;
    rest.date_of_transaction = `${moment(date_of_transaction).format(
      "YYYY-MM-DD"
    )} ${moment(time_stamp).format("HH:mm:ss")}`;

    const date = moment(date_of_transaction).format("YYYY-MM-DD");
    const time = moment(time_stamp).format("hh:mm:ss");

    let formatedHours = time.split(":")[0];
    const timeType = moment(time_stamp).format("hh:mm a").split(" ")[1];
    if (timeType === "pm")
      formatedHours =
        time.split(":")[0] === "12"
          ? "12"
          : String(Number(time.split(":")[0]) + 12);

    const TimeObj = new Date(
      new Date().setHours(formatedHours, time.split(":")[1], time.split(":")[2])
    );
    const time_in_mili = new Date(
      TimeObj.setFullYear(
        Number(date.split("-")[0]),
        Number(date.split("-")[1]) - 1,
        Number(date.split("-")[2])
      )
    );

    rest.time_in_mili = new Date(time_in_mili).getTime();
    rest.time_stamp = moment(time_stamp).format("hh:mm a");
    rest.rates=rest.rates =="0" ? "0.00" :rest.rates

    // let time_in_mili=moment(date_of_transaction);
    // time_in_mili.set("minute", moment(time_stamp).get("minute"))
    // time_in_mili.set("hour", moment(time_stamp).get("hour"))
    // time_in_mili.set("second", moment(time_stamp).get("second"))
    // rest.time_in_mili=time_in_mili.valueOf()

    res = yield addOpenTimeTransactionAPI(rest);

    info("success", res.data.data.message);
    yield put(addOpenTimeTransactionSuccess());
    cb && cb(res);
  } catch (e) {
    info("error", e.response?.data.error.message);
    yield put(addOpenTimeTransactionError());
  } finally {
    if (yield cancelled()) {
    }
  }
}

const updateOpenTimeTransactionAPI = (id, body) =>
  privateAgent.put(
    `${TRANSACTIONS}${UPDATE_OPEN_TIME_TRANSACTION_ENDPOINT}/${id}`,
    {
      ...body,
      current_time: current_time(),
    }
  );

function* updateOpenTimeTransaction(action) {
  const { id, body, cb } = action;
  let res;

  try {
    let { time_stamp, date_of_transaction, ...rest } = body;
  
    rest.rates =rest.rates =="0" ? "0.00" :rest.rates
    rest.calculated_amount =rest.calculated_amount =="0" ? "0.00" :rest.calculated_amount
    rest.time_stamp =
      moment(time_stamp).format("h:mm A") === "Invalid date"
        ? time_stamp
        : moment(time_stamp).format("h:mm A");
    rest.date_of_transaction = moment(date_of_transaction).format(
      "YYYY-MM-DD HH:mm:ss"
    );
    const date = moment(date_of_transaction).format("YYYY-MM-DD");
    const time = moment(time_stamp).format("hh:mm:ss");

    let formatedHours = time.split(":")[0];
    const timeType = moment(time_stamp).format("hh:mm a").split(" ")[1];
    if (timeType === "pm")
      formatedHours =
        time.split(":")[0] === "12"
          ? "12"
          : String(Number(time.split(":")[0]) + 12);

    const TimeObj = new Date(
      new Date().setHours(formatedHours, time.split(":")[1], time.split(":")[2])
    );
    const time_in_mili = new Date(
      TimeObj.setFullYear(
        Number(date.split("-")[0]),
        Number(date.split("-")[1]) - 1,
        Number(date.split("-")[2])
      )
    );

    rest.time_in_mili = new Date(time_in_mili).getTime();
    rest.rates = rest.rates =="0" ? "0.00" :rest.rates
    res = yield updateOpenTimeTransactionAPI(id, rest);
    info("success", res.data.data.message);
    yield put(updateOpenTimeTransactionSuccess());
    yield put(getOpenTransRequest());
    yield put(getCloseTransRequest());
    cb && cb();
  } catch (e) {

    info("error", e.response?.data.error.message);
    yield put(updateOpenTimeTransactionError());
  } finally {
    if (yield cancelled()) {
    }
  }
}

const addExpenseTransactionApi = (body) => {
  console.log("FORM DATA BODY: ", body);
  return privateAgent.post(
    `${TRANSACTIONS}${ADD_EXPENSE_TRANSACTION_ENDPOINT}`,
    body,
    {
      headers: {
        "Content-Type": "application/application/x-www-form-urlencoded",
      },
    }
  );
};

function* addExpenseTransaction(action) {
  const { body, cb } = action;
  const {
    time_stamp,
    date_of_transaction,
    lawyer_name,
    customer_name,
    job_name,
    expense_account_name,
    vendor_name,
    credit_card_name,
    ...rest
  } = body;
  rest.date_of_transaction = `${moment(date_of_transaction).format(
    "YYYY-MM-DD"
  )} ${moment(time_stamp).format("hh:mm:ss")}`;

  const date = moment(date_of_transaction).format("YYYY-MM-DD");
  const time = moment(time_stamp).format("hh:mm:ss");

  let formatedHours = time.split(":")[0];
  const timeType = moment(time_stamp).format("hh:mm a").split(" ")[1];
  if (timeType === "pm")
    formatedHours =
      time.split(":")[0] === "12"
        ? "12"
        : String(Number(time.split(":")[0]) + 12);

  const TimeObj = new Date(
    new Date().setHours(formatedHours, time.split(":")[1], time.split(":")[2])
  );
  const time_in_mili = new Date(
    TimeObj.setFullYear(
      Number(date.split("-")[0]),
      Number(date.split("-")[1]) - 1,
      Number(date.split("-")[2])
    )
  );

  rest.time_in_mili = new Date(time_in_mili).getTime();
  rest.time_stamp = moment(time_stamp).format("hh:mm a");
  rest.current_time = current_time();
  console.log("BODYYY: ", body);
  let formData = new FormData();
  for (const key in rest) {
    console.log("FOMR LOOPS: ", key);
    formData.append(key, rest[key]);
  }
  for (var pair of formData.entries()) {
    console.log("FORMDATA", pair[0] + ", " + pair[1]);
  }
  console.log("FORMMMMDATAAA: ", formData);
  let res;
  try {
    res = yield addExpenseTransactionApi(formData);
    info("success", res.data.data.message);
    yield put(addExpenseTransactionSuccess());
    yield put(getOpenTransRequest());
    yield put(getCloseTransRequest());
    cb && cb(res);
  } catch (e) {
    info("error", e.response?.data.error.message);
    yield put(addExpenseTransactionError());
  } finally {
    if (yield cancelled()) {
    }
  }
}

const editExpenseTransactionAPI = (id, body) =>
  privateAgent.post(
    `${TRANSACTIONS}${EDIT_EXPENSE_TRANSACTION_ENDPOINT}/${id}`,
    body,
    {
      headers: {
        "Content-Type": "application/application/x-www-form-urlencoded",
      },
    }
  );

function* editExpenseTransaction(action) {
  const { id, body, cb } = action;
  const {
    time_stamp,
    date_of_transaction,
    lawyer_name,
    customer_name,
    job_name,
    expense_account_name,
    vendor_name,
    credit_card_name,
    ...rest
  } = body;
  rest.date_of_transaction = moment(date_of_transaction).format(
    "YYYY-MM-DD HH:MM:SS"
  );
 
  const date = moment(date_of_transaction).format("YYYY-MM-DD");
  const time = moment(time_stamp).format("hh:mm:ss");

  let formatedHours = time.split(":")[0];
  const timeType = moment(time_stamp).format("hh:mm a").split(" ")[1];
  if (timeType === "pm")
    formatedHours =
      time.split(":")[0] === "12"
        ? "12"
        : String(Number(time.split(":")[0]) + 12);

  const TimeObj = new Date(
    new Date().setHours(formatedHours, time.split(":")[1], time.split(":")[2])
  );
  const time_in_mili = new Date(
    TimeObj.setFullYear(
      Number(date.split("-")[0]),
      Number(date.split("-")[1]) - 1,
      Number(date.split("-")[2])
    )
  );

  rest.time_in_mili = new Date(time_in_mili).getTime();

  rest.current_time = current_time();
  let formData = new FormData();
  for (const key in rest) {
    console.log("FOMR LOOPS: ", key);
    formData.append(key, rest[key]);
  }
  for (var pair of formData.entries()) {
    console.log("FORMDATA", pair[0] + ", " + pair[1]);
  }
  console.log("FORMMMMDATAAA: ", formData);
  console.log("time_in_mili: ", formData.get("time_in_mili"));
  let res;
  try {
    res = yield editExpenseTransactionAPI(id, formData);
    info("success", res.data.data.message);
    yield put(editExpenseTransactionSuccess());
    yield put(getOpenTransRequest());
    yield put(getCloseTransRequest());
    cb && cb();
  } catch (e) {
    info("error", e.response?.data.error.message);
    yield put(editExpenseTransactionError());
  } finally {
    if (yield cancelled()) {
    }
  }
}

const editFixedCostTransactionAPI = (id, body) =>
  privateAgent.post(
    `${TRANSACTIONS}${EDIT_FIXED_COST_TRANSACTION_ENDPOINT}/${id}`,
    body,
    {
      headers: {
        "Content-Type": "application/application/x-www-form-urlencoded",
      },
    }
  );

function* editFixedCostTransaction(action) {
  const { id, body, cb } = action;
  const {
    time_stamp,
    date_of_transaction,
    lawyer_name,
    customer_name,
    job_name,
    expense_account_name,
    vendor_name,
    credit_card_name,
    ...rest
  } = body;
  rest.date_of_transaction = moment(date_of_transaction).format(
    "YYYY-MM-DD HH:MM:SS"
  );
  const date = moment(date_of_transaction).format("YYYY-MM-DD");
  const time = moment(time_stamp).format("hh:mm:ss");

  let formatedHours = time.split(":")[0];
  const timeType = moment(time_stamp).format("hh:mm a").split(" ")[1];
  if (timeType === "pm")
    formatedHours =
      time.split(":")[0] === "12"
        ? "12"
        : String(Number(time.split(":")[0]) + 12);

  const TimeObj = new Date(
    new Date().setHours(formatedHours, time.split(":")[1], time.split(":")[2])
  );
  const time_in_mili = new Date(
    TimeObj.setFullYear(
      Number(date.split("-")[0]),
      Number(date.split("-")[1]) - 1,
      Number(date.split("-")[2])
    )
  );

  rest.time_in_mili = new Date(time_in_mili).getTime();
  rest.current_time = current_time();
  let formData = new FormData();
  for (const key in rest) {
    console.log("FOMR LOOPS: ", key);
    formData.append(key, rest[key]);
  }
  for (var pair of formData.entries()) {
    console.log("FORMDATA", pair[0] + ", " + pair[1]);
  }
  console.log("FORMMMMDATAAA: ", formData);
  let res;
  try {
    res = yield editFixedCostTransactionAPI(id, formData);
    info("success", res.data.data.message);
    yield put(editFixedCostTransactionSuccess());
    yield put(getOpenTransRequest());
    yield put(getCloseTransRequest());
    cb && cb();
  } catch (e) {
    info("error", e.response?.data.error.message);
    yield put(editFixedCostTransactionError());
  } finally {
    if (yield cancelled()) {
    }
  }
}

const addFixedCostTransactionApi = (body) => {
  console.log("FORM DATA BODY: ", body);
  return privateAgent.post(
    `${TRANSACTIONS}${ADD_FIXED_COST_TRANSACTION_ENDPOINT}`,
    body,
    {
      headers: {
        "Content-Type": "application/application/x-www-form-urlencoded",
      },
    }
  );
};

function* addFixedCostTransaction(action) {
  const { body, cb } = action;
  const {
    time_stamp,
    date_of_transaction,
    lawyer_name,
    customer_name,
    job_name,
    expense_account_name,
    vendor_name,
    credit_card_name,
    ...rest
  } = body;
  rest.date_of_transaction = `${moment(date_of_transaction).format(
    "YYYY-MM-DD"
  )} ${moment(time_stamp).format("hh:mm:ss")}`;

  const date = moment(date_of_transaction).format("YYYY-MM-DD");
  const time = moment(time_stamp).format("hh:mm:ss");

  let formatedHours = time.split(":")[0];
  const timeType = moment(time_stamp).format("hh:mm a").split(" ")[1];
  if (timeType === "pm")
    formatedHours =
      time.split(":")[0] === "12"
        ? "12"
        : String(Number(time.split(":")[0]) + 12);

  const TimeObj = new Date(
    new Date().setHours(formatedHours, time.split(":")[1], time.split(":")[2])
  );
  const time_in_mili = new Date(
    TimeObj.setFullYear(
      Number(date.split("-")[0]),
      Number(date.split("-")[1]) - 1,
      Number(date.split("-")[2])
    )
  );

  rest.time_in_mili = new Date(time_in_mili).getTime();
  rest.time_stamp = moment(time_stamp).format("hh:mm a");
  rest.current_time = current_time();
  console.log("BODYYY: ", body);
  let formData = new FormData();
  for (const key in rest) {
    console.log("FOMR LOOPS: ", key);
    formData.append(key, rest[key]);
  }
  for (var pair of formData.entries()) {
    console.log("FORMDATA", pair[0] + ", " + pair[1]);
  }
  console.log("FORMMMMDATAAA: ", formData);
  let res;
  try {
    res = yield addFixedCostTransactionApi(formData);
    info("success", res.data.data.message);
    yield put(addFixedCostTransactionSuccess());
    yield put(getOpenTransRequest());
    yield put(getCloseTransRequest());
    cb && cb(res);
  } catch (e) {
    info("error", e.response?.data.error.message);
    yield put(addFixedCostTransactionError());
  } finally {
    if (yield cancelled()) {
    }
  }
}

export function* WatchTransactionActions() {
  yield takeLatest(GET_OPEN_TRANSACTIONS_REQUEST, getOpenTransactions);
  yield takeLatest(GET_RECURRING_TRANSACTIONS_REQUEST, getRecurringTransactions);
  yield takeLatest(GET_CLOSE_TRANSACTIONS_REQUEST, getCloseTransactions);
  yield takeEvery(DEL_OPEN_TRANSACTION_REQUEST, delOpenTransaction);
  yield takeLatest(
    UPDATE_OPEN_TIME_TRANSACTION_REQUEST,
    updateOpenTimeTransaction
  );
  yield takeLatest(ADD_OPEN_TIME_TRANSACTION_REQUEST, addOpenTimeTransaction);
  yield takeLatest(ADD_EXPENSE_TRANSACTION_REQUEST, addExpenseTransaction);
  yield takeLatest(ADD_FIXED_COST_TRANSACTION_REQUEST, addFixedCostTransaction);
  yield takeLatest(EDIT_EXPENSE_TRANSACTION_REQUEST, editExpenseTransaction);
  yield takeLatest(
    EDIT_FIXED_COST_TRANSACTION_REQUEST,
    editFixedCostTransaction
  );
}
