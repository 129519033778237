import React from "react";
import styled, { css } from "styled-components";
//import {primaryColor} from "../configurations/theme"
import { Button } from "antd";
// import propTypes from "prop-types";

const StyledButton = styled(Button).attrs({ className: "button" })`
  color: ${(props) =>
    props.outlined
      ? props.theme[props.theme.mode].primary.main
      : props.simple
      ? props.theme[props.theme.mode].common.white
      : props.theme[props.theme.mode].common.white};
  background: ${(props) =>
    props.outlined
      ? props.theme[props.theme.mode].common.white
      : props.theme[props.theme.mode].primary.main};
  box-shadow: ${(props) =>
    props.type === "icon"
      ? "none"
      : props.outlined === "normal"
      ? `5px 0 20px ${props.theme[props.theme.mode].primary.light}14`
      : props.outlined
      ? `5px 0 20px ${props.theme[props.theme.mode].primary.main}0C`
      : `none`};
  border: ${(props) =>
    props.outlined
      ? `1px solid ${props.theme[props.theme.mode].primary.main}`
      : `1px solid transparent`};
  height: auto;
  font-weight: 700;

  line-height: 1.3;

  padding: calc(0.7vw)
    ${(props) => (!props.autoWidthSubmit && !props.block ? `0.8vw` : 0)};
  min-width: ${(props) =>
    !props.autoWidthSubmit && !props.block ? `8rem` : "auto"};
  ${(props) => (props.autoWidthSubmit ? `width: 50%;` : null)}
  outline: none;
  //border: none;
  border-radius: 5px;
  text-transform: "";

  ${(props) =>
    props.type === "icon"
      ? `
    border-radius: 50%;
    min-width: unset;
    min-height: unset;
    width: 2.8rem;
    height: 2.8rem;
  `
      : null}
  /* &.iconBtn {
    min-width: auto;
    padding: 1rem;
    font-size: ${(props) =>
    props.theme[props.theme.mode].textSizes.relative.subHeading};
    display: inline-flex;
    justify-content: center;
    border-radius: 50%;
    width: 3.5rem;
    height: 3.5rem;
  }
&.dangerBtn {
  color: ${(props) => props.theme[props.theme.mode].error.main};
  background: ${(props) => props.theme[props.theme.mode].common.white};
  //box-shadow: inset 0px 0px 0px 2px ${(props) =>
    props.theme[props.theme.mode].primary.main};
}
&.dangerBtn, &:hover, &.dangerBtn, &:active, &.dangerBtn, &:focus {
  color: ${(props) => props.theme[props.theme.mode].common.white};
  background: ${(props) => props.theme[props.theme.mode].error.main};
} */
  &:hover, &:active, &:focus {
    color: ${(props) =>
      props.outlined && props.color
        ? props.theme[props.theme.mode].card.colors[props.color]
        : props.outlined
        ? props.theme[props.theme.mode].primary.main
        : props.simple
        ? props.theme[props.theme.mode].common.white
        : props.theme[props.theme.mode].common.white};
    background: ${(props) =>
      props.danger
        ? props.theme[props.theme.mode].error.main
        : props.outlined
        ? props.theme[props.theme.mode].common.white
        : props.theme[props.theme.mode].primary.main};
    /* box-shadow: ${(props) =>
      props.outlined
        ? css`inset 0 0 0 2px ${(props) =>
            props.theme[props.theme.mode].primary.main}`
        : `none`}; */
    opacity: ${(props) => (props.outlined ? 1 : 0.75)};
    outline: none;
    border: ${(props) =>
      props.outlined
        ? `1px solid ${props.theme[props.theme.mode].primary.main}`
        : `1px solid transparent`};
  }
  @media screen and (max-width: 991px) {
    padding: calc(0.5rem) ${(props) => (!props.block ? "0.5rem" : 0)};
    //padding: .5rem 0;
    /* &.iconBtn {
      font-size: calc(${(props) =>
      props.theme[props.theme.mode].textSizes.relative.subHeading} - 4px);
    } */
    /* &:hover, &:active, &:focus {
      padding: .5rem ${(props) => (!props.block ? "4rem" : 0)};
    } */
  }
  /* @media screen and (max-width: 767px){
    &.iconBtn {
      font-size: ${(props) =>
    props.theme[props.theme.mode].textSizes.actual.subHeading};
    }
  } */
`;

const ButtonWrapper = (props) => {
  return <StyledButton {...props}></StyledButton>;
};

// ButtonWrapper.propTypes = {
//   children: propTypes.string.isRequired,
//   block: propTypes.bool.isRequired,
//   onClick: propTypes.func
// };

export default ButtonWrapper;
