import { put, takeLatest, cancelled } from "redux-saga/effects";

import {
  SEND_REPORT_REQUEST,
  GET_AR_ANALYSIS_REQUEST,
  GET_AGING_REPORT_REQUEST,
  CHART_OF_ACCOUNTS_REQUEST,
  AR_SEARCH_TOOL_REPORT_REQUEST,
  TRIAL_BALANCE_SUMMARY_REQUEST,
  TRIAL_BALANCE_SUMMARY_SUCCESS,
  TRIAL_BALANCE_SUMMARY_ERROR,
  CUSTOMER_BALANCE_SUMMARY_REQUEST,
  CUSTOMER_BALANCE_DETAIL_REQUEST,
  ACCOUNTS_RECEIVABLE_GRAPH_REQUEST,
  VENDORS_SUMMARY_REPORT_REQUEST,
  CREDIT_CARD_DETAIL_REPORT_REQUEST,
  LAWYERS_RANK_REPORT_REQUEST,
  CUSTOMER_CREDIT_BALANCE_REPORT_REQUEST,
  AR_INCOME_METRICS_REQUEST,
  SUMMARY_REPORT_REQUEST,
  JOB_RANK_REPORT_REQUEST
} from "../configurations/Types";

import {
  INVOICES,
  REPORTS,
  SEND_REPORT_ENDPOINT,
  GET_AR_ANALYSIS_ENDPOINT,
  GET_AGING_REPORT_ENDPOINT,
  CHART_OF_ACCOUNTS_ENDPOINT,
  AR_SEARCH_TOOL_ENDPOINT,
  ALL_SUBSCRIPTION_ENDPOINT,
  REPORT,
  TRIAL_BALANCE_SUMMARY_ENDPOINT,
  CUSTOMER_BALANCE_SUMMARY_ENDPOINT,
  CUSTOMER_BALANCE_DETAIL_ENDPOINT,
  ACCOUNTS_RECEIVABLE_GRAPH_ENDPOINT,
  VENDORS_SUMMARY_REPORT_ENDPOINT,
  CREDIT_CARD_DETAIL_REPORT_ENDPOINT,
  LAWYERS_RANK_REPORT_ENDPOINT,
  CUSTOMER_CREDIT_BALANCE_REPORT_ENDPOINT,
  AR_INCOME_METRICS_ENDPOINT,
  SUMMARY_REPORT_ENDPOINT,
  JOB_RANK_REPORT_ENDPOINT
} from "../configurations/Constants";

import {
  sendReportSuccess,
  sendReportError,
  getArAnalysisSuccess,
  getArAnalysisError,
  getAgingReportSuccess,
  getAgingReportError,
  chartOfAccountsSuccess,
  chartOfAccountsError,
  chartOfAccountsRequest,
  arSearchToolReportSuccess,
  arSearchToolReportError,
  trialBalanceSummaryRequest,
  trialBalanceSummarySuccess,
  trialBalanceSummaryError,
  customerBalanceSummarySuccess,
  customerBalanceSummaryError,
  customerBalanceDetailSuccess,
  customerBalanceDetailError,
  accountsReceivableGraphSuccess,
  accountsReceivableGraphError,
  vendorsSummaryReportSuccess,
  vendorsSummaryReportError,
  creditCardDetailReportSuccess,
  creditCardDetailReportError,
  lawyersRankReportSuccess,
  lawyersRankReportError,
  customerCreditBalanceReportSuccess,
  customerCreditBalanceReportError,
  arIncomeMetricsSuccess,
  arIncomeMetricsError,
  summaryReportSuccess,
  summaryReportError,
  jobRankReportSuccess,
  jobRankReportError,
} from "../actions/Report";

import moment, { months } from "moment";
import info from "../components/message/index";

import { privateAgent, CancelToken } from "../configurations/AxiosAgent";

// -- Send Report
let sendReportToken;
let current_time = moment().format("YYYY-MM-DD HH:mm:ss");

const sendReportAPI = (body) =>
  privateAgent.post(`${INVOICES}${SEND_REPORT_ENDPOINT}`, body);

function* sendReport(action) {
  console.log("USER SAGA RUN", action);
  let res;
  try {
    let { file, ...body } = action.body;
    if(body?.invoice_number) {
      let newBody = {...body, number: body?.invoice_number}
      delete newBody?.invoice_number;
      res = yield sendReportAPI(newBody);
    }
    else{
      res = yield sendReportAPI(body);
    }

    console.log("USER API GET", res.data);

    yield put(sendReportSuccess(res.data.data));
    info("success", `${res.data.data.message}`);

    if (action.cb) {
      action.cb(res.data.data);
    }
  } catch (e) {
    console.log("ERROR", e);

    if (e.response) {
      info("error", `${e.response.data.error.message}`);
    }

    yield put(sendReportError(e));
  } finally {
    if (yield cancelled()) {
      console.log("CANCELLED");
    }
  }
}

const customerBalanceSummaryApi = (params) => 
  privateAgent.get(`${REPORTS}${CUSTOMER_BALANCE_SUMMARY_ENDPOINT}`, {
    params,
  });
;

function* customerBalanceSummary(action) {
  const { cb, params } = action;
  console.log('CALLBAKC: ', cb)

  let res;
  try {
    res = yield customerBalanceSummaryApi(params);
    console.log("CUSTOMEER BALANCE SUMMARY RESS", res);
    yield put(customerBalanceSummarySuccess(res.data.data));
    cb(res.data.data);
  } catch (e) {
    if (e.response?.data?.error?.message) {
      info("error", e.response?.data?.error?.message);
    }
    yield put(customerBalanceSummaryError(e.response));
  } finally {
    if (yield cancelled()) {
      console.log("CANCELLED");
    }
  }
}

const customerBalanceDetailApi = (params) => 
  privateAgent.get(`${REPORTS}${CUSTOMER_BALANCE_DETAIL_ENDPOINT}`, { params });


function* customerBalanceDetail(action) {
  const { cb, params } = action;
  let res;
  try {
    res = yield customerBalanceDetailApi(params);
    console.log("CUSTOMEER BALANCE Detail RESS", res);
    yield put(customerBalanceDetailSuccess(res.data.data));
    cb && cb(res.data.data);
  } catch (e) {
    if (e.response?.data?.error?.message) {
      info("error", e.response?.data?.error?.message);
    }
    yield put(customerBalanceDetailError(e.response));
  } finally {
    if (yield cancelled()) {
      console.log("CANCELLED");
    }
  }
}

const accountsReceivableGraphApi = (params) => 
  privateAgent.get(`${REPORTS}${ACCOUNTS_RECEIVABLE_GRAPH_ENDPOINT}`, {
    params,
  });


function* accountsReceivableGraph(action) {
  const { cb, params } = action;
  let res;
  try {
    res = yield accountsReceivableGraphApi(params);
    console.log("CUSTOMEER BALANCE Detail RESS", res);
    yield put(accountsReceivableGraphSuccess(res.data.data));
    cb && cb(res.data.data);
  } catch (e) {
    if (e.response?.data?.error?.message) {
      info("error", e.response?.data?.error?.message);
    }
    yield put(accountsReceivableGraphError(e.response));
  } finally {
    if (yield cancelled()) {
      console.log("CANCELLED");
    }
  }
}

const vendorsSummaryReportApi = (params) => 
  privateAgent.get(`${REPORTS}${VENDORS_SUMMARY_REPORT_ENDPOINT}`, { params });


function* vendorsSummaryReport(action) {
  const { cb, params } = action;
  let res;
  try {
    res = yield vendorsSummaryReportApi(params);
    console.log("CUSTOMEER BALANCE Detail RESS", res);
    yield put(vendorsSummaryReportSuccess(res.data.data));
    cb && cb(res.data.data);
  } catch (e) {
    if (e.response?.data?.error?.message) {
      info("error", e.response?.data?.error?.message);
    }
    yield put(vendorsSummaryReportError(e.response));
  } finally {
    if (yield cancelled()) {
      console.log("CANCELLED");
    }
  }
}

//Credit Card Report Detail

const creditCardDetailReportApi = (params) => 
  privateAgent.get(`${REPORTS}${CREDIT_CARD_DETAIL_REPORT_ENDPOINT}`, {
    params,
  });

function* creditCardDetailReport(action) {
  const { cb, params } = action;
  let res;
  try {
    res = yield creditCardDetailReportApi(params);
    console.log("Credit Card Report Detail RESS", res);
    yield put(creditCardDetailReportSuccess(res.data.data));
    cb && cb(res.data.data);
  } catch (e) {
    if (e.response?.data?.error?.message) {
      info("error", e.response?.data?.error?.message);
    }
    yield put(creditCardDetailReportError(e.response));
  } finally {
    if (yield cancelled()) {
      console.log("CANCELLED");
    }
  }
}

//lawyers Rank Report

const lawyersRankReportApi = (params) => 
  privateAgent.get(`${REPORTS}${LAWYERS_RANK_REPORT_ENDPOINT}`, { params });


function* lawyersRankReport(action) {
  const { cb, params } = action;
  let res;
  try {
    res = yield lawyersRankReportApi(params);
    console.log("Credit Card Report Detail RESS", res);
    yield put(lawyersRankReportSuccess(res.data.data));
    cb && cb(res.data.data);
  } catch (e) {
    if (e.response?.data?.error?.message) {
      info("error", e.response?.data?.error?.message);
    }
    yield put(lawyersRankReportError(e.response));
  } finally {
    if (yield cancelled()) {
      console.log("CANCELLED");
    }
  }
}
//job Rank Report
const jobRankReportApi = (params) => 
  privateAgent.get(`${REPORTS}${JOB_RANK_REPORT_ENDPOINT}`, { params });


function* jobRankReport(action) {
  const { cb, params } = action;
  let res;
  try {
    res = yield jobRankReportApi(params);
    console.log("Credit Card Report Detail RESS", res);
    yield put(jobRankReportSuccess(res.data.data));
    cb && cb(res.data.data);
  } catch (e) {
    if (e.response?.data?.error?.message) {
      info("error", e.response?.data?.error?.message);
    }
    yield put(jobRankReportError(e.response));
  } finally {
    if (yield cancelled()) {
      console.log("CANCELLED");
    }
  }
}

//Customer Credit Balance Report
const customerCreditBalanceReportApi = (params) =>
  privateAgent.get(`${REPORTS}${CUSTOMER_CREDIT_BALANCE_REPORT_ENDPOINT}`, {
    params,
  });

function* customerCreditBalanceReport(action) {
  const { cb, params } = action;
  let res;
  try {
    res = yield customerCreditBalanceReportApi(params);
    console.log("Credit Card Report Detail RESS", res);
    yield put(customerCreditBalanceReportSuccess(res.data.data));
    cb && cb(res.data.data);
  } catch (e) {
    if (e.response?.data?.error?.message) {
      info("error", e.response?.data?.error?.message);
    }
    yield put(customerCreditBalanceReportError(e.response));
  } finally {
    if (yield cancelled()) {
      console.log("CANCELLED");
    }
  }
}

const getAgingReportApi = (params) => {
  return privateAgent.get(`${REPORTS}${GET_AGING_REPORT_ENDPOINT}`, { params });
};

function* getAgingReport(action) {
  const { cb, params } = action;
  let res;
  try {
    const { customer_name, job_name, ...rest } = params;
    res = yield getAgingReportApi(rest);
    console.log("INVOICCESS RESS", res);
    yield put(getAgingReportSuccess(res.data.data));
    cb && cb(res.data.data);
  } catch (e) {
    if (e.response?.data?.error?.message) {
      info("error", e.response?.data?.error?.message);
    }
    yield put(getAgingReportError(e.response));
  } finally {
    if (yield cancelled()) {
    }
  }
}

//AR Income Metrics
const arIncomeMetricsApi = (params) =>
  privateAgent.get(`${REPORTS}${AR_INCOME_METRICS_ENDPOINT}`, { params });

function* arIncomeMetrics(action) {
  const { cb, params } = action;
  let res;
  try {
    res = yield arIncomeMetricsApi(params);
    console.log("AR Income Metrics RESS", res);
    yield put(arIncomeMetricsSuccess(res.data.data));
    cb && cb(res.data.data);
  } catch (e) {
    if (e.response?.data?.error?.message) {
      info("error", e.response?.data?.error?.message);
    }
    yield put(arIncomeMetricsError(e.response));
  } finally {
    if (yield cancelled()) {
      console.log("CANCELLED");
    }
  }
}

const chartOfAccountsApi = () => {
  return privateAgent.get(`${REPORTS}${CHART_OF_ACCOUNTS_ENDPOINT}`);
};

function* chartOfAccounts(action) {
  // const { cb } = action;
  let res;
  try {
    res = yield chartOfAccountsApi();
    console.log("CHAARTTTSSS RESS", res);
    yield put(chartOfAccountsSuccess(res.data.data));
    // cb && cb(res.data.data);
  } catch (e) {
    if (e.response?.data?.error?.message) {
      info("error", e.response?.data?.error?.message);
    }
    yield put(chartOfAccountsError(e.response));
  } finally {
    if (yield cancelled()) {
    }
  }
}





const arSearchToolReportApi = (params) => {
  console.log('THE PARAMS: ',params)
  return privateAgent.get(`${REPORT}${AR_SEARCH_TOOL_ENDPOINT}`, {params});
};
function *arSearchToolReport(action){
  const {cb,params} = action;
  console.log('AR SEARCH TOOL ACTIONS: ', params)
  let res;
  try {
    const {customer_name,lawyer_name,job_name,...rest}=params
    let body = {...rest, current_time: current_time}
    console.log('MY BODY: ', body)
    res=yield arSearchToolReportApi(body)
    console.log("AR SEARCH TOOL RESS",res)
    yield put(arSearchToolReportSuccess(res.data.data));
    cb && cb(res.data.data);
  } catch (e) {
    if (e.response?.data?.error?.message) {
      info("error", e.response?.data?.error?.message);
    }
    yield put(arSearchToolReportError(e.response));
  }
  finally {
    if (yield cancelled()) {
    }
  }
}
//Summary Report
const summaryReportApi = (params) =>
  privateAgent.get(`${REPORTS}${SUMMARY_REPORT_ENDPOINT}`, { params });

function* summaryReport(action) {
  const { cb, params } = action;
  let res;
  try {
    res = yield summaryReportApi(params);
    console.log("Summary Report RESS", res);
    yield put(summaryReportSuccess(res.data.data));
    cb && cb(res.data.data);
  } catch (e) {
    if (e.response?.data?.error?.message) {
      info("error", e.response?.data?.error?.message);
    }
    yield put(summaryReportError(e.response));
  } finally {
    if (yield cancelled()) {
      console.log("CANCELLED");
    }
  }
}
 

const trialBalanceSummaryApi = (params) => {
  console.log('THE PARAMS: ',params)
  return privateAgent.get(`${REPORT}${TRIAL_BALANCE_SUMMARY_ENDPOINT}`, {params});
};

function *trialBalanceSummaryReport(action){
  const {cb,params} = action;
  console.log('trialBalanceSummaryReport: ', action)
  let res;
  try {
    let body = {...params, current_time: current_time}
    console.log('MY BODY: ', body)
    res=yield trialBalanceSummaryApi(body)
    console.log("AR SEARCH TOOL RESS",res)
    yield put(trialBalanceSummarySuccess(res.data.data));
    cb && cb(res.data.data);
  } catch (e) {
    if (e.response?.data?.error?.message) {
      info("error", e.response?.data?.error?.message);
    }
    yield put(trialBalanceSummaryError(e.response));
  }
  finally {
    if (yield cancelled()) {
    }
  }
}







// Our watcher Saga: watches for report related actions
export function* watchReportActions() {
  yield takeLatest(SEND_REPORT_REQUEST, sendReport);
  yield takeLatest(GET_AGING_REPORT_REQUEST, getAgingReport);
  yield takeLatest(CHART_OF_ACCOUNTS_REQUEST, chartOfAccounts);
  // yield takeLatest(SEND_REPORT_REQUEST, sendReport);
  yield takeLatest(AR_SEARCH_TOOL_REPORT_REQUEST,arSearchToolReport);
  yield takeLatest(TRIAL_BALANCE_SUMMARY_REQUEST,trialBalanceSummaryReport);
  yield takeLatest(CUSTOMER_BALANCE_SUMMARY_REQUEST, customerBalanceSummary);
  yield takeLatest(CUSTOMER_BALANCE_DETAIL_REQUEST, customerBalanceDetail);
  yield takeLatest(ACCOUNTS_RECEIVABLE_GRAPH_REQUEST, accountsReceivableGraph);
  yield takeLatest(VENDORS_SUMMARY_REPORT_REQUEST, vendorsSummaryReport);
  yield takeLatest(CREDIT_CARD_DETAIL_REPORT_REQUEST, creditCardDetailReport);
  yield takeLatest(LAWYERS_RANK_REPORT_REQUEST, lawyersRankReport);
  yield takeLatest(JOB_RANK_REPORT_REQUEST, jobRankReport);
  yield takeLatest(
    CUSTOMER_CREDIT_BALANCE_REPORT_REQUEST,
    customerCreditBalanceReport
  );
  yield takeLatest(AR_INCOME_METRICS_REQUEST, arIncomeMetrics);
  yield takeLatest(SUMMARY_REPORT_REQUEST, summaryReport);
}
