import {
  GET_OPEN_TRANSACTIONS_REQUEST,
  GET_OPEN_TRANSACTIONS_SUCCESS,
  GET_OPEN_TRANSACTIONS_ERROR,
  GET_RECURRING_TRANSACTIONS_REQUEST,
  GET_RECURRING_TRANSACTIONS_SUCCESS,
  GET_RECURRING_TRANSACTIONS_ERROR,
  GET_CLOSE_TRANSACTIONS_REQUEST,
  GET_CLOSE_TRANSACTIONS_SUCCESS,
  GET_CLOSE_TRANSACTIONS_ERROR,
  DEL_OPEN_TRANSACTION_REQUEST,
  DEL_OPEN_TRANSACTION_SUCCESS,
  DEL_OPEN_TRANSACTION_ERROR,
ADD_OPEN_TIME_TRANSACTION_REQUEST,
ADD_OPEN_TIME_TRANSACTION_SUCCESS,
ADD_OPEN_TIME_TRANSACTION_ERROR,
  UPDATE_OPEN_TIME_TRANSACTION_REQUEST,
  UPDATE_OPEN_TIME_TRANSACTION_SUCCESS,
  UPDATE_OPEN_TIME_TRANSACTION_ERROR,
  ADD_EXPENSE_TRANSACTION_REQUEST,
  ADD_EXPENSE_TRANSACTION_SUCCESS,
  ADD_EXPENSE_TRANSACTION_ERROR,
  EDIT_EXPENSE_TRANSACTION_REQUEST,
  EDIT_EXPENSE_TRANSACTION_SUCCESS,
  EDIT_EXPENSE_TRANSACTION_ERROR,
  ADD_FIXED_COST_TRANSACTION_REQUEST,
  ADD_FIXED_COST_TRANSACTION_SUCCESS,
  ADD_FIXED_COST_TRANSACTION_ERROR,
  EDIT_FIXED_COST_TRANSACTION_REQUEST,
  EDIT_FIXED_COST_TRANSACTION_SUCCESS,
  EDIT_FIXED_COST_TRANSACTION_ERROR
} from "configurations/Types";

// actions for get transactions
export const getOpenTransRequest = (params) => {
  return {
    type: GET_OPEN_TRANSACTIONS_REQUEST,
    params,
  };
};
export const getOpenTransSuccess = (payload) => {
  return {
    type: GET_OPEN_TRANSACTIONS_SUCCESS,
    payload,
  };
};
export const getOpenTransError = (error) => {
  return {
    type: GET_OPEN_TRANSACTIONS_ERROR,
    error,
  };
};
export const getRecurringTransError = (error) => {
  return {
    type: GET_RECURRING_TRANSACTIONS_ERROR,
    error,
  };
};
export const getRecurringTransRequest = (params) => {
  console.log("PARAMS: ", params);
  return {
    type: GET_RECURRING_TRANSACTIONS_REQUEST,
    params,
  };
};
export const getRecurringTransSuccess = (payload) => {
  return {
    type: GET_RECURRING_TRANSACTIONS_SUCCESS,
    payload,
  };
};
export const getCloseTransRequest = (params) => {
  return {
    type: GET_CLOSE_TRANSACTIONS_REQUEST,
    params,
  };
};
export const getCloseTransSuccess = (payload) => {
  return {
    type: GET_CLOSE_TRANSACTIONS_SUCCESS,
    payload,
  };
};
export const getCloseTransError = (error) => {
  return {
    type: GET_CLOSE_TRANSACTIONS_ERROR,
    error,
  };
};


export const delOpenTransactionRequest = (id,cb) => {
  return {
    type: DEL_OPEN_TRANSACTION_REQUEST,
    id,
    cb
  };
};
export const delOpenTransactionSuccess = () => {
  return {
    type: DEL_OPEN_TRANSACTION_SUCCESS,
  };
};
export const delOpenTransactionError = (error) => {
  return {
    type: DEL_OPEN_TRANSACTION_ERROR,
    error,
  };
};



export const addOpenTimeTransactionRequest=(body,cb)=>{
  return {
    type:ADD_OPEN_TIME_TRANSACTION_REQUEST,
    body,cb
  }
}

export const addOpenTimeTransactionSuccess=()=>{
  return{
    type:ADD_OPEN_TIME_TRANSACTION_SUCCESS,
  }
}

export const addOpenTimeTransactionError=(error)=>{
  return{
    type:ADD_OPEN_TIME_TRANSACTION_ERROR,
    error
  }
}



export const updateOpenTimeTransactionRequest=(id,body,cb)=>{
  return {
    type:UPDATE_OPEN_TIME_TRANSACTION_REQUEST,
    id,body,cb
  }
}

export const updateOpenTimeTransactionSuccess=()=>{
  return{
    type:UPDATE_OPEN_TIME_TRANSACTION_SUCCESS
  }
}

export const updateOpenTimeTransactionError=(error)=>{
  return{
    type:UPDATE_OPEN_TIME_TRANSACTION_ERROR,
    error
  }
}

export const addExpenseTransactionRequest=(body,cb)=>{
  console.log('BODYYYY', body)
  return {
    type:ADD_EXPENSE_TRANSACTION_REQUEST,
    body,cb
  }
}

export const addExpenseTransactionSuccess=()=>{
  return{
    type:ADD_EXPENSE_TRANSACTION_SUCCESS
  }
}

export const addExpenseTransactionError=(error)=>{
  return{
    type:ADD_EXPENSE_TRANSACTION_ERROR,
    error
  }
}
export const addFixedCostTransactionRequest=(body,cb)=>{
  console.log('BODYYYY', body)
  return {
    type:ADD_FIXED_COST_TRANSACTION_REQUEST,
    body,cb
  }
}

export const addFixedCostTransactionSuccess=()=>{
  return{
    type:ADD_FIXED_COST_TRANSACTION_SUCCESS
  }
}

export const addFixedCostTransactionError=(error)=>{
  return{
    type:ADD_FIXED_COST_TRANSACTION_ERROR,
    error
  }
}
export const editExpenseTransactionRequest=(id,body,cb)=>{
  console.log('BODYYYY', body)
  return {
    type:EDIT_EXPENSE_TRANSACTION_REQUEST,
    id,body,cb
  }
}

export const editExpenseTransactionSuccess=()=>{
  return{
    type:EDIT_EXPENSE_TRANSACTION_SUCCESS
  }
}

export const editExpenseTransactionError=(error)=>{
  return{
    type:EDIT_EXPENSE_TRANSACTION_ERROR,
    error
  }
}

export const editFixedCostTransactionRequest=(id,body,cb)=>{
  console.log('BODYYYY', body)
  return {
    type:EDIT_FIXED_COST_TRANSACTION_REQUEST,
    id,body,cb
  }
}

export const editFixedCostTransactionSuccess=()=>{
  return{
    type:EDIT_FIXED_COST_TRANSACTION_SUCCESS
  }
}

export const editFixedCostTransactionError=(error)=>{
  return{
    type:EDIT_FIXED_COST_TRANSACTION_ERROR,
    error
  }
}






