import {
  OPEN_MODAL,
  CLOSE_MODAL,
  CLOSE_ALL_MODAL,
} from "../configurations/Types";
//import rootModal from '../commons/modals/rootModal';

export function openModal(
  title,
  content,
  okText,
  cancelText,
  AllProps,
  replace
) {
  return {
    type: OPEN_MODAL,
    replace,
    modalType: "basic_modal",
    modalProps: {
      title: title,
      visible: true,
      content: content,
      okText: okText || "Ok",
      cancelText: cancelText || "Cancel",
      ...AllProps,
    },
  };
}

export function closeModal(closeFirst) {
  console.log("closemodal ===>", closeFirst);
  return {
    type: CLOSE_MODAL,
    closeFirst,
  };
}

export function closeAllModal() {
  return {
    type: CLOSE_ALL_MODAL,
  };
}
