import {
  getOrganizationSuccess,
  getOrganizationError,
} from "actions/Organization";
import { USERS_URL, ORGANIZATION_ENDPOINT } from "configurations/Constants";
import { GET_ORGANIZATION_REQUEST } from "configurations/Types";
import { privateAgent } from "configurations/AxiosAgent";
import { put, takeLatest } from "redux-saga/effects";
// import moment from "moment";
import info from "components/message/index";

const getOrganizationApi = () => {
  return privateAgent.get(`${USERS_URL}${ORGANIZATION_ENDPOINT}`);
};

function* getOrganization() {
  let res;
  try {
    res = yield getOrganizationApi();
    console.log("res org:", res.data);
    if (res.status == 200) {
      yield put(getOrganizationSuccess(res.data.data));
    }
  } catch (e) {
    console.error("Error: ", e);
    if (e.response?.data.error.message) {
      info("error", e.response?.data.error.message);
      yield put(getOrganizationError(e.response?.data));
    }
  }
}

export function* watchOrganizationActions() {
  yield takeLatest(GET_ORGANIZATION_REQUEST, getOrganization);
}
