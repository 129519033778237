// import { applyMiddleware,
//     // createStore
//   //, compose
// } from 'redux'
import createSagaMiddleWare from "redux-saga";
import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";

import { createInjectorsEnhancer } from "redux-injectors";

import rootReducer from "./reducers";
import rootSaga from "./sagas";

// export default function configureStore(preloadedState) {

const sagaMiddleware = createSagaMiddleWare();

//const middlewares = [loggerMiddleware, thunkMiddleware]
//const middlewareEnhancer = applyMiddleware(...middlewares)

//const enhancers = [middlewareEnhancer, monitorReducersEnhancer]
//const composedEnhancers = compose(...enhancers)

const enhancers = [
  createInjectorsEnhancer({
    createReducer: rootReducer,
    runSaga: sagaMiddleware.run,
  }),
];

export const store = configureStore({
  reducer: rootReducer,
  middleware: [...getDefaultMiddleware({
    serializableCheck: {
      ignoredActionPaths: ['cb']
    }
  }), ...[sagaMiddleware]],
  enhancers,
});
// compose(
//   applyMiddleware(sagaMiddleware)
//   ,window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__())
// );

sagaMiddleware.run(rootSaga);
