import {
    BUY_SUBSCRIPTION_REQUEST,
    BUY_SUBSCRIPTION_SUCCESS,
    BUY_SUBSCRIPTION_ERROR,
    GET_SUBSCRIPTION_REQUEST,
    GET_SUBSCRIPTION_SUCCESS,
    GET_SUBSCRIPTION_ERROR,
    SET_SUBSCRIPTION,
    CANCEL_SUBSCRIPTION_REQUEST,
    CANCEL_SUBSCRIPTION_SUCCESS,
    CANCEL_SUBSCRIPTION_ERROR,
    ALL_SUBSCRIPTION_REQUEST,
  ALL_SUBSCRIPTION_SUCCESS,
  ALL_SUBSCRIPTION_ERROR,
  CHECK_SUBSCRIPTION_REQUEST,
    CHECK_SUBSCRIPTION_SUCCESS,
    CHECK_SUBSCRIPTION_ERROR,
  } from "../configurations/Types";
  import { LOCAL_STORAGE_KEY } from "configurations/Constants";

  //Buy subscription
  export const buySubscriptionRequest = (generatePaymentMethod, params, callback) => {
    return {
      type: BUY_SUBSCRIPTION_REQUEST,
      generatePaymentMethod,
      params,
      callback
    };
  };
  
  export const buySubscriptionSuccess = (cb) => {
    let newStorage = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY));
    newStorage.subscription.status = "active";
     localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(newStorage));
    cb()
    return {
      type: BUY_SUBSCRIPTION_SUCCESS,
    };
  };
  
  export const buySubscriptionError = () => {
    return {
      type: BUY_SUBSCRIPTION_ERROR,
    };
  };
  
  
  export const getSubscriptionStatus = () => {
    return {
      type: GET_SUBSCRIPTION_REQUEST,
    };
  };
  
  export const getSubscriptionStatusSuccess = () => {
    return {
      type: GET_SUBSCRIPTION_SUCCESS,
    };
  };
  
  export const getSubscriptionStatusError = () => {
    return {
      type: GET_SUBSCRIPTION_ERROR,
    };
  };
  
  export const setSubscriptionStatus = (status) => {
    return {
      type: SET_SUBSCRIPTION,
      status,
    };
  };

  export const allSubscriptionRequest = (params, cb) => {
    return {
      type: ALL_SUBSCRIPTION_REQUEST,
      params,
      cb,
    };
  };
  
  export const allSubscriptionSuccess = (payload) => {
    return {
      type: ALL_SUBSCRIPTION_SUCCESS,
      payload,
    };
  };
  
  
  export const allSubscriptionError = (error) => {
    return {
      type: ALL_SUBSCRIPTION_ERROR,
      error,
    };
  };
  
  export const checkSubscriptionRequest = (params, cb) => {
    return {
      type: CHECK_SUBSCRIPTION_REQUEST,
      params,
      cb,
    };
  };
  
  export const checkSubscriptionSuccess = (payload) => {
    return {
      type: CHECK_SUBSCRIPTION_SUCCESS,
      payload,
    };
  };
  
  
  export const checkSubscriptionError = (error) => {
    return {
      type: CHECK_SUBSCRIPTION_ERROR,
      error,
    };
  };

  export const cancelSubscriptionRequest = () => {
    return {
      type: CANCEL_SUBSCRIPTION_REQUEST,
    };
  };
  
  export const cancelSubscriptionSuccess = () => {
    return {
      type: CANCEL_SUBSCRIPTION_SUCCESS,
    };
  };
  
  export const cancelSubscriptionError = () => {
    return {
      type: CANCEL_SUBSCRIPTION_ERROR,
    };
  };
  