import {
  //ADD types
  ADD_LAWYER_SUCCESS,
  ADD_LAWYER_REQUEST,
  ADD_LAWYER_ERROR,
  ADD_CUSTOMER_REQUEST,
  ADD_CUSTOMER_SUCCESS,
  ADD_CUSTOMER_ERROR,
  ADD_JOB_REQUEST,
  ADD_JOB_SUCCESS,
  ADD_JOB_ERROR,
  ADD_TIME_ACCOUNT_ERROR,
  ADD_TIME_ACCOUNT_SUCCESS,
  ADD_TIME_ACCOUNT_REQUEST,
  ADD_FIXED_COST_ACCOUNT_REQUEST,
  ADD_FIXED_COST_ACCOUNT_SUCCESS,
  ADD_FIXED_COST_ACCOUNT_ERROR,
  ADD_EXPENSE_ACCOUNT_REQUEST,
  ADD_EXPENSE_ACCOUNT_SUCCESS,
  ADD_EXPENSE_ACCOUNT_ERROR,
  ADD_VENDOR_REQUEST,
  ADD_VENDOR_SUCCESS,
  ADD_VENDOR_ERROR,
  ADD_CREDIT_CARD_REQUEST,
  ADD_CREDIT_CARD_SUCCESS,
  ADD_CREDIT_CARD_ERROR,

  //GET types
  FETCH_LAWYERS_REQUEST,
  FETCH_LAWYERS_SUCCESS,
  FETCH_LAWYERS_ERROR,
  FETCH_CUSTOMERS_REQUEST,
  FETCH_CUSTOMERS_SUCCESS,
  FETCH_CUSTOMERS_ERROR,
  FETCH_JOBS_REQUEST,
  FETCH_JOBS_SUCCESS,
  FETCH_JOBS_ERROR,
  FETCH_ALL_ACCOUNTS_REQUEST,
  FETCH_ALL_ACCOUNTS_SUCCESS,
  FETCH_ALL_ACCOUNTS_ERROR,
  FETCH_TIME_ACCOUNTS_REQUEST,
  FETCH_TIME_ACCOUNTS_SUCCESS,
  FETCH_TIME_ACCOUNTS_ERROR,
  FETCH_FIXED_COST_ACCOUNTS_REQUEST,
  FETCH_FIXED_COST_ACCOUNTS_SUCCESS,
  FETCH_FIXED_COST_ACCOUNTS_ERROR,
  FETCH_EXPENSE_ACCOUNTS_REQUEST,
  FETCH_EXPENSE_ACCOUNTS_SUCCESS,
  FETCH_EXPENSE_ACCOUNTS_ERROR,
  FETCH_VENDORS_REQUEST,
  FETCH_VENDORS_SUCCESS,
  FETCH_VENDORS_ERROR,
  FETCH_CREDIT_CARDS_REQUEST,
  FETCH_CREDIT_CARDS_SUCCESS,
  FETCH_CREDIT_CARDS_ERROR,
  GET_DASHBOARD_REQUEST,
  GET_DASHBOARD_SUCCESS,
  GET_DASHBOARD_ERROR,

  // UPDATE types
  UPDATE_LAWYER_REQUEST,
  UPDATE_LAWYER_SUCCESS,
  UPDATE_LAWYER_ERROR,
  UPDATE_CUSTOMER_REQUEST,
  UPDATE_CUSTOMER_SUCCESS,
  UPDATE_CUSTOMER_ERROR,
  UPDATE_JOB_REQUEST,
  UPDATE_JOB_SUCCESS,
  UPDATE_JOB_ERROR,
  UPDATE_TIME_ACCOUNT_REQUEST,
  UPDATE_TIME_ACCOUNT_SUCCESS,
  UPDATE_TIME_ACCOUNT_ERROR,
  UPDATE_FIXED_COST_ACCOUNT_REQUEST,
  UPDATE_FIXED_COST_ACCOUNT_SUCCESS,
  UPDATE_FIXED_COST_ACCOUNT_ERROR,
  UPDATE_EXPENSE_ACCOUNT_REQUEST,
  UPDATE_EXPENSE_ACCOUNT_SUCCESS,
  UPDATE_EXPENSE_ACCOUNT_ERROR,
  UPDATE_VENDOR_REQUEST,
  UPDATE_VENDOR_SUCCESS,
  UPDATE_VENDOR_ERROR,
  UPDATE_CREDIT_CARD_REQUEST,
  UPDATE_CREDIT_CARD_SUCCESS,
  UPDATE_CREDIT_CARD_ERROR,

  // DELETE type
  DEL_LAWYER_REQUEST,
  DEL_LAWYER_SUCCESS,
  DEL_LAWYER_ERROR,
  DEL_CUSTOMER_REQUEST,
  DEL_CUSTOMER_SUCCESS,
  DEL_CUSTOMER_ERROR,
  DEL_JOB_REQUEST,
  DEL_JOB_SUCCESS,
  DEL_JOB_ERROR,
  DEL_TIME_ACCOUNT_REQUEST,
  DEL_TIME_ACCOUNT_SUCCESS,
  DEL_TIME_ACCOUNT_ERROR,
  DEL_FIXED_COST_ACCOUNT_REQUEST,
  DEL_FIXED_COST_ACCOUNT_SUCCESS,
  DEL_FIXED_COST_ACCOUNT_ERROR,
  DEL_EXPENSE_ACCOUNT_REQUEST,
  DEL_EXPENSE_ACCOUNT_SUCCESS,
  DEL_EXPENSE_ACCOUNT_ERROR,
  DEL_VENDOR_REQUEST,
  DEL_VENDOR_SUCCESS,
  DEL_VENDOR_ERROR,
  DEL_CREDIT_CARD_REQUEST,
  DEL_CREDIT_CARD_SUCCESS,
  DEL_CREDIT_CARD_ERROR,

  // SEARCH types
  SEARCH_LAWYER_REQUEST,
  SEARCH_LAWYER_SUCCESS,
  SEARCH_CUSTOMER_REQUEST,
  SEARCH_CUSTOMER_SUCCESS,
  SEARCH_JOB_REQUEST,
  SEARCH_JOB_SUCCESS,
  SEARCH_TIME_ACCOUNT_REQUEST,
  SEARCH_TIME_ACCOUNT_SUCCESS,
  SEARCH_FIXED_COST_ACCOUNT_REQUEST,
  SEARCH_FIXED_COST_ACCOUNT_SUCCESS,
  SEARCH_EXPENSE_ACCOUNT_REQUEST,
  SEARCH_EXPENSE_ACCOUNT_SUCCESS,
  SEARCH_VENDOR_REQUEST,
  SEARCH_VENDOR_SUCCESS,
  SEARCH_CREDIT_CARD_REQUEST,
  SEARCH_CREDIT_CARD_SUCCESS,
  CLEAR_TYPE_SEARCH,

  // Details
  LAWYER_DETAILS_REQUEST,
  LAWYER_DETAILS_SUCCESS,
  LAWYER_DETAILS_ERROR,
  LAWYER_DETAILS_EMPTY,
  CUSTOMER_DETAILS_REQUEST,
  CUSTOMER_DETAILS_SUCCESS,
  CUSTOMER_DETAILS_ERROR,

  // Details PATCH
  LAWYER_ASSIGN_REQUEST,
  LAWYER_ASSIGN_SUCCESS,
  LAWYER_ASSIGN_ERROR,
  CUSTOMER_ASSIGN_REQUEST,
  CUSTOMER_ASSIGN_SUCCESS,
  CUSTOMER_ASSIGN_ERROR,
} from "configurations/Types";

// Get dashboard
export function getDashboardRequest() {
  return {
    type: GET_DASHBOARD_REQUEST,
  };
}

export function getDashboardSuccess(payload) {
  return {
    type: GET_DASHBOARD_SUCCESS,
    payload,
  };
}

export function getDashboardError(error) {
  return {
    type: GET_DASHBOARD_ERROR,
    error,
  };
}
//ADD LAWYER ACTIONS
export function addLawyerRequest(body, cb) {
  console.log("state: ", body)
  return {
    type: ADD_LAWYER_REQUEST,
    body,
    cb,
  };
}
export function addLawyerSuccess() {
  return {
    type: ADD_LAWYER_SUCCESS,
  };
}
export function addLawyerError(error) {
  return {
    type: ADD_LAWYER_ERROR,
    error,
  };
}

//ADD CUSTOMER ACTIONS
export function addCustomerRequest(body, cb) {
  return {
    type: ADD_CUSTOMER_REQUEST,
    body,
    cb,
  };
}
export function addCustomerSuccess() {
  return {
    type: ADD_CUSTOMER_SUCCESS,
  };
}
export function addCustomerError(error) {
  return {
    type: ADD_CUSTOMER_ERROR,
    error,
  };
}

//ADD JOB ACTIONS
export function addJobRequest(body, cb) {
  return {
    type: ADD_JOB_REQUEST,
    body,
    cb,
  };
}
export function addJobSuccess() {
  return {
    type: ADD_JOB_SUCCESS,
  };
}
export function addJobError(error) {
  return {
    type: ADD_JOB_ERROR,
    error,
  };
}

//ADD TIME ACCOUNT ACTIONS
export function addTimeAccountRequest(body, cb) {
  return {
    type: ADD_TIME_ACCOUNT_REQUEST,
    body,
    cb,
  };
}
export function addTimeAccountSuccess() {
  return {
    type: ADD_TIME_ACCOUNT_SUCCESS,
  };
}
export function addTimeAccountError(error) {
  return {
    type: ADD_TIME_ACCOUNT_ERROR,
    error,
  };
}

//ADD FIXED COST ACCOUNT ACTIONS
export function addFixedCostAccountRequest(body, cb) {
  return {
    type: ADD_FIXED_COST_ACCOUNT_REQUEST,
    body,
    cb,
  };
}
export function addFixedCostAccountSuccess() {
  return {
    type: ADD_FIXED_COST_ACCOUNT_SUCCESS,
  };
}
export function addFixedCostAccountError(error) {
  return {
    type: ADD_FIXED_COST_ACCOUNT_ERROR,
    error,
  };
}

//ADD EXPENSE ACCOUNT ACTIONS
export function addExpenseAccountRequest(body, cb) {
  return {
    type: ADD_EXPENSE_ACCOUNT_REQUEST,
    body,
    cb,
  };
}
export function addExpenseAccountSuccess() {
  return {
    type: ADD_EXPENSE_ACCOUNT_SUCCESS,
  };
}
export function addExpenseAccountError(error) {
  return {
    type: ADD_EXPENSE_ACCOUNT_ERROR,
    error,
  };
}

//ADD VENDOR ACTIONS
export function addVendorRequest(body, cb) {
  return {
    type: ADD_VENDOR_REQUEST,
    body,
    cb,
  };
}
export function addVendorSuccess() {
  return {
    type: ADD_VENDOR_SUCCESS,
  };
}
export function addVendorError(error) {
  return {
    type: ADD_VENDOR_ERROR,
    error,
  };
}

//ADD CREDIT CARD ACTIONS
export function addCreditCardRequest(body, cb) {
  return {
    type: ADD_CREDIT_CARD_REQUEST,
    body,
    cb,
  };
}
export function addCreditCardSuccess() {
  return {
    type: ADD_CREDIT_CARD_SUCCESS,
  };
}
export function addCreditCardError(error) {
  return {
    type: ADD_CREDIT_CARD_ERROR,
    error,
  };
}

/*--------------------------------------FETCH---------------------------------------*/

//FETCH lawyers Actions
export function fetchLawyersRequest(params, cb) {
  return {
    type: FETCH_LAWYERS_REQUEST,
    params,
    cb,
  };
}

export function fetchLawyersSuccess(body) {
  return {
    type: FETCH_LAWYERS_SUCCESS,
    body,
  };
}

export function fetchLawyersError(error) {
  return {
    type: FETCH_LAWYERS_ERROR,
    error,
  };
}

//FETCH Customers Actions
export function fetchCustomersRequest(params, cb) {
  return {
    type: FETCH_CUSTOMERS_REQUEST,
    params,
    cb,
  };
}

export function fetchCustomersSuccess(body) {
  return {
    type: FETCH_CUSTOMERS_SUCCESS,
    body,
  };
}

export function fetchCustomersError(error) {
  return {
    type: FETCH_CUSTOMERS_ERROR,
    error,
  };
}

//FETCH Jobs Actions
export function fetchJobsRequest(params, cb) {
  console.log("fetchJobsRequest");
  return {
    type: FETCH_JOBS_REQUEST,
    params,
    cb,
  };
}

export function fetchJobsSuccess(body) {
  return {
    type: FETCH_JOBS_SUCCESS,
    body,
  };
}

export function fetchJobsError(error) {
  return {
    type: FETCH_JOBS_ERROR,
    error,
  };
}
//FETCH ALL ACCOUNTS ACTIONS
export function fetchAllAccountsRequest(cb) {
  console.log("FETTCH ALL ACCOUNTSSS")
  return {
    type: FETCH_ALL_ACCOUNTS_REQUEST,
    cb,
  };
}
export function fetchAllAccountsSuccess(body) {
  console.log('BODY IN ACTION: ', body)
  return {
    type: FETCH_ALL_ACCOUNTS_SUCCESS,
    body
  };
}
export function fetchAllAccountsError(error) {
  return {
    type: FETCH_ALL_ACCOUNTS_ERROR,
    error
  };
}
//FETCH TIME ACCOUNTS Actions
export function fetchTimeAccountsRequest(params, cb) {
  console.log("TIME BODDY", cb);

  return {
    type: FETCH_TIME_ACCOUNTS_REQUEST,
    params,
    cb,
    accountType: "time",
  };
}

export function fetchTimeAccountsSuccess(body) {
  return {
    type: FETCH_TIME_ACCOUNTS_SUCCESS,
    body,
  };
}

export function fetchTimeAccountsError(error) {
  return {
    type: FETCH_TIME_ACCOUNTS_ERROR,
    error,
  };
}

//FETCH FIXED COST ACCOUNTS Actions
export function fetchFixedCostAccountsRequest(params, cb) {
  console.log("TIME BODDY", cb);

  return {
    type: FETCH_FIXED_COST_ACCOUNTS_REQUEST,
    params,
    cb,
    accountType: "fixed cost",
  };
}

export function fetchFixedCostAccountsSuccess(body) {
  return {
    type: FETCH_FIXED_COST_ACCOUNTS_SUCCESS,
    body,
  };
}

export function fetchFixedCostAccountsError(error) {
  return {
    type: FETCH_FIXED_COST_ACCOUNTS_ERROR,
    error,
  };
}

//FETCH EXPENSE ACCOUNTS Actions
export function fetchExpenseAccountsRequest(params, cb) {
  return {
    type: FETCH_EXPENSE_ACCOUNTS_REQUEST,
    params,
    cb,
  };
}

export function fetchExpenseAccountsSuccess(body) {
  return {
    type: FETCH_EXPENSE_ACCOUNTS_SUCCESS,
    body,
  };
}

export function fetchExpenseAccountsError(error) {
  return {
    type: FETCH_EXPENSE_ACCOUNTS_ERROR,
    error,
  };
}

//FETCH VENDORS Actions
export function fetchVendorsRequest(params, cb) {
  return {
    type: FETCH_VENDORS_REQUEST,
    params,
    cb,
  };
}

export function fetchVendorsSuccess(body) {
  return {
    type: FETCH_VENDORS_SUCCESS,
    body,
  };
}

export function fetchVendorsError(error) {
  return {
    type: FETCH_VENDORS_ERROR,
    error,
  };
}

//FETCH CREDIT CARDS Actions
export function fetchCreditCardsRequest(params, cb) {
  return {
    type: FETCH_CREDIT_CARDS_REQUEST,
    params,
    cb,
  };
}

export function fetchCreditCardsSuccess(body) {
  return {
    type: FETCH_CREDIT_CARDS_SUCCESS,
    body,
  };
}

export function fetchCreditCardsError(error) {
  return {
    type: FETCH_CREDIT_CARDS_ERROR,
    error,
  };
}

/*--------------------------------------search---------------------------------------*/
export function searchLawyersRequest(search) {
  return {
    type: SEARCH_LAWYER_REQUEST,
    search,
  };
}
export function searchLawyersSuccess() {
  return {
    type: SEARCH_LAWYER_SUCCESS,
  };
}

// --
export function searchCustomersRequest(search) {
  return {
    type: SEARCH_CUSTOMER_REQUEST,
    search,
  };
}

export function searchCustomersSuccess() {
  return {
    type: SEARCH_CUSTOMER_SUCCESS,
  };
}

// --
export function searchJobsRequest(search) {
  return {
    type: SEARCH_JOB_REQUEST,
    search,
  };
}
export function searchJobsSuccess() {
  return {
    type: SEARCH_JOB_SUCCESS,
  };
}

// --
export function searchTimeAccountRequest(search) {
  return {
    type: SEARCH_TIME_ACCOUNT_REQUEST,
    search,
  };
}
export function searchTimeAccountSuccess() {
  return {
    type: SEARCH_TIME_ACCOUNT_SUCCESS,
  };
}

// --
export function searchFixedCostAccountRequest(search) {
  return {
    type: SEARCH_FIXED_COST_ACCOUNT_REQUEST,
    search,
  };
}
export function searchFixedCostAccountSuccess() {
  return {
    type: SEARCH_FIXED_COST_ACCOUNT_SUCCESS,
  };
}

// --
export function searchExpenseAccRequest(search) {
  return {
    type: SEARCH_EXPENSE_ACCOUNT_REQUEST,
    search,
  };
}
export function searchExpenseAccSuccess() {
  return {
    type: SEARCH_EXPENSE_ACCOUNT_SUCCESS,
  };
}

// --
export function searchVendorRequest(search) {
  return {
    type: SEARCH_VENDOR_REQUEST,
    search,
  };
}
export function searchVendorSuccess() {
  return {
    type: SEARCH_VENDOR_SUCCESS,
  };
}

// --
export function searchCreditCardsRequest(search) {
  return {
    type: SEARCH_CREDIT_CARD_REQUEST,
    search,
  };
}
export function searchCreditCardsSuccess() {
  return {
    type: SEARCH_CREDIT_CARD_SUCCESS,
  };
}

export function clearTypeSearch() {
  return {
    type: CLEAR_TYPE_SEARCH,
  };
}

// --------------- UPDATE Type ---------------
export function updateLawyerRequest(body, cb) {
  return {
    type: UPDATE_LAWYER_REQUEST,
    body,
    cb,
  };
}
export function updateLawyerSuccess() {
  return {
    type: UPDATE_LAWYER_SUCCESS,
  };
}
export function updateLawyerError(err) {
  return {
    type: UPDATE_LAWYER_ERROR,
    err,
  };
}

export function updateCustomerRequest(body, cb) {
  return {
    type: UPDATE_CUSTOMER_REQUEST,
    body,
    cb,
  };
}
export function updateCustomerSuccess() {
  return {
    type: UPDATE_CUSTOMER_SUCCESS,
  };
}
export function updateCustomerError(err) {
  return {
    type: UPDATE_CUSTOMER_ERROR,
    err,
  };
}

export function updateJobRequest(body, cb) {
  return {
    type: UPDATE_JOB_REQUEST,
    body,
    cb,
  };
}
export function updateJobSuccess() {
  return {
    type: UPDATE_JOB_SUCCESS,
  };
}
export function updateJobError(err) {
  return {
    type: UPDATE_JOB_ERROR,
    err,
  };
}

export function updateTimeAccRequest(body, cb) {
  return {
    type: UPDATE_TIME_ACCOUNT_REQUEST,
    body,
    cb,
  };
}
export function updateTimeAccSuccess() {
  return {
    type: UPDATE_TIME_ACCOUNT_SUCCESS,
  };
}
export function updateTimeAccError(err) {
  return {
    type: UPDATE_TIME_ACCOUNT_ERROR,
    err,
  };
}

export function updateFixedCostAccountRequest(body, cb) {
  return {
    type: UPDATE_FIXED_COST_ACCOUNT_REQUEST,
    body,
    cb,
  };
}
export function updateFixedCostAccountSuccess() {
  return {
    type: UPDATE_FIXED_COST_ACCOUNT_SUCCESS,
  };
}
export function updateFixedCostAccountError(err) {
  return {
    type: UPDATE_FIXED_COST_ACCOUNT_ERROR,
    err,
  };
}
export function updateExpenseAccRequest(body, cb) {
  return {
    type: UPDATE_EXPENSE_ACCOUNT_REQUEST,
    body,
    cb,
  };
}
export function updateExpenseAccSuccess() {
  return {
    type: UPDATE_EXPENSE_ACCOUNT_SUCCESS,
  };
}
export function updateExpenseAccError(err) {
  return {
    type: UPDATE_EXPENSE_ACCOUNT_ERROR,
    err,
  };
}

export function updateVendorRequest(body, cb) {
  return {
    type: UPDATE_VENDOR_REQUEST,
    body,
    cb,
  };
}
export function updateVendorSuccess() {
  return {
    type: UPDATE_VENDOR_SUCCESS,
  };
}
export function updateVendorError(err) {
  return {
    type: UPDATE_VENDOR_ERROR,
    err,
  };
}

export function updateCreditCardRequest(body, cb) {
  return {
    type: UPDATE_CREDIT_CARD_REQUEST,
    body,
    cb,
  };
}
export function updateCreditCardSuccess() {
  return {
    type: UPDATE_CREDIT_CARD_SUCCESS,
  };
}
export function updateCreditCardError(err) {
  return {
    type: UPDATE_CREDIT_CARD_ERROR,
    err,
  };
}

// ---------------------------- GET DETAILS -----------------------
export function getLawyerDetailsRequest(id, params, cb) {
  console.log("id params", params);
  return {
    type: LAWYER_DETAILS_REQUEST,
    id,
    params,
    cb,
  };
}
export function getLawyerDetailsSuccess(payload) {
  return {
    type: LAWYER_DETAILS_SUCCESS,
    payload,
  };
}
export function getLawyerDetailsError(error) {
  return {
    type: LAWYER_DETAILS_ERROR,
    error,
  };
}

export function setLawyerDetailsEmpty(){
  return{
    type: LAWYER_DETAILS_EMPTY
  }
}

export function getCustomerDetailsRequest(id, params, cb) {
  return {
    type: CUSTOMER_DETAILS_REQUEST,
    id,
    params,
    cb,
  };
}
export function getCustomerDetailsSuccess(payload) {
  return {
    type: CUSTOMER_DETAILS_SUCCESS,
    payload,
  };
}
export function getCustomerDetailsError(error) {
  return {
    type: CUSTOMER_DETAILS_ERROR,
    error,
  };
}

// ---------------------------- ASSIGN DETAIL -----------------------
export function assignLawyerRequest(body, cb) {
  return {
    type: LAWYER_ASSIGN_REQUEST,
    body,
    cb,
  };
}
export function assignLawyerSuccess() {
  return {
    type: LAWYER_ASSIGN_SUCCESS,
  };
}
export function assignLawyerError(error) {
  return {
    type: LAWYER_ASSIGN_ERROR,
    error,
  };
}

export function assignCustomerRequest(body, cb) {
  return {
    type: CUSTOMER_ASSIGN_REQUEST,
    body,
    cb,
  };
}
export function assignCustomerSuccess() {
  return {
    type: CUSTOMER_ASSIGN_SUCCESS,
  };
}
export function assignCustomerError(error) {
  return {
    type: CUSTOMER_ASSIGN_ERROR,
    error,
  };
}
// ---------------------------- DELETE -----------------------
export function delLawyerRequest(body) {
  return {
    type: DEL_LAWYER_REQUEST,
    body,
  };
}
export function delLawyerSuccess() {
  return {
    type: DEL_LAWYER_SUCCESS,
  };
}
export function delLawyerError(err) {
  return {
    type: DEL_LAWYER_ERROR,
    err,
  };
}

export function delCustomerRequest(body) {
  return {
    type: DEL_CUSTOMER_REQUEST,
    body,
  };
}
export function delCustomerSuccess() {
  return {
    type: DEL_CUSTOMER_SUCCESS,
  };
}
export function delCustomerError(err) {
  return {
    type: DEL_CUSTOMER_ERROR,
    err,
  };
}

export function delJobRequest(body) {
  return {
    type: DEL_JOB_REQUEST,
    body,
  };
}
export function delJobSuccess() {
  return {
    type: DEL_JOB_SUCCESS,
  };
}
export function delJobError(err) {
  return {
    type: DEL_JOB_ERROR,
    err,
  };
}

export function delTimeAccRequest(id, cb) {
  console.log("Delete time act");
  return {
    type: DEL_TIME_ACCOUNT_REQUEST,
    id,
    cb,
  };
}
export function delTimeAccSuccess() {
  return {
    type: DEL_TIME_ACCOUNT_SUCCESS,
  };
}
export function delTimeAccError(err) {
  return {
    type: DEL_TIME_ACCOUNT_ERROR,
    err,
  };
}

export function deleteFixedCostAccountRequest(id, cb) {
  console.log("Delete time act");
  return {
    type: DEL_FIXED_COST_ACCOUNT_REQUEST,
    id,
    cb,
  };
}
export function deleteFixedCostAccountSuccess() {
  return {
    type: DEL_FIXED_COST_ACCOUNT_SUCCESS,
  };
}
export function deleteFixedCostAccountError(err) {
  return {
    type: DEL_FIXED_COST_ACCOUNT_ERROR,
    err,
  };
}
export function delExpenseAccRequest(id, cb) {
  return {
    type: DEL_EXPENSE_ACCOUNT_REQUEST,
    id,
    cb,
  };
}
export function delExpenseAccSuccess() {
  return {
    type: DEL_EXPENSE_ACCOUNT_SUCCESS,
  };
}
export function delExpenseAccError(err) {
  return {
    type: DEL_EXPENSE_ACCOUNT_ERROR,
    err,
  };
}

export function delVendorRequest(body) {
  return {
    type: DEL_VENDOR_REQUEST,
    body,
  };
}
export function delVendorSuccess() {
  return {
    type: DEL_VENDOR_SUCCESS,
  };
}
export function delVendorError(err) {
  return {
    type: DEL_VENDOR_ERROR,
    err,
  };
}

export function delCreditCardRequest(body) {
  return {
    type: DEL_CREDIT_CARD_REQUEST,
    body,
  };
}
export function delCreditCardSuccess() {
  return {
    type: DEL_CREDIT_CARD_SUCCESS,
  };
}
export function delCreditCardError(err) {
  return {
    type: DEL_CREDIT_CARD_ERROR,
    err,
  };
}
