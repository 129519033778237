import { put, takeLatest, cancelled } from "redux-saga/effects";
import {
  uploadImages as uploadImagesAction
} from "../actions/Images";
import {
  UPLOAD_IMAGE_ENDPOINT,
  API_REST_URL
} from "../configurations/Constants";
import info from "../components/message/index";
import {
  privateAgent,
  //publicAgent,
  //CancelToken
} from "../configurations/AxiosAgent";
//import moment from "moment";


const uploadImagesApi = (body, token) => {
  return privateAgent.post(`${API_REST_URL}${UPLOAD_IMAGE_ENDPOINT}`, body, {
    //cancelToken: token.token,
    headers: { "Content-Type": "application/application/x-www-form-urlencoded" },
  });
};

//let token;

// Our worker Saga: will perform the async increment task
export function* uploadImages(action) {
  console.log("IMAGES SAGA", action);
  let res;
  try {
    let { images, cb } = action.payload;

    console.log("IMAGES SAGA", action);

    //images.filter(file => file instanceof Blob);

    let formData = new FormData();
    //formData.append("images[]", images);
    images.forEach((val) => formData.append("images[]", val, val.name));

    console.log("IMAGES SAGA", action);
    
    const { data: { success: { data: urls } } } = yield uploadImagesApi(formData);
    console.log("images", res);

    //info("success", `Image/s uploaded`);
    if (cb) {
      cb(urls);
    }
  } catch (e) {
    console.log(e);
    try {
      info("error", `${e.response.data.error.message}`);
    }
    catch(e) {}
  } finally {
    if (yield cancelled()) {
      // console.log("CANCELLED");
    }
  }
 // console.log("In saga", res);
  //yield put({ type: 'INCREMENT' })
}

export function* watchImagesActions() {
  yield takeLatest(uploadImagesAction.toString(), uploadImages);
}
