import {
  STRIPE_ACCOUNT_SUCCESS,
  STRIPE_ACCOUNT_REQUEST,
  STRIPE_ACCOUNT_ERROR,
  GET_ALL_ACCOUNT_REQ,
  GET_ALL_ACCOUNT_SUCCESS,
  GET_ALL_ACCOUNT_ERROR,
  LINKED_ACCOUNT_SUCCESS,
  LINKED_ACCOUNT_REQUEST,
  LINKED_ACCOUNT_ERROR,
  DELETE_ACCOUNT_SUCCESS,
  DELETE_ACCOUNT_REQUEST,
  CHECK_TOKEN_SUCCESS,
  DEL_CREDIT_CARD_ERROR,
  ACTIVE_ACCOUNT_SUCCESS,
  ACTIVE_ACCOUNT_ERROR,
  ACTIVE_ACCOUNT_REQUEST,
  RECORD_INVOICE_SUC,
  RECORD_INVOICE_REQ,
  RECORD_INVOICE_ERR,
  CHECK_TOKEN_REQ,
  CHECK_TOKEN_ERROR,
  CREATE_PAYMENT_REQUEST,
  CHECK_ACCOUNT_REQ,
  CHECK_ACCOUNT_SUCCESS,
  CHECK_ACCOUNT_ERROR
} from "../configurations/Types";

export function StripeAccountReq(payload, cb) {
  console.log("Checking CallBack ===>", cb);
  console.log("Checking Payload ===>", payload);
  return { type: STRIPE_ACCOUNT_REQUEST, payload, cb };
}

export function StripeAccountSuc(res) {
  return { type: STRIPE_ACCOUNT_SUCCESS, res };
}

export function StripeAccountErr(error) {
  return { type: STRIPE_ACCOUNT_ERROR, error };
}

export function CheckAccountReq(payload, cb) {
  //console.log("Checking CallBack ===>", cb);
  console.log("Checking Payload ===>", payload);
  return { type: CHECK_ACCOUNT_REQ, payload, cb };
}

export function CheckAccountSuc(res) {
  return { type: CHECK_ACCOUNT_SUCCESS, res };
}

export function CheckAccountErr(error) {
  return { type: CHECK_ACCOUNT_ERROR, error };
}

export function getAllAccountsReq(ab, callback) {
  return { type: GET_ALL_ACCOUNT_REQ, ab, callback };
}

export function getAllAccountsSuccess(res) {
  return { type: GET_ALL_ACCOUNT_SUCCESS, res };
}
export function getAllAccountError(e) {
  return { type: GET_ALL_ACCOUNT_ERROR, e };
}
export function linkedAccountReq(payload) {
  return { type: LINKED_ACCOUNT_REQUEST, payload };
}
export function linkedAccountSuc(res) {
  return { type: LINKED_ACCOUNT_SUCCESS, res };
}

export function linkedAccountError(e) {
  return { type: LINKED_ACCOUNT_ERROR, e };
}

export function deleteAccountReq(id) {
  return { type: DELETE_ACCOUNT_REQUEST, id };
}

export function deleteAccountSuc(res) {
  return { type: DELETE_ACCOUNT_SUCCESS, res };
}

export function deleteAccountErr(e) {
  return { type: DEL_CREDIT_CARD_ERROR, e };
}
export function activeAccountreq(id) {
  return { type: ACTIVE_ACCOUNT_REQUEST, id };
}

export function activeAccountSuc(res) {
  return { type: ACTIVE_ACCOUNT_SUCCESS, res };
}
export function activeAccountErr(e) {
  return { type: ACTIVE_ACCOUNT_ERROR, e };
}

export function createPaymentReq(payload) {
  return { type: CREATE_PAYMENT_REQUEST, payload };
}

export function recordInvoiceSuc(res) {
  return { type: RECORD_INVOICE_SUC, res };
}

export function recordInvoiceReq(
  invoice_id,
  payment_number,
  amount,
  payment_type,
  user_id,
  connect_account_id,
  cb
) {
  return {
    type: RECORD_INVOICE_REQ,
    invoice_id,
    payment_number,
    amount,
    payment_type,
    user_id,
    connect_account_id,
    cb
  };
}

export function recordInvoiceErr(e) {
  return { type: RECORD_INVOICE_ERR, e };
}

export const checkTokenReq = (params) => {
  return { type: CHECK_TOKEN_REQ, params };
};
export const checkTokenSuccess = () => {
  return { type: CHECK_TOKEN_SUCCESS };
};
export const checkTokenError = () => {
  return { type: CHECK_TOKEN_ERROR };
};
