import {
  GET_ALL_ACCOUNT_REQ,
  GET_ALL_ACCOUNT_SUCCESS,
  GET_ALL_ACCOUNT_ERROR,
  STRIPE_ACCOUNT_REQUEST,
  STRIPE_ACCOUNT_SUCCESS,
  STRIPE_ACCOUNT_ERROR,
  LINKED_ACCOUNT_SUCCESS,
  LINKED_ACCOUNT_REQUEST,
  LINKED_ACCOUNT_ERROR,
  ACTIVE_ACCOUNT_SUCCESS,
  ACTIVE_ACCOUNT_REQUEST,
  ACTIVE_ACCOUNT_ERROR,
  CHECK_TOKEN_REQ,
  CHECK_TOKEN_SUCCESS,
  CHECK_TOKEN_ERROR,
} from "configurations/Types";

const INITIAL_STATE = {
  snap: {},
  loadingAccount: false,
  stripeLoader: false,
  all_account: [],
  isPaid: false,
};

const StripeReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case STRIPE_ACCOUNT_REQUEST: {
      return Object.assign({}, state, { loading: true, stripeLoader: true });
    }
    case STRIPE_ACCOUNT_SUCCESS: {
      return Object.assign({}, state, {
        loading: false,
        snap: action.res,
        stripeLoader: false,
      });
    }
    case STRIPE_ACCOUNT_ERROR: {
      return Object.assign({}, state, { loading: false, stripeLoader: false });
    }
    case GET_ALL_ACCOUNT_REQ: {
      return Object.assign({}, state, { loadingAccount: true });
    }
    case GET_ALL_ACCOUNT_SUCCESS: {
      return Object.assign({}, state, {
        loadingAccount: false,
        all_account: action.res,
      });
    }
    case GET_ALL_ACCOUNT_ERROR: {
      return Object.assign({}, state, { loadingAccount: false });
    }
    case LINKED_ACCOUNT_REQUEST: {
      return Object.assign({}, state, { loading: true });
    }
    case LINKED_ACCOUNT_SUCCESS: {
      return Object.assign({}, state, { loading: false });
    }
    case LINKED_ACCOUNT_ERROR: {
      return Object.assign({}, state, { loading: false });
    }
    case LINKED_ACCOUNT_REQUEST: {
      return Object.assign({}, state, { loading: true });
    }
    case ACTIVE_ACCOUNT_SUCCESS: {
      return Object.assign({}, state), { loading: false };
    }
    case ACTIVE_ACCOUNT_ERROR: {
      return Object.assign({}, state, { loading: false });
    }
    case ACTIVE_ACCOUNT_REQUEST: {
      return Object.assign({}, state, { loading: true });
    }
    case CHECK_TOKEN_REQ: {
      return Object.assign({}, state, { isPaid: false, isLoading: true });
    }
    case CHECK_TOKEN_SUCCESS: {
      return Object.assign({}, state, { isPaid: false, isLoading: false });
    }
    case CHECK_TOKEN_ERROR: {
      return Object.assign({}, state, { isPaid: true, isLoading: false });
    }
    default: {
      return state;
    }
  }
};

export default StripeReducer;
