import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import {openModal} from './actions/Modal'
import { store } from "./ConfigureStore";
// import { ApolloProvider } from '@apollo/client';
// import { client } from "./configurations/apollo";
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
    <BrowserRouter basename="/" getUserConfirmation={(message, callback) => {
      store.dispatch(openModal("Are you sure?", message?message:"changes are not saved, do you want to discard?", "Yes", "No",
      {
        onOk: () => {
          callback && callback(true);
        },
        onCancel: () => {
          callback && callback(false)
        } ,     
        footer: undefined,
        className:"appBackground"
      }))
    }}>
      <Provider store={store}>
        {/* <ApolloProvider client={client}> */}
          <App />
        {/* </ApolloProvider> */}
      </Provider>
    </BrowserRouter>,
  // </StripeProvider>, 
document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
