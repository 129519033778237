import React, { lazy, Suspense } from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { LOCAL_STORAGE_KEY } from "./configurations/Constants";
import Loader from "./components/loader";
import Payments from "./appcomponents/timetrakker/payments";

const Login = lazy(() => import("./views/auth/LoginCard"));
const Lawyer = lazy(() => import("./views/landingpage/Lawyer"));
const Signup = lazy(() => import("./views/auth/signup"));
const LandingScreen = lazy(() => import("./views/landingpage/Main"));
const ForgotPassword = lazy(() => import("./views/auth/ForgotPassword"));
const VerificationCode = lazy(() => import("./views/auth/VerificationCode"));
const ResetPassword = lazy(() => import("./views/auth/ResetPassword"));
const Shell = lazy(() => import("./views/shell"));
const Subscription = lazy(() => import("./views/Subscription"));

class Routes extends React.Component {
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0);
    }
  }
  render() {
    const { isAuthenticated } = this.props;
    let data = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY));
    // let userRole = data.role;
    let subscription = data?.subscription;
    console.log("SUBSCRIPTION IN ROUTE: ", subscription);
    return (
      <Suspense fallback={<Loader />}>
        <Switch>
          {!isAuthenticated && (
            <Route exact path="/home" component={LandingScreen} />
          )}
          {!isAuthenticated && (
            <Route exact path="/user" component={Lawyer} />
          )}
          {!isAuthenticated && (
            <Route exact path="/signup" component={Signup} />
          )}
          {!isAuthenticated && <Route exact path="/login" component={Login} />}
          {!isAuthenticated && (
            <Route exact path="/forgot-password" component={ForgotPassword} />
          )}
          {!isAuthenticated && (
            <Route exact path="/verify-code" component={VerificationCode} />
          )}
          {!isAuthenticated && (
            <Route exact path="/reset-password" component={ResetPassword} />
          )}
            {/* {!isAuthenticated && (
            <Route exact path="/user/paymentMethod" component={Payments} />
          )} */}

          {!isAuthenticated && (
            <Route exact path="/paymentMethod" component={Payments} />
          )}
          {/* {!isAuthenticated && (
            <Route exact path="/lawyer/paymentMethod" component={Login} />
          )} */}
          {isAuthenticated && <Route path="/" component={Shell} />}
          {/* {isAuthenticated && subscription.status !== "active" ? 
          <Route path="/" component={Shell} /> :
          <Route path="/" component={Subscription} 
          render={() => <Redirect to="/subscribe"/>}
          />} */}
          {/* <Route
            path="*"
            render={() => <Redirect to={isAuthenticated  ? "/" : "/home"} />}

          /> */}

          <Route
            path="*"
            render={() => <Redirect to={isAuthenticated ? "/" : "/login"} />}
          />
          {/* <Route
            path="*"
            render={() => (
              <Redirect
                to={
                  isAuthenticated
                    ? "/"
                    : (window.location.href =
                        "http://www.timetrakkerprime.com/")
                }
              />
            )}
          />  */}
        </Switch>
      </Suspense>
    );
  }
}

// const ProtectedRoute = ({ enabled, ...props }) => (
//   enabled && <Route {...props} />
// )

export default withRouter(
  connect((storeState) => ({
    isAuthenticated: storeState.Auth.isAuthenticated,
  }))(Routes)
);
