import { all } from "redux-saga/effects";
import { watchAppActions } from "./App";
import { watchAuthActions } from "./Auth";
import { watchImagesActions } from "./Images";
import { watchReportActions } from "./Report";
import { watchSnapshotActions } from "./Snapshot";
import { watchOrganizationActions } from "./Organization";
import { watchDashboardActions } from "./Dashboard";
import { WatchTransactionActions } from "./Transactions";
import { WatchInvoiceActions } from "./Invoices";
import { watchSettingsActions } from "./Settings";
import { watchCharts } from "./Charts";
import { watchSubscription } from "./Subscription";
import { watchStripeSagas } from "./Stripe";

export default function* rootSaga() {
  // console.log("roots saga running")
  yield all([
    watchAppActions(),
    watchAuthActions(),
    watchImagesActions(),
    watchReportActions(),
    watchSnapshotActions(),
    watchOrganizationActions(),
    watchDashboardActions(),
    WatchTransactionActions(),
    WatchInvoiceActions(),
    watchSettingsActions(),
    watchCharts(),
    watchSubscription(),
    watchStripeSagas(),
  ]);
}
