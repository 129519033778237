/// Constants
// export const GOOGLE_API_KEY = "AIzaSyAFnHI7y7n5eV9J-gHk-Ald5W9bnzKqCgI";
// export const STRIPE_PUBLISHABLE_API_KEY =
//   "pk_test_jUPgEi4tIpr7lEDkWC1xegOo00Trmps34D";
//export const STRIPE_SECRET_API_KEY = "pk_test_rBeOTa2VkXZ16aKpzBQiA8qT00SmODzsPk";
export const DEFAULT_TABLE_PAGE_SIZE = 10;
export const LOCAL_STORAGE_KEY = process.env.REACT_APP_LOCAL_STORAGE_KEY;
export const protocol_head = "https://";
export const current_sprint = 2;

// API base URL
//https://ttracker.5stardesigners.net/timetrakker/backend
//REACT_APP_API_BASE_URL=https://iapp.5stardesigners.net/invoicing4business/backend
export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const API_GRAPHQL_URL = API_BASE_URL + process.env.REACT_APP_GRAPHQL_URL;
export const API_REST_URL = API_BASE_URL + process.env.REACT_APP_REST_URL;
export const USERS_URL = "/users";
export const CONTACTS = "/contacts";
export const TRANSACTIONS = "/transactions";
export const INVOICES = "/invoices";
export const REPORTS = "/reports";
export const REPORT = "/reports";
export const CHARTS = "/charts";
export const STRIPE = "/stripes";
//export const AUTH_URL = "/card";
// export const USER_URL = "/user";

//API endpoints

//App
// export const FAQS_ENDPOINT = "/get-faq";
export const CONTACT_ENDPOINT = "/contact";
export const DELETE_DEMO_DATA = "/delete-demo-data"

//Auth
export const REGISTER_ENDPOINT = "/register";
export const SIGNUP_VALIDATION_REQUEST_ENDPOINT = "/signup-validation";
export const LOGIN_ENDPOINT = "/login";
export const VERIFY_EMAIL_ENDPOINT = "/resend-verify-code";
export const FORGOT_PASSWORD_ENDPOINT = "/resend-verify-code";
export const VERIFY_CODE_ENDPOINT = "/verify-email";
export const RESET_PASSWORD_ENDPOINT = "/reset-password";

//Edit Profile
export const UPDATE_PROFILE_ENDPOINT = "/profile";
export const CHANGE_PASSWORD_ENDPOINT = "/change-password";
//change password api is in the app saga
//Edit Profile

export const UPLOAD_IMAGE_ENDPOINT = "/upload-image";
export const GET_REPORT_ENDPOINT = "/generate-pdf";

// snapshot
export const SNAPSHOT_ENDPOINT = "/snap-shot";

// Organization
export const ORGANIZATION_ENDPOINT = "/get-organization";

// Dashboard
export const DASHBOARD_ENDPOINT = "/get-dash-board";
//Dashboard GET Constants
export const FETCH_ALL_ACCOUNTS = "/get-details";
export const FETCH_LAWYERS_ENDPOINT = "/get-lawyers";
export const FETCH_CUSTOMERS_ENDPOINT = "/get-customers";
export const FETCH_JOBS_ENDPOINT = "/get-jobs";
export const FETCH_ACCOUNTS_ENDPOINT = "/get-accounts";
export const FETCH_VENDORS_ENDPOINT = "/get-vendors";
export const FETCH_CREDIT_CARDS_ENDPOINT = "/get-credit-cards";

//Dashboard ADD Constants

export const ADD_LAWYER_ENDPOINT = "/create-lawyer";
export const ADD_CUSTOMER_ENDPOINT = "/create-customer";
export const ADD_VENDOR_ENDPOINT = "/create-vendor";
export const ADD_JOB_ENDPOINT = "/create-job";
export const ADD_TIME_ACCOUNT_ENDPOINT = "/create-account";
export const ADD_EXPENSE_ACCOUNT_ENDPOINT = "/create-account";
export const ADD_CREDIT_CARD_ENDPOINT = "/create-credit-card";

// update dashboard endpoint
export const UPDATE_LAWYER_ENDPOINT = "/update-lawyer";
export const UPDATE_TRANSACTION_ENDPOINT = "/update-account";
export const UPDATE_CREDIT_CARD_ENDPOINT = "/update-credit-card";
export const UPDATE_VENDOR_ENDPOINT = "/update-vendor";
export const UPDATE_JOB_ENDPOINT = "/update-job";
export const UPDATE_CUSTOMER_ENDPOINT = "/update-customer";

// get details
export const GET_LAWYER_DETAILS_ENDPOINT = "/get-lawyer";
export const GET_CUSTOMERS_DETAILS_ENDPOINT = "/get-customer";

// assign
export const ASSIGN_LAWYER_ENDPOINT = "/assign-customer";
export const ASSIGN_CUSTOMER_ENDPOINT = "/assign-job";

// delete
export const DELETE_ACCOUNT_ENDPOINT = "/delete-account";

// transactions
export const GET_TRANSACTIONS_ENDPOINT = "/get-transactions";
export const DEL_TRANSACTION_ENDPOINT = "/delete-transaction";

export const ADD_OPEN_TIME_TRANSACTION_ENDPOINT = "/create-time-transaction";
export const UPDATE_OPEN_TIME_TRANSACTION_ENDPOINT = "/update-time-transaction";

export const ADD_EXPENSE_TRANSACTION_ENDPOINT = "/create-expense-transaction";
export const EDIT_EXPENSE_TRANSACTION_ENDPOINT = "/update-expense-transaction";

export const ADD_FIXED_COST_TRANSACTION_ENDPOINT = "/create-fixed-cost-transaction";
export const EDIT_FIXED_COST_TRANSACTION_ENDPOINT = "/update-fixed-cost-transaction";
// invoices
export const GET_INVOICE_TRANSACTION_ENDPOINT =
  "/get-transactions-for-invoices";
export const CREATE_INVOICE_ENDPOINT = "/create-invoice";
export const MULTIPLE_EXPORT_ENDPOINT = "/send-emails";
export const GET_OPEN_INVOICES_ENDPOINT = "/get-open-invoices";

export const SETTINGS_URL = "/user-settings";
export const EDIT_ORGANIZATION_PROFILE_ENDPOINT = "/update-organization";
export const UPDATE_PASSWORD_ENDPOINT = "/update-password";
export const UPDATE_SETTINGS_ENDPOINT = "/update";

export const GET_CLIENT_STATEMENT_ENDPOINT = "/client-statement";
export const MULTIPLE_STATEMENT_EXPORT_ENDPOINT = "/send-bulk-statement";
export const PRINT_INVOICE_ENDPOINT = "/print-open-invoices";

export const DELETE_INVOICE_ENDPOINT = "/delete-invoice/";
export const PAYMENT_HISTORY_ENDPOINT = "/payment-history";

export const RECORD_INVOICE_ENDPOINT = "/record-invoice-payment/";

export const CHECK_SUBSCRIPTION_ENDPOINT = "/invoices/check-subscription";

export const SEND_REPORT_ENDPOINT = "/send-report";

// Charts
export const CHARTS_ENDPOINT = "/charts/get-transactions";
export const CHARTS_EXPORT_ENDPOINT = "/charts/print-report";
export const CHART_DROPDOWNS_ENDPOINT = "/charts/get-details";

// Subscription
export const BUY_SUBSCRIPTION_ENDPOINT = "/subscriptions";
export const SUBSCRIPTION_ENDPOINT = "/user-settings";
export const CANCEL_SUBSCRIPTION_ENDPOINT = "/cancel";
export const AR_SEARCH_TOOL_ENDPOINT = "/print-all-invoices";
export const ALL_SUBSCRIPTION_ENDPOINT = "/all-subscription";

export const CUSTOMER_BALANCE_SUMMARY_ENDPOINT = "/customer-balance-summary";
export const CUSTOMER_BALANCE_DETAIL_ENDPOINT = "/customer-balance-detail";
export const ACCOUNTS_RECEIVABLE_GRAPH_ENDPOINT = "/accounts-receivable-graph";
export const VENDORS_SUMMARY_REPORT_ENDPOINT = "/vendor-summary";
export const CREDIT_CARD_DETAIL_REPORT_ENDPOINT = "/credit-card-detail";
export const LAWYERS_RANK_REPORT_ENDPOINT = "/lawyer-ranks";
export const JOB_RANK_REPORT_ENDPOINT = "/job-ranks";
export const CUSTOMER_CREDIT_BALANCE_REPORT_ENDPOINT =
  "/customer-credit-balance";
export const AR_INCOME_METRICS_ENDPOINT = "/ar-income-metrics";
export const SUMMARY_REPORT_ENDPOINT = "/summary";

//Settings
export const ASSIGN_USER_ROLE_ENDPOINT = "/assign-user-role";
export const FETCH_ASSIGN_USER_ROLE_ENDPOINT = "/get-user-roles";
export const FETCH_UNASSIGNED_USER_ROLE_ENDPOINT = "/get-user-roles";
export const DELETE_ASSIGN_USER_ROLE_ENDPOINT = "/delete-assign-user-role";
export const EDIT_ASSIGN_USER_ROLE_ENDPOINT = "/update-assign-user-role";

//Reports
export const GET_AR_ANALYSIS_ENDPOINT = "/ar-analysis";
export const GET_AGING_REPORT_ENDPOINT = "/aging";
export const CHART_OF_ACCOUNTS_ENDPOINT = "/chart-of-accounts";
export const TRIAL_BALANCE_SUMMARY_ENDPOINT = "/trial-balance-summary";
export const CHECK_ACCOUNT_ENDPOINT = "/check-account";
export const CONNECT_ACCOUNT = "/create-account";
export const GET_ALL_ACCOUNT = "/get-accounts";
export const GET_LINKED_ACCOUNT = "/link-account";

//Delete
export const DELETE_ACCOUNT = "/delete-account";

//ACTIVE ACCOUNT

export const ACTIVE_ACCOUNT = "/update";
export const CREATE_PAYMENT = "/stripe-create-payment"
export const RECORD_INVOICE_PAYMENT = "/record-invoice-payment"
export const CHECK_TOKEN_ENDPOINT='check-token'