import React from "react";
import {
  put,
  take,
  takeLatest,
  fork,
  cancel,
  cancelled,
} from "redux-saga/effects";
import {
  REGISTER_COMPLETE_REQUEST,
  LOGIN_REQUEST,
  LOGIN_ERROR,
  LOGOUT,
  PASSWORD_RESET_REQUEST,
  RESEND_CODE_REQUEST,
  VERIFY_TOKEN_REQUEST,
  RESET_PASSWORD_REQUEST,
  RESEND_EMAIL,
  SIGNUP_VALIDATION_REQUEST,
} from "../configurations/Types";
import {
  registerCompleteSuccess,
  registerCompleteError,
  loginError,
  passwordResetSuccess,
  passwordResetError,
  resendCodeSuccess,
  resendCodeError,
  verificationCodeSuccess,
  verificationCodeError,
  changePasswordSuccess,
  changePasswordError,
  resendEmailRequest,
  signupValidationError,
  signupValidationSuccess,
} from "../actions/Auth";
import info from "../components/message/index";
import VerifyAccountMessage from "../appcomponents/shares/auth/VerifyAccountMessage";
import { login } from "../utils/index";
import { store } from "../ConfigureStore";
import {
  USERS_URL,
  LOGIN_ENDPOINT,
  FORGOT_PASSWORD_ENDPOINT,
  VERIFY_CODE_ENDPOINT,
  RESET_PASSWORD_ENDPOINT,
  VERIFY_EMAIL_ENDPOINT,
  // REGISTER_ENDPOINT,
  SIGNUP_VALIDATION_REQUEST_ENDPOINT,
} from "../configurations/Constants";
import moment from "moment";

import { publicAgent } from "../configurations/AxiosAgent";

// const delay = ms => new Promise((res, rej) => setTimeout(() => res(5), ms));

const registerApi = (body) => {
  let formData = new FormData();
  for (const key in body) {
    formData.append(key, body[key]);
  }
  let current_time = moment().format("YYYY-MM-DD HH:mm:ss");
  formData.append("current_time", current_time);
  return publicAgent.post(`${USERS_URL}`, formData, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
};

const signupValidationApi = (email) => {
  // console.log("signupValidationApi", body);
  return publicAgent.post(`${USERS_URL}${SIGNUP_VALIDATION_REQUEST_ENDPOINT}`, {
    email,
  });
};
const loginApi = (body) => {
  return publicAgent.post(`${USERS_URL}${LOGIN_ENDPOINT}`, {
    ...body,
  });
  // .then(res=>{
  //   console.log("RES Login",res)
  // });
};

const resendEmailApi = (email) => {
  return publicAgent.post(`${USERS_URL}${VERIFY_EMAIL_ENDPOINT}`, {
    email: email,
    current_time: moment().format("YYYY-MM-DD HH:mm:ss"),
  });
};
const passwordResetApi = (email) => {
  //console.log("body passwordResetApi", body);
  return publicAgent.post(`${USERS_URL}${FORGOT_PASSWORD_ENDPOINT}`, {
    email: email,
    current_time: moment().format("YYYY-MM-DD HH:mm:ss"),
  });
};
const resendCodeApi = (email) => {
  return publicAgent.post(`${USERS_URL}${FORGOT_PASSWORD_ENDPOINT}`, {
    email: email,
    current_time: moment().format("YYYY-MM-DD HH:mm:ss"),
  });
};
const verifyCodeApi = (body) => {
  // console.log("verifyCodeApi: ", body);
  return publicAgent.post(`${USERS_URL}${VERIFY_CODE_ENDPOINT}`, {
    ...body,
    current_time: moment().format("YYYY-MM-DD HH:mm:ss"),
  });
};
const resetPasswordApi = (body) => {
  // console.log("resetPasswordApi 104: ", body, RESET_PASSWORD_ENDPOINT);
  // let resetPasswordFields ={
  //   new_password :body.password
  // }
  let { password, ...rest } = body;
  return publicAgent.post(`${USERS_URL}${RESET_PASSWORD_ENDPOINT}`, {
    ...rest,
    new_password: password,
    current_time: moment().format("YYYY-MM-DD HH:mm:ss"),
  });
};

export function* register(action) {
  let res;
  try {
    res = yield registerApi(action.body);
    // console.log("FROM SAGA 2: ", res);
    info("info", `Please verify your email so we can continue login`);
    yield put(registerCompleteSuccess(res));
    if (action.isSubmitting) action.isSubmitting();
    if (action.cb) action.cb();
  } catch (e) {
    console.log("IN CATCH", e, action);
    if (action.isSubmitting) action.isSubmitting();
    yield put(registerCompleteError(e));
    try {
      // console.log("FROM SAGA ERROR", e.response.data.error.message);
      info("error", `${e.response.data.error.message}`);
    } catch (e) {}
  } finally {
    if (yield cancelled()) {
      // console.log("CANCELLED");
    }
  }
}
//signup validation saga
export function* signUpValidation(action) {
  let res;
  try {
    res = yield signupValidationApi(action.email);
    yield put(signupValidationSuccess(res, false));
    if (action.cb) action.cb();
  } catch (e) {
    if (e.response.data.error.message === "Email already exist.") {
      info("error", `${e.response.data.error.message}`);
      yield put(signupValidationSuccess(res, true));
    } else {
      yield put(signupValidationError(e));
    }
    // info("warning", `${res.data.data.message}`);
  } finally {
    if (yield cancelled()) {
      // console.log("CANCELLED");
    }
  }
}

export function* authorize(body, cb, extras, navigationCallback) {
  let res;
  try {
    //  console.log("FROM SAGA", body, extras);
    res = yield loginApi(body);
    // console.log("FROM SAGA", res);
    login(res.data.data.data, cb, navigationCallback);
    // yield put(loginSuccess(res));
  } catch (e) {
    if (e.response) {
      // console.log("e.response.data.status", e);
      if (e.response.data.status === 400) {
        //VerifyAccountMessage();
        // console.log("aut0rize if");
        if (e.response.data.error.message === "Unverified email.") {
          // console.log("please verify eamil........");
          info(
            "info",
            <VerifyAccountMessage
              email={body.email}
              verifyRedirect={extras}
              apiCall={() => store.dispatch(resendEmailRequest(body.email))}
            />,
            10
          );
          //yield put(resendEmailRequest(body.email));
        } else {
          // console.log("else:",``)
          //console.log("FROM SAGA ERROR", e.response.data.error.message);
          info("error", `${e.response.data.error.message}`);
        }
      }
    }
    yield put(loginError(e));
  } finally {
    if (yield cancelled()) {
      // console.log("CANCELLED");
    }
  }
}

export function* resendEmail(action) {
  //console.log("resend email 164", action);
  let res;
  try {
    res = yield resendEmailApi(action.payload);
    info("success", `Verification email has been resent`);
  } catch (e) {
    if (e.response) {
      info("error", `${e.response.data?.error?.message}`);
    }
  }
}

export function* watchAuthentication() {
  while (true) {
    const { body, cb, extras, navigationCallback } = yield take(LOGIN_REQUEST);
    //console.log("TEST", email, password)
    // fork return a Task object
    const task = yield fork(authorize, body, cb, extras, navigationCallback);
    const action = yield take([LOGOUT, LOGIN_ERROR]);
    if (action.type === LOGOUT) {
      action.cb && action.cb();
      yield cancel(task)}
    //Clear local storage here or run callback
    //yield call(Api.clearItem, 'token')
  }
}

export function* passwordResetRequest(action) {
  let res;
  try {
    // console.log("Actions: ", action);
    res = yield passwordResetApi(action.body);
    //console.log("FROM SAGA", res.data.data.message);
    info("success", `Email has been sent with verification code`);
    yield put(passwordResetSuccess(res));
    if (action.cb) {
      action.cb();
    }
  } catch (e) {
    if (e.response) {
      //console.log("FROM SAGA ERROR", e.response.data.error.message);
      info("error", `${e.response.data.error.message}`);
    }
    yield put(passwordResetError(e));
  } finally {
    if (yield cancelled()) {
      //console.log("CANCELLED");
    }
  }
}
export function* resendCodeRequest(action) {
  let res;
  try {
    res = yield resendCodeApi(action.body);
    //  console.log("FROM SAGA", res.data.data.message);
    info(
      "success",
      `Please check your inbox, Email has been sent with verification code`
    );
    yield put(resendCodeSuccess(res));
    if (action.cb) {
      action.cb();
    }
  } catch (e) {
    if (e.response) {
      // console.log("FROM SAGA ERROR", e.response.data.error.message);
      info("error", `${e.response.data?.error?.message}`);
    }
    yield put(resendCodeError(e));
  } finally {
    if (yield cancelled()) {
      // console.log("CANCELLED");
    }
  }
}
export function* verificationCode(action) {
  let res;
  try {
    // console.log("Verify action: ", action);
    res = yield verifyCodeApi(action.body);
    //  console.log("FROM SAGA", res.data.data);
    yield put(verificationCodeSuccess(res));
    info("success", `Your account has been verified`);
    if (action.cb) {
      action.cb();
    }
  } catch (e) {
    if (e.response) {
      //  console.log("FROM SAGA ERROR", e.response.data.error.message);
      info("error", `${e.response.data.error.message}`);
    }
    yield put(verificationCodeError(e));
  } finally {
    if (yield cancelled()) {
      // console.log("CANCELLED");
    }
  }
}
export function* changePassword(action) {
  console.log("chnage password::", action.body);
  let res;
  try {
    let { confirmPassword, ...properBody } = action.body;
    // console.log("properBody::", properBody);
    res = yield resetPasswordApi(properBody);
    info("success", `Password Updated Successfully!`);
    //console.log("FROM SAGA", res.data.data);
    if (action.cb) {
      action.cb();
    }
    yield put(changePasswordSuccess(res));
  } catch (e) {
    if (e.response) {
      // console.log("FROM SAGA ERROR", e.response.data.error.message);
      info("error", `${e.response.data.error.message}`);
    }
    yield put(changePasswordError(e));
  } finally {
    if (yield cancelled()) {
      // console.log("CANCELLED");
    }
  }
}

// Our watcher Saga: spawn a new incrementAsync task on each INCREMENT_ASYNC
export function* watchAuthActions() {
  yield fork(watchAuthentication);
  yield takeLatest(REGISTER_COMPLETE_REQUEST, register);
  yield takeLatest(PASSWORD_RESET_REQUEST, passwordResetRequest);
  yield takeLatest(RESEND_CODE_REQUEST, resendCodeRequest);
  yield takeLatest(VERIFY_TOKEN_REQUEST, verificationCode);
  yield takeLatest(RESET_PASSWORD_REQUEST, changePassword);
  yield takeLatest(RESEND_EMAIL, resendEmail);
  yield takeLatest(SIGNUP_VALIDATION_REQUEST, signUpValidation);
}
