import { put, takeLatest, cancelled } from "redux-saga/effects";
import { privateAgent, CancelToken } from "configurations/AxiosAgent";
import {
  EDIT_ORGANIZATION_PROFILE_REQUEST,
  GET_ORGANIZATION_PROFILE_REQUEST,
  UPDATE_PASSWORD_REQUEST,
  UPDATE_SETTINGS_REQUEST,
  GET_SETTINGS_REQUEST,
  ASSIGN_USER_ROLE_REQUEST,
  FETCH_ASSIGN_USER_ROLE_REQUEST,
  EDIT_ASSIGN_USER_ROLE_REQUEST,
  DELETE_ASSIGN_USER_ROLE_REQUEST,
  FETCH_UNASSIGNED_USER_ROLE_REQUEST
} from "configurations/Types";
import info from "components/message/index";
import moment from "moment";
import {
  USERS_URL,
  SETTINGS_URL,
  EDIT_ORGANIZATION_PROFILE_ENDPOINT,
  UPDATE_PASSWORD_ENDPOINT,
  UPDATE_SETTINGS_ENDPOINT,
  GET_SETTINGS_ENDPOINT,
  ASSIGN_USER_ROLE_ENDPOINT,
  FETCH_ASSIGN_USER_ROLE_ENDPOINT,
  FETCH_UNASSIGNED_USER_ROLE_ENDPOINT,
  DELETE_ASSIGN_USER_ROLE_ENDPOINT,
  EDIT_ASSIGN_USER_ROLE_ENDPOINT,
} from "configurations/Constants";
import {
  editOrganizationProfileSuccess,
  editOrganizationProfileError,
  getSettingsRequest,
  getOrganizationProfileSuccess,
  getOrganizationProfileError,
  updatePasswordSuccess,
  updatePasswordError,
  updateSettingsSuccess,
  updateSettingsError,
  getSettingsSuccess,
  getSettingsError,
  assignUserRoleSuccess,
  assignUserRoleError,
  assignUserRoleRequest,
  fetchAssignUserRoleRequest,
  fetchAssignUserRoleError,
  fetchAssignUserRoleSuccess,
  fetchUnAssignedUserRoleError,
  fetchUnAssignedUserRoleSuccess,
  editAssignUserRoleRequest,
  editAssignUserRoleSuccess,
  editAssignUserRoleError,
  deleteAssignUserRoleSuccess,
  deleteAssignUserRoleError
} from "actions/Settings";
import { getOrganizationRequest } from "actions/Organization";
let token;
let current_time = () => moment().format("YYYY-MM-DD HH:mm:ss");

const getOrganizationProfileApi = () => privateAgent.get(`${SETTINGS_URL}`);

export function* getOrganizationProfile(action) {
  const { cb } = action;
  let res;
  try {
    res = yield getOrganizationProfileApi();
    console.log(res, "ORGANIZATION RESSSS");
    yield put(getOrganizationProfileSuccess(res.data.data));
    cb && cb();
  } catch (e) {
    if (e.response?.data.error.message) {
      info("error", e.response?.data.error.message);
    }
    yield put(getOrganizationProfileError(e.response));
  } finally {
    if (yield cancelled()) {
      // console.log("CANCELLED");
    }
  }
}

const editOrganizationProfileApi = (id, formData) =>
  privateAgent.post(
    `${USERS_URL}${EDIT_ORGANIZATION_PROFILE_ENDPOINT}/${id}`,
    formData,
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    }
  );

export function* editOrganizationProfile(action) {
  const { id, body, cb } = action;
  let res;
  try {
    const {
      user_id,
      organization_id,
      thumb_url,
      image_url,
      upload,
      ...rest
    } = body;
    if (upload instanceof Blob) {
      rest.upload = upload;
    } else {
      rest.image_url = image_url;
      rest.thumb_image_url = thumb_url;
    }

    let formData = new FormData();
    for (const key in rest) {
      formData.append(key, rest[key]);
    }

    let current_time = moment().format("YYYY-MM-DD HH:mm:ss");
    formData.append("current_time", current_time);

    res = yield editOrganizationProfileApi(id, formData);
    yield put(editOrganizationProfileSuccess());
    info("success", "Profile updated");
    yield put(getOrganizationRequest());
    cb && cb();
  } catch (e) {
    if (e.response?.data.error.message) {
      info("error", e.response?.data.error.message);
    }
    yield put(editOrganizationProfileError(e.response));
  } finally {
    if (yield cancelled()) {
      // console.log("CANCELLED");
    }
  }
}

const updatePasswordApi = (body) =>
  privateAgent.put(`${USERS_URL}${UPDATE_PASSWORD_ENDPOINT}`, {
    ...body,
    current_time: current_time(),
  });

export function* updatePassword(action) {
  const { cb, body } = action;
  let res;
  try {
    const {
      confirm_password,
      old_password: current_password,
      password: new_password,
      ...rest
    } = body;
    res = yield updatePasswordApi({ current_password, new_password });
    console.log(res, "ORGANIZATION RESSSS");
    info("success", res.data.data.message);
    yield put(updatePasswordSuccess(res.data.data));
    cb && cb();
  } catch (e) {
    if (e.response?.data.error.message) {
      info("error", e.response?.data.error.message);
    }
    yield put(updatePasswordError(e.response));
  } finally {
    if (yield cancelled()) {
      // console.log("CANCELLED");
    }
  }
}

const updateSettingsApi = (body, id) =>
  privateAgent.put(`${SETTINGS_URL}${UPDATE_SETTINGS_ENDPOINT}/${id}`, {
    ...body,
    current_time: current_time(),
  });
export function* updateSettings(action) {
  const { id, cb, body } = action;
  let res;
 
  try {
    res = yield updateSettingsApi(body, id);
    console.log(res,"kkkk")
    info("success", res?.data?.data?.message);
    yield put(updateSettingsSuccess(res.data.data));
    yield put(getSettingsRequest());
    cb && cb();
  } catch (e) {
    if (e.response?.data.error.message) {
      info("error", e.response?.data.error.message);
    }
    yield put(updateSettingsError(e.response));
  } finally {
    if (yield cancelled()) {
    }
  }
}

const getSettingsApi = () => privateAgent.get(`${SETTINGS_URL}`);
export function* getSettings(action) {
  let res;
  try {
    res = yield getSettingsApi(action);
    yield put(getSettingsSuccess(res.data.data));
  } catch (e) {
    if (e.response?.data.error.message) {
      info("error", e.response?.data.error.message);
    }
    yield put(getSettingsError(e.response));
  } finally {
    if (yield cancelled()) {
    }
  }
}
//Create request
const assignUserRoleApi = (body) => {
  return privateAgent.post(`${SETTINGS_URL}${ASSIGN_USER_ROLE_ENDPOINT}`, {
    ...body,
    current_time: current_time(),
  });
};

export function* assignUserRole(action) {
  const { body, cb } = action;
  let res;
  try {
    res = yield assignUserRoleApi(body);
    console.log("RESSS OF ASSIGN USER ROLE",res.data.data.message)
    info("success", `${res.data.data.message}`);
    yield put(assignUserRoleSuccess());
    yield put(fetchAssignUserRoleRequest());
    if (cb) action.cb();
  } catch (e) {
    if (e.response?.data.error.message) {
      info("error", e.response?.data.error.message);
    }
    yield put(assignUserRoleError(e.response));
  } finally {
    if (yield cancelled()) {
    }
  }
}

const fetchAssignUserRoleApi = (params, token) => {
  return privateAgent.get(
    `${SETTINGS_URL}${FETCH_ASSIGN_USER_ROLE_ENDPOINT}`,
    {
      cancelToken: token.token,
      params: params || {
        limit: 1000,
        offset: 0,
      },
    }
  );
};

export function* fetchAssignUserRole(action) {
  const { params, cb } = action;
  let res;
  try {
    if (token) {
      token.cancel();
    }
    token = CancelToken.source();
    res = yield fetchAssignUserRoleApi(params, token);
    console.log("MANAGE USERS DATA",res)
    info("add user Success", res?.data.data.message);
    yield put(fetchAssignUserRoleSuccess(res.data.data.assign_users));
    if (cb) action.cb();
  } catch (e) {
    if (e.response?.data.error.message) {
      info("error", e.response?.data.error.message);
    }
    yield put(fetchAssignUserRoleError(e.response));
  } finally {
    if (yield cancelled()) {
    }
  }
}










const fetchUnAssignedUserRoleApi = (params, token) => {
  return privateAgent.get(
    `${SETTINGS_URL}${FETCH_UNASSIGNED_USER_ROLE_ENDPOINT}`,
    {
      cancelToken: token.token,
      params: params || {
        limit: 1000,
        offset: 0,
      },
    }
  );
};


export function* fetchUnAssignedUserRole(action) {
  const { params, cb } = action;
  let res;
  try {
    if (token) {
      token.cancel();
    }
    token = CancelToken.source();
    res = yield fetchUnAssignedUserRoleApi(params, token);
    console.log("UNASSIGNED USERS DATA",res)
    info("add user Success", res?.data.data.message);
    yield put(fetchUnAssignedUserRoleSuccess(res.data.data.unassign_users));
    if (cb) cb('', res.data.data.unassign_users);
  } catch (e) {
    if (e.response?.data.error.message) {
      info("error", e.response?.data.error.message);
    }
    yield put(fetchUnAssignedUserRoleError(e.response));
  } finally {
    if (yield cancelled()) {
    }
  }
}

















const editAssignUserRoleApi = (id, body) => {
  let current_time = moment().format("YYYY-MM-DD HH:mm:ss");
  console.log("SAGGAAA IIDDD",id)
  return privateAgent.put(
    `${SETTINGS_URL}${EDIT_ASSIGN_USER_ROLE_ENDPOINT}/${id}`,
    {
      ...body,
      current_time,
    }
  );
};

function* editAssignUserRole(action) {
  let {body}=action
  console.log("SAGAGAAA BODDYY",body)
  let res;
  const { user_id, ...rest } = body;
  try {
    res = yield editAssignUserRoleApi(user_id, rest);
    console.log(res,'RESSSSSS UPDATEEEEE')
    info("success", res.data.data.message);
    console.log("Edit Assgin success", res.data.data);
    yield put(editAssignUserRoleSuccess());
    if (action.cb) action.cb();
  } catch (e) {
    if (e.response?.data?.error?.message) {
      info("error", e.response?.data?.error?.message);
    }
    yield put(editAssignUserRoleError());
  } finally {
    if (yield cancelled()) {
    }
  }
}

const  deleteAssignUserRoleApi = (id, token) =>
  privateAgent.delete(`${SETTINGS_URL}${DELETE_ASSIGN_USER_ROLE_ENDPOINT}/${id}`, {
    cancelToken: token.token,
  });


  function* deleteAssignUserRole(action) {
    const {body,cb}=action
    console.log("USERSS DELETE IDDDD",body)
    let res;
    try {
      if (token) {
        token.cancel();
      }
      token = CancelToken.source();
      res = yield deleteAssignUserRoleApi(body, token);
      info("success", res.data.data.message);
      yield put(deleteAssignUserRoleSuccess());
      if (cb) cb();
    } catch (e) {
      if (e.response?.data?.error?.message) {
        info("error", e.response?.data?.error?.message);
      }
      yield put(deleteAssignUserRoleError());
    } finally {
      if (yield cancelled()) {
      }
    }
  }
  


export function* watchSettingsActions() {
  yield takeLatest(GET_ORGANIZATION_PROFILE_REQUEST, getOrganizationProfile);
  yield takeLatest(EDIT_ORGANIZATION_PROFILE_REQUEST, editOrganizationProfile);
  yield takeLatest(UPDATE_PASSWORD_REQUEST, updatePassword);
  yield takeLatest(UPDATE_SETTINGS_REQUEST, updateSettings);
  yield takeLatest(ASSIGN_USER_ROLE_REQUEST, assignUserRole);
  yield takeLatest(EDIT_ASSIGN_USER_ROLE_REQUEST, editAssignUserRole);
  yield takeLatest(DELETE_ASSIGN_USER_ROLE_REQUEST, deleteAssignUserRole);
  yield takeLatest(FETCH_ASSIGN_USER_ROLE_REQUEST, fetchAssignUserRole);
  yield takeLatest(FETCH_UNASSIGNED_USER_ROLE_REQUEST, fetchUnAssignedUserRole);
  yield takeLatest(GET_SETTINGS_REQUEST, getSettings);
}
